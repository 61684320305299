import { FormControl, FormLabel, Switch, VStack, Skeleton } from '@chakra-ui/react'

function Notifications({ data, updateData, loading }) {
  const handleChange = (field, value) => {
    updateData({
      ...data,
      [field]: value
    });
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormLabel
          htmlFor="emailNotifications"
          mb={0}
          cursor="pointer"
          userSelect="none"
        >
          Primește notificări pe email
        </FormLabel>
        <Skeleton isLoaded={!loading}>
          <Switch 
            id="emailNotifications"
            isChecked={data.emailNotifications}
            onChange={(e) => handleChange("emailNotifications", e.target.checked)}
            colorScheme="green"
          />
        </Skeleton>
      </FormControl>

      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormLabel
          htmlFor="fundingAlerts"
          mb={0}
          cursor="pointer"
          userSelect="none"
        >
          Primește alerte despre noi oportunități de finanțare
        </FormLabel>
        <Skeleton isLoaded={!loading}>
          <Switch 
            id="fundingAlerts"
            isChecked={data.fundingAlerts}
            onChange={(e) => handleChange("fundingAlerts", e.target.checked)}
            colorScheme="green"
          />
        </Skeleton>
      </FormControl>

      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormLabel
          htmlFor="deadlineReminders"
          mb={0}
          cursor="pointer"
          userSelect="none"
        >
          Primește reminder-uri pentru termene limită
        </FormLabel>
        <Skeleton isLoaded={!loading}>
          <Switch 
            id="deadlineReminders"
            isChecked={data.deadlineReminders}
            onChange={(e) => handleChange("deadlineReminders", e.target.checked)}
            colorScheme="green"
          />
        </Skeleton>
      </FormControl>
    </VStack>
  )
}

export default Notifications
