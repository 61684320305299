import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
} from '@chakra-ui/react';
import { BiSend } from 'react-icons/bi';
import { sendFeedback } from '../../utils/feedback';
import styles from './FeedbackModal.module.css';

const FeedbackModal = ({
  isOpen,
  onClose,
  initialData = {},
  title = 'Send Feedback',
  submitButtonText = 'Send',
  axiosInstance,
  onSuccess,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: initialData.name || '',
    email: initialData.email || '',
    message: initialData.message || '',
    subject: initialData.subject || 'Feedback'
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await sendFeedback(axiosInstance, formData);

      toast({
        title: 'Success',
        description: 'Mesajul tau a fost trimis cu succes!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });

      setFormData(prev => ({
        ...prev,
        message: ''
      }));
      
      if (onSuccess) {
        onSuccess();
      }
      
      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className={styles.modalContent}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* <FormControl isRequired className={styles.formControl}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
            />
          </FormControl> */}

          <FormControl isRequired className={styles.formControl}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
            />
          </FormControl>

          {initialData.subject && (
            <FormControl isRequired className={styles.formControl}>
              <FormLabel>Subject</FormLabel>
              <Input
                value={formData.subject}
                isReadOnly
                variant="filled"
              />
            </FormControl>
          )}

          <FormControl isRequired className={styles.formControl}>
            <FormLabel>Mesaj</FormLabel>
            <Textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Scrie sugestia aici..."
              rows={4}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="purple"
            mr={3}
            onClick={handleSubmit}
            isLoading={isSubmitting}
            leftIcon={<BiSend />}
          >
            {submitButtonText}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal; 