import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F9FAFB;
  padding: 20px;
`;

const SuccessCard = styled.div`
  background: white;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
`;

const Icon = styled(FaCheckCircle)`
  color: #48BB78;
  font-size: 64px;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  color: #1A1A1A;
  font-size: 24px;
  margin-bottom: 16px;
`;

const Message = styled.p`
  color: #4A5568;
  margin-bottom: 32px;
  line-height: 1.6;
`;

const Button = styled.button`
  background: #6B46C1;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #553C9A;
    transform: translateY(-2px);
  }
`;

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    // Show success message
    toast({
      title: "Abonare reușită!",
      description: "Îți mulțumim pentru abonare",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }, [toast]);

  const handleContinue = () => {
    navigate('/feed');
  };

  return (
    <Container>
      <SuccessCard>
        <Icon />
        <Title>Abonare reușită!</Title>
        <Message>
          Îți mulțumim pentru abonare! Contul tău a fost actualizat cu succes și ai acum acces
          la toate funcționalitățile incluse în planul ales.
        </Message>
        <Button onClick={handleContinue}>
          Continuă la platformă
        </Button>
      </SuccessCard>
    </Container>
  );
};

export default SubscriptionSuccess; 