import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { signOutAPI } from "../../action";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducers/authReducer"; // Adjust the path as necessary
import { FaSignOutAlt } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { FaSort, FaFilter } from "react-icons/fa";
import axiosInstance from "../../services/axios";
import SearchDropdownContent from "./SearchDropdown";
import Chat from '../Feed2/Chat'; // Import the Chat component
import Modal from './Modal';
import { useChat } from '../../ChatContext'; // Add this import

const Container = styled.div`
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 10px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 11; // Increase z-index to be above the modal
  transition: top 0.3s;
  @media (min-width: 768px) {
    top: 0 !important; // Always visible on large screens
  }
`;

const Nav = styled.nav`
  margin-left: auto;
  display: block;
  @media (max-width: 768px) {
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;
    width: 100%;
    transition: bottom 0.3s;
    background: white;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  max-width: 1300px; //1128px;
`;

const Logo = styled.span`
  // margin-right: 100px;
  // margin-left: -105px;
  width: 50px;
  min-width: 50px;
  border-radius: 30%;
  font-size: 100%;
  @media (max-width: 768px) {
    padding: 2px;
    // margin-left: -30px;
  }
  // padding: 1%;
`;

// const Search = styled.div`
//   opacity: 1;
//   flex-grow: 1;
//   display: flex;
//   align-items: center;
//   position: relative;

//   & > div {
//     display: flex;
//     align-items: center;
//     width: 100%;

//     input {
//       border: none;
//       box-shadow: none;
//       border-radius: 2px;
//       color: rgba(0, 0, 0, 0.9);
//       padding: 0 8px 0 40px;
//       line-height: 1.75;
//       font-weight: 400;
//       font-size: 14px;
//       height: 34px;
//       vertical-align: text-top;
//       width: 100%;
//       max-width: 200px;
//       transition: max-width 0.3s ease-in-out;

//       &:focus {
//         max-width: 100%;
//       }
//     }
//   }
// `;

const SearchDropdown = styled.div`
  // display: ${({ visible }) => (visible ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 5%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 100;
  width: 90%;
  margin-top: 10px;
  border-radius: 15px;

  .filter-group {
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;

    label {
      font-size: 14px;
      margin-bottom: 5px;
    }

    input,
    select {
      padding: 5px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 14px;
      // width: 100%;
    }
  }

  @media (min-width: 768px) {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const Search = styled.div`
  opacity: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative; // For dropdown positioning

  .searchBar {
  margin-left: 10px;
  margin-right: 10px;
    z-index: 100;
    display: flex;
    align-items: center;
    flex-grow: 1;
    background: #eef3f8;
    border-radius: 20px;
    transition: all 0.3s ease;
    position: relative;
    max-width: 300px;

    &.focused {
      background: #fff;
      box-shadow: 0 0 0 2px #0a66c2;
      max-width: 100%;
    }

    input {
      flex-grow: 1;
      background: transparent;
      border: none;
      box-shadow: none;
      color: rgba(0, 0, 0, 0.9);
      padding: 0 8px 0 40px;
      line-height: 1.75;
      font-weight: 400;
      font-size: 14px;
      height: 40px;
      vertical-align: text-top;
      width: 100%;
      max-width: 400px;
      margin-left: 8px;

      &:focus {
        outline: none;
        max-width: 100%;
      }
      
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    .filter-button {
      padding: 8px 12px;
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      margin-right: 10px;
      border-radius: 16px;
      position: relative;
      margin-left: auto;

      &:hover {
        background: rgba(0, 0, 0, 0.08);
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
`;

const SearchOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: ${props => props.show ? 'block' : 'none'};
`;

const SearchDropdownWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 12;
  display: ${props => props.show ? 'block' : 'none'};
  max-height: 80vh;
  overflow-y: auto;
`;

const SearchHistory = styled.div`
  position: absolute;
  top: 100%;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  max-height: 200px;
  overflow-y: auto;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 80%;
  }

  .search-item {
    width: 100%;
    display: flex;
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #f9f9f9;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      color: #333;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

const SearchIcon = styled.div`
  margin-right: -40px;
  margin-left: 10px;

  width: 40px;
  z-index: 1;
  // position: absolute;
  // top: 10px;
  // left: 5px;
  border-radius: 0 2px 2px 0;
  // margin: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchSettings = styled.div`
  // margin-left: 10px;
  cursor: pointer;
  // display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

const FilteringGroup = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .sorting-options {
    display: flex;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;

      input {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 5px;

    h4 {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .sorting-options {
      label {
        font-size: 12px;
      }
    }
  }
`;

const SortingGroup = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .sorting-options {
    // display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;

      input {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 5px;

    h4 {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .sorting-options {
      label {
        font-size: 12px;
      }
    }
  }
`;

const NavListWrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  justify-content: space-between;
  .active {
    span {
      color: rgba(0, 0, 0, 1); /* Change text color to black */
      font-weight: 600;
    }
    font-weight: 600;
    span::after {
      content: "";
      transform: scaleX(1);
      border-bottom: 2px solid var(--white, #fff);
      position: absolute;
      left: 0;
      bottom: 0;
      transition: transform 0.2s ease-in-out;
      width: 100%;
      border-color: purple; //rgba(0, 0, 0, 0.9)
    }
  }
`;

const NavList = styled.li`
  display: flex;
  align-items: center;
  a {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5;
    // min-height: 52px;
    min-width: 80px;
    position: relative;
    text-decoration: none;
    span {
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      text-align: center;
    }
    @media (max-width: 768px) {
      min-width: 50px;
      font-size: 10px;
      span > img {
        width: 40%;
      }
    }
  }
  &:hover,
  &:active {
    a {
      span {
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
      }
    }
  }
`;

const SignOut = styled.div`
  // position: absolute;
  // top: 45px;
  background: white;
  border-radius: 0 0 5px 5px;
  width: 100px;
  // height: 40px;
  font-size: 16px;
  text-align: center;
  // transition-duration: 167ms;
  // display: none;
  // z-index: 15;
`;

const SignOutMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    padding-left: 1rem;
    font-size: 14px;
  }
`;

// const User = styled(NavList)`
//   a > img {
//     border-radius: 50%;
//     width: 25px;
//     height: 25px;
//   }
//   span {
//     display: flex;
//     align-items: center;
//   }
//   &:hover {
//     ${SignOut} {
//       @media (min-width: 768px) {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }
//   }
// `;

const User = styled(NavList)`
  position: relative;
  cursor: pointer;
  
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 80px;
    // gap: 4px;
    
    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid transparent;
      transition: all 0.2s ease;
    }

    span {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);

      img {
        width: 12px;
        height: 12px;
        border: none;
      }
    }
  }

  &:hover {
    a img:first-child {
      border-color: #6B46C1;
    }
  }

  @media (max-width: 768px) {
    a {
      min-width: 50px;
      
      span {
        font-size: 10px;
      }
    }
  }
`;

const Work = styled(User)`
  border-left: 1px solid rgba(0, 0, 0, 0.08);
`;

const Dropdown = styled.div`
  position: absolute;
  top: 130%;
  right: 0;
  background: white;
  min-width: 280px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 0;
  z-index: 100;
  overflow: hidden;

  .profile-section {
    padding: 20px;
    background: linear-gradient(to bottom, #F9FAFB, white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: #1A1A1A;
      gap: 12px;
      min-width: unset;

      &:hover {
        .profile-info h4 {
          color: #6B46C1;
        }
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
      }

      .profile-info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          transition: color 0.2s ease;
        }

        p {
          font-size: 13px;
          color: #666;
          margin: 0;
        }
      }
    }
  }

  .menu-section {
    padding: 8px 0;

    h4 {
      font-size: 12px;
      font-weight: 600;
      color: #666;
      padding: 8px 20px;
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    a {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      text-decoration: none;
      color: #1A1A1A;
      font-size: 14px;
      transition: all 0.2s ease;
      min-width: unset;

      &:hover {
        background: #F9FAFB;
        color: #6B46C1;
      }

      &.subscription-link {
        color: #6B46C1;
        font-weight: 600;
        
        &:hover {
          background: #F3E8FF;
        }
      }
    }
  }

  hr {
    margin: 8px 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

const SignOutSection = styled.div`
  padding: 8px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background: #F9FAFB;

  .sign-out-button {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 10px 20px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: none;
    border: none;

    &:hover {
      color: #E53E3E;
      background: #FFF5F5;
    }

    svg {
      font-size: 16px;
    }
  }
`;

const NotificationsDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130%;
  right: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  min-width: 200px;
  max-width: 400px;
  max-height: 400px;
  border-radius: 5px;
  overflow-y: auto;

  @media (max-width: 768px) {
    top: auto;
    bottom: 130%;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.9);
  }

  .notification-item {
    // padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

const FilterButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;

  img {
    margin-right: 5px;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
`;

const ModalButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 16px;
  position: relative;
  border-radius: 20px;
  gap: 8px;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #f6f8fa 0%, #ebedff 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }

  span {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    color: #4a154b;
    background: linear-gradient(135deg, #ebedff 0%, #e0e4ff 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);

    img {
      transform: rotate(15deg) scale(1.1);
    }
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(74, 21, 75, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(74, 21, 75, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(74, 21, 75, 0);
    }
  }

  &:focus {
    outline: none;
    animation: pulse 1.5s infinite;
  }
`;

const FilterBadge = styled.span`
  background-color: #4a154b;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Header({ setSearchResults, user, filters, passedSearchQuery, onSearch }) {
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Add this near the top of the component

  // State for controlling the dropdown visibility
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);

  // States for controlling the visibility of the header on scroll
  const [lastScrollY, setLastScrollY] = useState(0);
  const [visible, setVisible] = useState(true);

  const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const { isInitialized } = useChat(); // Use the global chat context

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleSearch = useCallback(async (newFilters) => {
    try {
      // If we're not on the feed page, navigate to it with the search query
      if (location.pathname !== '/feed') {
        navigate('/feed', {
          state: {
            searchQuery: searchQuery,
            filters: newFilters
          }
        });
        return;
      }

      // Use the onSearch prop to handle the search
      onSearch({ ...newFilters, searchQuery });
    } catch (error) {
      console.error("Error during search:", error);
    }
  }, [searchQuery, location.pathname, navigate, onSearch]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch({});
      handleCloseSearch();
    }
  };

  useEffect(() => {
    const savedSearchHistory =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistory(savedSearchHistory);

    if (passedSearchQuery) {
      setSearchQuery(passedSearchQuery);
    }
  }, []);

  const toggleSearchDropdown = () => {
    setSearchDropdownVisible(!searchDropdownVisible);
  };

  // Function to toggle the notifications dropdown visibility
  const toggleNotificationsDropdown = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  // Memoize the closeNotificationsDropdown function
  const closeNotificationsDropdown = useCallback((e) => {
    if (notificationsVisible && !e.target.closest(".notifications-dropdown")) {
      setNotificationsVisible(false);
    }
  }, [notificationsVisible]);

  // Use the memoized function in the effect
  useEffect(() => {
    document.addEventListener("click", closeNotificationsDropdown);
    return () => document.removeEventListener("click", closeNotificationsDropdown);
  }, [closeNotificationsDropdown]);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Function to close the dropdown when clicking outside
  const closeDropdown = (e) => {
    if (dropdownVisible && !e.target.closest(".user-dropdown")) {
      setDropdownVisible(false);
    }
  };

  // Add event listener for clicks outside
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [dropdownVisible]);

  const handleScroll = () => {
    if (window.innerWidth < 1500) {
      // Apply only on small screens
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setVisible(false); // Hide when scrolling down
      } else {
        setVisible(true); // Show when scrolling up
      }
      setLastScrollY(currentScrollY);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1500) {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, visible]);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const openFilterModal = () => {
    setModalContent('filter');
    setIsModalOpen(true);
  };

  const openChatModal = () => {
    if (isInitialized) {
      setModalContent('chat');
      setIsModalOpen(true);
    } else {
      console.log('Chat is not initialized yet');
      // You can show a loading message or handle this case as needed
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const countActiveFilters = (filters) => {
    let count = 0;
    if (filters.status !== "all") count++;
    if (filters.region !== "all") count++;
    if (filters.eligibility !== "all") count++;
    if (filters.startDate) count++;
    if (filters.endDate) count++;
    if (filters.sortBy !== "relevance") count++;
    return count;
  };

  // Add handlers for search focus
  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleCloseSearch = () => {
    setIsSearchFocused(false);
  };

  return (
    <>
      <Container style={{ top: visible ? "0" : "-70px" }}>
        <Content>
          <Logo>
            <a href="/feed">
              <img style={{ paddingRight: "0px", borderRadius: "15px" }} src="/images/logo.svg" alt="" />
            </a>
          </Logo>
          <Search>
            <div className={`searchBar ${isSearchFocused ? 'focused' : ''}`}>
              <SearchIcon>
                <img src="/images/search-icon.svg" alt="Search" />
              </SearchIcon>
              <input
                type="text"
                placeholder="Caută oportunități de finanțare"
                value={searchQuery}
                onChange={handleSearchInputChange}
                onKeyPress={handleSearchInputKeyPress}
                onFocus={handleSearchFocus}
              />
              <button className="filter-button" onClick={handleSearchFocus}>
                <img src="/images/filter.svg" alt="Filters" />
                {countActiveFilters(filters) > 0 && (
                  <FilterBadge>{countActiveFilters(filters)}</FilterBadge>
                )}
              </button>
            </div>
            <SearchOverlay show={isSearchFocused} onClick={handleCloseSearch} />
            <SearchDropdownWrapper show={isSearchFocused}>
              <SearchDropdownContent
                onSearch={(filters) => {
                  handleSearch(filters);
                  handleCloseSearch();
                }}
              />
            </SearchDropdownWrapper>
            <ModalButton onClick={openChatModal}>
              <img src="/images/sparkles_24.png" alt="AI Search" />
              <span>Asistent</span>
            </ModalButton>
          </Search>
          <Nav style={{ bottom: visible ? "0" : "-60px" }}>
            <NavListWrap>
              <NavList className={location.pathname === "/feed" ? "active" : ""}>
                <Link to="/feed">
                  <img style={{ width: "25px", height: "25px" }} src="/images/home.svg" alt="" />
                  <span>Acasa</span>
                </Link>
              </NavList>
              {/* <NavList>
                <a href="/feed">
                  <img src="/images/nav-network.svg" alt="" />
                  <span>My Network</span>
                </a>
              </NavList> */}
              <NavList
                className={location.pathname === "/saved-fundings" ? "active" : ""}
              >
                <Link to="/saved-fundings">
                  <img
                    style={{ width: "25px", height: "25px" }}
                    src="/images/saved.svg" alt="" />
                  <span>Salvate</span>
                </Link>
              </NavList>
              {/* <NavList>
                <Link to="/feed">
                  <img src="/images/nav-work.svg" alt="" />
                  <span>Evenimente</span>
                </Link>
              </NavList> */}
              <NavList
                className="notifications-dropdown"
                onClick={toggleNotificationsDropdown}
                style={{
                  position: "relative", // Ensure dropdown is positioned relative to the user icon
                  cursor: "pointer",
                }}
              >
                <a>
                  <img style={{ width: "25px", height: "25px" }} src="/images/notification.svg" alt="" />
                  <span>Notificari</span>
                </a>
                {notificationsVisible && (
                  <NotificationsDropdown className="dropdown">
                    <h4>Notificari</h4>
                    <div className="notification-item">Nu ai notificari noi</div>
                  </NotificationsDropdown>
                )}
              </NavList>

              <User className="user-dropdown" onClick={toggleDropdown}>
                <a>
                  {user && user.profile_picture ? (
                    <img src={user.profile_picture} alt="" />
                  ) : (
                    <img src="/images/user.svg" alt="" />
                  )}
                  <span>
                    {user?.displayName?.split(' ')[0] || 'Me'} <img src="/images/down-icon.svg" alt="" />
                  </span>
                </a>
                {dropdownVisible && (
                  <Dropdown className="dropdown">
                    <div className="profile-section">
                      <Link to="/profile">
                        {user && user.profile_picture ? (
                          <img src={user.profile_picture} alt="" />
                        ) : (
                          <img src="/images/user.svg" alt="" />
                        )}
                        <div className="profile-info">
                          <h4>{user?.name || "Nume Utilizator"}</h4>
                          <p>Vezi profil</p>
                        </div>
                      </Link>
                    </div>
                    
                    <div className="menu-section">
                      <h4>Cont</h4>
                      {/* <Link to="/settings">Setări & Confidențialitate</Link> */}
                      <Link to="/contact">Contact</Link>
                      {/* <Link to="/aboutus">Despre noi</Link> */}
                      <Link to="/subscription" className="subscription-link">Abonamente</Link>
                    </div>
                    
                    <div className="menu-section">
                      <h4>Management</h4>
                      <Link to="/profile?tab=Companie">Date companie</Link>
                      <Link to="/profile?tab=Personale">Date personale</Link>
                    </div>
                    
                    <SignOutSection>
                      <button 
                        className="sign-out-button"
                        onClick={() => dispatch(logout())}
                      >
                        <FaSignOutAlt />
                        <span>Deconectare</span>
                      </button>
                    </SignOutSection>
                  </Dropdown>
                )}
              </User>

              {/* <Work>
                <a>
                  <img src="/images/nav-work.svg" alt="" />
                  <span>
                    Work <img src="/images/down-icon.svg" alt="" />
                  </span>
                </a>
              </Work> */}
            </NavListWrap>
          </Nav>
        </Content>
      </Container>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {modalContent === 'chat' && (
          <Chat onClose={closeModal} />
        )}
      </Modal>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     user: state.auth.user,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   signOut: () => dispatch(signOutAPI()),
//   search: () => dispatch(())
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Header);

export default Header;