import {
  Avatar,
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Input,
  useToast,
  Skeleton,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { useRef } from 'react'
import axiosInstance from '../../../services/axios'
import { updateUserSuccess } from '../../../reducers/authReducer'

function Sidebar({ loading }) {
  const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)
  const toast = useToast()
  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000'

  const getProfilePictureUrl = (url) => {
    if (!url) return undefined
    return url.startsWith('http') ? url : `${backendUrl}${url}`
  }

  const handleProfilePicClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0]
    if (!file) return

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toast({
        title: "Error",
        description: "Please select an image file",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast({
        title: "Error",
        description: "Image size should be less than 5MB",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }

    try {
      const formData = new FormData()
      formData.append('profile_picture', file)

      const response = await axiosInstance.post('/users/update-profile-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.data) {
        // Get the backend URL from the environment
        const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000'
        const profilePictureUrl = `${backendUrl}${response.data.profile_picture}`

        // Fetch the latest user data
        const userResponse = await axiosInstance.get('/users/me')
        
        // Update the user in Redux store with complete updated data
        dispatch(updateUserSuccess({
          ...userResponse.data,
          profile_picture: profilePictureUrl
        }))

        // Force a re-render of the avatar
        const avatarImg = document.querySelector('.chakra-avatar__img')
        if (avatarImg) {
          avatarImg.src = profilePictureUrl
        }

        toast({
          title: "Success",
          description: "Profile picture updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
      console.error('Error updating profile picture:', error)
      toast({
        title: "Error",
        description: "Could not update profile picture. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Box
      as="aside"
      flex={1}
      mr={{ base: 0, md: 5 }}
      mb={{ base: 5, md: 0 }}
      bg="white"
      rounded="md"
      borderWidth={1}
      borderColor="brand.light"
      style={{ transform: 'translateY(-100px)' }}
    >
      <VStack spacing={6} p={6} align="center">
        <Skeleton isLoaded={!loading} borderRadius="full">
          <Avatar
            size="2xl"
            cursor="pointer"
            onClick={handleProfilePicClick}
            src={getProfilePictureUrl(user?.profile_picture)}
            name={user?.name}
            _hover={{
              opacity: 0.8,
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)'
            }}
          />
        </Skeleton>

        <Input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          display="none"
          onChange={handleFileChange}
        />

        <Skeleton isLoaded={!loading}>
          <VStack spacing={2}>
            <Heading as="h3" fontSize="xl" color="brand.dark">
              {user?.name || 'Loading...'}
            </Heading>
            <Text color="brand.gray" fontSize="sm">
              {user?.email || 'Loading...'}
            </Text>
          </VStack>
        </Skeleton>

        <Skeleton isLoaded={!loading}>
          <Button
            w="full"
            variant="outline"
            colorScheme="blue"
            onClick={handleProfilePicClick}
          >
            Change Profile Picture
          </Button>
        </Skeleton>
      </VStack>
    </Box>
  )
}

export default Sidebar
