// components/FinanceOption.js
import React from "react";
import styles from "./FinanceOption.module.css";
import FundingScroll from "./FundingScroll";
import OnboardingCard from "../Feed/Onboarding/CompanyOnboard2/OnboardingCard";
import { useSelector } from "react-redux";
import FinanceCard from "./FinanceCard";

const FinanceOption = ({
  searchResults,
  setSearchResults,
  filters,
  setFilters,
  hasMore,
  setHasMore,
  viewMode
}) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={`${styles.container} ${viewMode === 'grid' ? styles.gridContainer : ''}`}>
      {/* <div className={styles.header}>
      <h2>Optiuni de finantare</h2>
      <button className={styles.sortButton}>
        <FaSort /> Sorteaza
      </button>
      <button className={styles.filterButton}>
        <FaFilter /> Filtreaza
      </button>
    </div> */}
      {/* <div className={styles.highlight}>
      <h3>Vezi cele mai bune optiuni de finantare ale anului 2023.</h3>
      <button>Vezi optiunile</button>
    </div> */}
      {user && user.companies.length === 0 && (
        <div>
          <OnboardingCard /> 
        </div>
      )}
      <FundingScroll 
        searchResults={searchResults} 
        setSearchResults={setSearchResults}
        filters={filters}
        hasMore={hasMore}
        setHasMore={setHasMore}
        viewMode={viewMode}
      />
    </div>
  );
};

export default FinanceOption;
