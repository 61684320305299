import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaBuilding, FaRegBookmark, FaChevronDown, FaPlus, FaLightbulb } from "react-icons/fa";
import leftStyle from "./NavigationDrawer2.module.css";
import { setCompaniesSuccess, setCurrentCompany } from "../../reducers/authReducer";
import axiosInstance from "../../services/axios";
import FeedbackModal from '../Common/FeedbackModal';

function Left() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const companies = useSelector((state) => state.auth.companies);
  const current_company_id = useSelector((state) => state.auth.current_company_id);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  
  let photoUrl = user && user.profile_picture ? user.profile_picture : "/images/user.svg";

  const handleCompanyClick = (companyId) => {
    dispatch(setCurrentCompany(companyId));
    setIsCompanyDropdownOpen(false);
    window.location.reload();
  };

  const getCurrentCompanyName = () => {
    if (!companies) return "Selectează companie";
    const currentCompany = companies.find(c => c._id === current_company_id);
    return currentCompany ? currentCompany.company_name : "Selectează companie";
  };

  useEffect(() => {
    if (!companies) {
      axiosInstance
        .get("/companies/getAll", { withCredentials: true })
        .then((res) => {
          dispatch(setCompaniesSuccess(res.data));
          if (current_company_id == null && res.data.length > 0) {
            dispatch(setCurrentCompany(res.data[0]._id));
          }
        })
        .catch((error) => {
          console.error("Could not fetch companies", error);
        });
    }
  }, [dispatch]);

  return (
    <div className={leftStyle.navDrawer}>
      <div className={leftStyle.card}>
        {/* Profile Section */}
        <div className={leftStyle.profileSection}>
          <div className={leftStyle.profileBackground} />
          <Link to="/profile" className={leftStyle.profileLink}>
            <div
              className={leftStyle.profilePhoto}
              style={{ backgroundImage: `url(${photoUrl})` }}
            />
            <div className={leftStyle.profileInfo}>
              <h3>Salutare{user && user.first_name ? ", " + user.first_name : ""}!</h3>
              <span>Configureaza profilul</span>
            </div>
          </Link>
        </div>

        {/* Company Section */}
        <div className={leftStyle.section}>
          <div className={leftStyle.companyDropdown}>
            <button 
              className={leftStyle.dropdownButton}
              onClick={() => setIsCompanyDropdownOpen(!isCompanyDropdownOpen)}
            >
              <FaBuilding className={leftStyle.icon} />
              <span>{getCurrentCompanyName()}</span>
              <FaChevronDown className={`${leftStyle.chevron} ${isCompanyDropdownOpen ? leftStyle.chevronUp : ''}`} />
            </button>
            
            {isCompanyDropdownOpen && (
              <div className={leftStyle.dropdownMenu}>
                {companies && companies.map((company) => (
                  <button
                    key={company._id}
                    className={`${leftStyle.dropdownItem} ${current_company_id === company._id ? leftStyle.selected : ''}`}
                    onClick={() => handleCompanyClick(company._id)}
                  >
                    <FaBuilding className={leftStyle.icon} />
                    {company.company_name}
                  </button>
                ))}
                <Link to="/onboard2" className={leftStyle.addCompanyButton}>
                  <FaPlus className={leftStyle.icon} />
                  Adaugă companie nouă
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Navigation Links */}
        <div className={leftStyle.section}>
          <Link to="/saved-fundings" className={leftStyle.navLink}>
            <FaRegBookmark className={leftStyle.icon} />
            Finanțări salvate
          </Link>
        </div>

        {/* Feedback Section */}
        <div className={leftStyle.feedbackSection}>
          <button className={leftStyle.feedbackButton}
              onClick={() => setIsFeedbackOpen(true)}>
            <FaLightbulb className={leftStyle.icon} />
            Ai o sugestie?
          </button>
        </div>
      </div>
      <FeedbackModal
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        initialData={{
          email: user.email,
          // name: user.name,
          // subject: "Sugestie pentru FinAccess"
        }}
        title="Trimite o sugestie"
        submitButtonText="Trimite sugestia"
        axiosInstance={axiosInstance}
      />  
    </div>
  );
}

export default Left;
