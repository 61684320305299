import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import axiosInstance from "../../services/axios";
import styles from "./FundingDetails.module.css";
import Header from "../Feed/Header";
import Chat_details from "./Chat_details";
import { FaCheckCircle, FaTimesCircle, FaQuestionCircle } from "react-icons/fa";
import { parseDate } from "../../utils/general";
import { FaClock, FaHourglassEnd } from "react-icons/fa";
import { Button } from "@chakra-ui/react";
import { RiRobot2Fill } from "react-icons/ri";
import {
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";

const EditableControls = ({ onSubmit, onCancel }) => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

  return isEditing ? (
    <>
      <IconButton icon={<FaSave />} {...getSubmitButtonProps()} onClick={onSubmit} />
      <IconButton icon={<FaTimes />} {...getCancelButtonProps()} onClick={onCancel} />
    </>
  ) : (
    <IconButton icon={<FaEdit />} {...getEditButtonProps()} />
  );
};

const EditableField = ({ isEditing, value, onSave, field }) => {
  const [editedValue, setEditedValue] = useState(value);
  
  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleBlur = () => {
    if (value !== editedValue) {
      onSave(field, editedValue);
    }
  };

  if (Array.isArray(value)) {
    return isEditing ? (
      <textarea
        value={value.join(', ')}
        onChange={(e) => setEditedValue(e.target.value.split(',').map(item => item.trim()))}
        onBlur={handleBlur}
        className={styles.editableTextarea}
      />
    ) : (
      <div>{value.join(', ')}</div>
    );
  }

  return isEditing ? (
    <textarea
      value={editedValue || ''}
      onChange={(e) => setEditedValue(e.target.value)}
      onBlur={handleBlur}
      className={styles.editableTextarea}
    />
  ) : (
    <div className={styles.markdownContent}>
      <ReactMarkdown>{value || ''}</ReactMarkdown>
    </div>
  );
};

const FundingDetails = ({funding}) => {
  const { id } = useParams();
  // const [funding, setFunding] = useState(null);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const sectionRefs = useRef({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedFunding, setEditedFunding] = useState(funding);
  const isSuperUser = useSelector((state) => state.auth.user.is_superuser);
  const toast = useToast();

  const sections = [
    // { id: "descriere", id_short: "descriere_medie", title: "Descriere program" },

    { id: "eligibilitate", title: "Criterii de eligibilitate" },
    { id: "valori", title: "Valoare finanțare" },
    { id: "achizitii", title: "Achizitii" },
    { id: "punctaj", title: "Schema de punctaj" },
  ];

  const scrollToSection = (sectionId) => {
    sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
  };

  // useEffect(() => {
  //   const fetchFundingDetails = async () => {
  //     try {
  //       const response = await axiosInstance.get(`/fundings/details/${id}`);
  //       setFunding(response.data || null);
  //     } catch (error) {
  //       console.error("Error fetching funding details:", error);
  //       setError("Failed to fetch funding details. Please try again later.");
  //     }
  //   };

  //   id ? fetchFundingDetails() : setError("Invalid funding ID");
  // }, [id]);

  if (error) return <div className={styles.error}>{error}</div>;
  if (!funding) return <div className={styles.loading}>Loading...</div>;

  const toggleSection = (sectionName) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionName]: !prev[sectionName]
    }));
  };

  const renderSection = (title, shortContent, longContent, sectionName, hasShortVersion) => {
    const isExpanded = expandedSections[sectionName];
    const content = hasShortVersion ? (isExpanded ? longContent : shortContent) : longContent;
    
    return (
      <section className={styles.section}>
        <div className={styles.sectionHeader}>
          <h2>{title}</h2>
          {hasShortVersion && (
            <div className={styles.toggleButton} onClick={() => toggleSection(sectionName)}>
              <span>{isExpanded ? "Mai puțin" : "Mai mult"}</span>
              <div className={styles.switch}>
                <div className={`${styles.slider} ${isExpanded ? styles.expanded : ''}`}></div>
              </div>
            </div>
          )}
        </div>
        <div className={`${styles.sectionContent} ${isExpanded || !hasShortVersion ? styles.expanded : ''}`}>
          <EditableField
            isEditing={isEditMode}
            value={content}
            onSave={handleSave}
            field={sectionName}
          />
        </div>
      </section>
    );
  };

  const processValue = (valoareMin, valoareMax) => {
    if (!valoareMin && !valoareMax) {
      return "Indisponibil";
    } else if (!valoareMin) {
      return `până la ${valoareMax}`;
    } else if (!valoareMax) {
      return `de la ${valoareMin}`;
    } else {
      return `${valoareMin} - ${valoareMax}`;
    }
  };
  
  const processPercentage = (minPercent, maxPercent) => {
    if (minPercent === 'None' && maxPercent === 'None') {
      return "Indisponibil";
    } else if (minPercent === 'None') {
      return `${maxPercent}%`;
    } else if (maxPercent === 'None') {
      return `de la ${minPercent}`;
    } else {
      return `${minPercent} - ${maxPercent}`;
    }
  };
  
  const formatFieldValue = (value) => {
    if (!value) return "Indisponibil";
    if (Array.isArray(value)) {
      const formatted = value.join(', ');
      return formatted.length > 50 ? `${formatted.substring(0, 47)}...` : formatted;
    }
    return value;
  };

  const getStatusInfo = () => {
    const currentDate = new Date();
    const startDate = funding.data_start_depunere_exact ? parseDate(funding.data_start_depunere_exact) : null;
    const endDate = funding.data_inchidere_depunere_exact ? parseDate(funding.data_inchidere_depunere_exact) : null;

    if (!startDate && !endDate) {
      return { text: "Consultare publica", color: "#f39c12", icon: FaClock };
    } else if (startDate && currentDate < startDate) {
      return { text: "Consultare publica", color: "#f39c12", icon: FaClock };
    } else if ((!startDate || currentDate >= startDate) && (!endDate || currentDate <= endDate)) {
      return { text: "Activ", color: "#2ecc71", icon: FaCheckCircle };
    } else {
      return { text: "Închis", color: "#e74c3c", icon: FaHourglassEnd };
    }
  };

  const statusInfo = getStatusInfo();

  const renderOverview = () => {
    if (!editedFunding) return null;

    return (
      <div className={styles.overviewSection}>
        <h2>Prezentare generală</h2>
        <div className={styles.overviewGrid}>
          <div className={styles.overviewItem}>
            <div className={styles.statusChip} style={{ backgroundColor: statusInfo.color }}>
              <statusInfo.icon color="white" />
              <span style={{ color: "white", marginLeft: "5px" }}>{statusInfo.text}</span>
            </div>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Start:</span>
            <EditableField
              isEditing={isEditMode}
              value={formatFieldValue(editedFunding.data_start_depunere_exact)}
              onSave={handleSave}
              field="data_start_depunere_exact"
            />
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Deadline:</span>
            <EditableField
              isEditing={isEditMode}
              value={formatFieldValue(editedFunding.data_inchidere_depunere_exact)}
              onSave={handleSave}
              field="data_inchidere_depunere_exact"
            />
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Valoare:</span>
              <EditableField
                isEditing={isEditMode}
                value={processValue(editedFunding.valoare_min, editedFunding.valoare_max)}
                onSave={handleSave}
                field="valoare"
              />
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Cofinanțare:</span>
            <EditableField
                isEditing={isEditMode}
                value={processPercentage(editedFunding.procent_minim, editedFunding.procent_maxim)}
                onSave={handleSave}
                field="procent"
              />
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Locație:</span>
            <EditableField
              isEditing={isEditMode}
              value={editedFunding.locatii_eligibile}
              onSave={handleSave}
              field="locatii_eligibile"
            />
          </div>
        </div>
        <div className={styles.overviewDescription}>
          <EditableField
            isEditing={isEditMode}
            value={editedFunding.descriere_medie}
            onSave={handleSave}
            field="descriere_medie"
          />
        </div>
      </div>
    );
  };

  const renderEvaluationDetails = () => {
    if (!funding || !funding.evaluation) return null;

    const { plus_points, minus_points, questions, eligibility_note, scope_note } = funding.evaluation;

    return (
      <div className={styles.evaluationSection}>
        <h2>Evaluare</h2>
        
        <div className={styles.evaluationPoints}>
          <h3>Puncte Forte</h3>
          <ul className={styles.plusPoints}>
            {plus_points.map((point, index) => (
              <li key={index}>
                <FaCheckCircle className={styles.iconGreen} />
                {point}
              </li>
            ))}
          </ul>

          <h3>Puncte Slabe</h3>
          <ul className={styles.minusPoints}>
            {minus_points.map((point, index) => (
              <li key={index}>
                <FaTimesCircle className={styles.iconRed} />
                {point}
              </li>
            ))}
          </ul>

          <h3>Întrebări</h3>
          <ul className={styles.questions}>
            {questions.map((question, index) => (
              <li key={index}>
                <FaQuestionCircle className={styles.iconBlue} />
                {question}
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.evaluationNotes}>
          <div className={styles.noteBox}>
            <h4>Notă Eligibilitate</h4>
            <div className={styles.noteMark}>{eligibility_note.mark}</div>
            <p>{eligibility_note.commentary}</p>
          </div>
          <div className={styles.noteBox}>
            <h4>Notă Scop</h4>
            <div className={styles.noteMark}>{scope_note.mark}</div>
            <p>{scope_note.commentary}</p>
          </div>
        </div>
      </div>
    );
  };

  const handleSave = async (field, value) => {
    try {
      const response = await axiosInstance.put(`/fundings/update/${funding._id}`, {
        [field]: value
      });
      
      if (response.data) {
        setEditedFunding(prev => ({
          ...prev,
          [field]: value
        }));
        toast({
          title: "Success",
          description: "Funding updated successfully",
          status: "success",
          duration: 3000,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update funding",
        status: "error",
        duration: 3000,
      });
    }
  };

  const additionalStyles = {
    valueFields: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    }
  };

  return (
    <div>
      <div className={styles.pageLayout}>
        {/* <div className={styles.tableOfContents}>
          <h2>Cuprins</h2>
          <ul>
            <li><button onClick={() => scrollToSection('overview')}>Prezentare generală</button></li>
            {sections.map((section) => (
              <li key={section.id}>
                <button onClick={() => scrollToSection(section.id)}>
                  {section.title}
                </button>
              </li>
            ))}
            <li><button onClick={() => scrollToSection('evaluation')}>Evaluare</button></li>
          </ul>
        </div> */}
        
        <div className={styles.detailsContainer}>
          {isSuperUser && (
            <Button
              leftIcon={isEditMode ? <FaSave /> : <FaEdit />}
              onClick={() => setIsEditMode(!isEditMode)}
              colorScheme={isEditMode ? "green" : "blue"}
              className={styles.editButton}
            >
              {isEditMode ? "Save Changes" : "Edit Content"}
            </Button>
          )}
          
          <h1>
            <EditableField
              isEditing={isEditMode}
              value={editedFunding.title}
              onSave={handleSave}
              field="title"
            />
            <div className={styles.chatSection}>
              <h2>
                <RiRobot2Fill size={12} />
                Asistent specializat
              </h2>
              <Chat_details fundingId={funding?._id} />
            </div>
          </h1>
          
          <div ref={(el) => (sectionRefs.current['overview'] = el)}>
            {renderOverview()}
          </div>
          
          <div ref={(el) => (sectionRefs.current['evaluation'] = el)}>
            {renderEvaluationDetails()}
          </div>
          {sections.map((section) => (
            <div key={section.id} ref={(el) => (sectionRefs.current[section.id] = el)}>
              {renderSection(
                section.title,
                editedFunding[section.id_short] || `${section.title}...`,
                editedFunding[section.id],
                section.id,
                !!section.id_short
              )}
            </div>
          ))}
          
          <div className={styles.disclaimer}>
            <p>„Acest rezumat a fost obținut prin analizarea documentației oficiale, cu ajutorul unui asistent AI specializat. Deși ne străduim să oferim informații clare, utile și corecte, conținutul de mai sus nu reflectă poziția oficială a instituțiilor europene sau a autorităților finanțatoare și nu înlocuiește consultarea directă a surselor oficiale. Pentru o înțelegere completă și aplicată situației tale, îți recomandăm să discuți cu un consultant în cadrul platformei noastre, sau să verifici informațiile în documentația originală."</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingDetails;
