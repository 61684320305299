import React, { useRef } from "react";
import MiniCard from "./FinanceOptionMini";
import { Box, IconButton, Flex } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import euroFlag from "./assets/europe_flag_circle.png";
import ingLogo from "./assets/ing.png";
import btLeasingLogo from "./assets/btleasing.png";
import bcrLogo from "./assets/bcr.png";
import spotawheelLogo from "./assets/spotawheel.png";
import faguraLogo from "./assets/fagura.png";
import roninLogo from "./assets/ronin.png";

const getItems2 = () => [
  {
    title: "Sprijin pentru întreprinderi nou înființate inovatoare",
    description: "Până la 1,6 milioane de euro, cu 0% cofinanțare",
    image: euroFlag,
    type: "Fond European",
    amount: "până la €1,6 mil",
    likes: 7,
    comments: 20,
  },

  {
    title: "Femeia Antreprenor",
    description: "Granturi în valoare maximă de 200.000 lei/IMM",
    image: euroFlag,
    type: "Fond European",
    amount: "200.000 lei",
    likes: 7,
    comments: 20,
  },
  {
    title: "Credit de Investitii",
    description: "Până la 2.500.000 lei credit de investiții",
    image: ingLogo,
    type: "Credit",
    amount: "până la 2.500.000 lei",
    likes: 7,
    comments: 20,
  },
  {
    title: "Leasing auto electrice sau hybrid",
    description: "Pentru compania ta dobândă în EUR: marja 3,5% + EURIBOR6M",
    image: btLeasingLogo,
    type: "Leasing",
    amount: "",
    likes: 7,
    comments: 20,
  },
  {
    title: "Electric-up 2",
    description:
      "150.000 euro pentru instalare panouri fotovoltaice și stații de încărcare",
    image: euroFlag,
    type: "Fond European",
    amount: "150.000 euro",
    likes: 7,
    comments: 20,
  },
  {
    title: "Credite pentru finanțarea și achiziția culturilor agricole",
    description: "",
    image: bcrLogo,
    type: "Credit",
    amount: "",
    likes: 7,
    comments: 20,
  },
  {
    title: "Leasing cu 0% avans",
    description: "Spotawheel GO, cel mai flexibil leasing cu 0% avans",
    image: spotawheelLogo,
    type: "Leasing",
    amount: "0% avans",
    likes: 7,
    comments: 20,
  },
  {
    title: "Credite online pentru afaceri",
    description: "Obții finanțare direct de la investitori",
    image: faguraLogo,
    type: "Credit",
    amount: "",
    likes: 7,
    comments: 20,
  },
  {
    title: "Fondul de Modernizare",
    description: "Până la 20 milioane de euro, pentru parcuri fotovoltaice",
    image: euroFlag,
    type: "Fond European",
    amount: "până la €20 mil",
    likes: 7,
    comments: 20,
  },
  {
    title: "Finanțare participativă",
    description: "Pentru Start Up-ul tău",
    image: roninLogo,
    type: "Finanțare participativă",
    amount: "",
    likes: 7,
    comments: 20,
  },
];

function HorizontalCarousel() {
  const items = getItems2();
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = 300; // Adjust this value to control scroll distance
    const currentScroll = container.scrollLeft;
    const targetScroll = direction === 'left' 
      ? currentScroll - scrollAmount 
      : currentScroll + scrollAmount;

    container.scrollTo({
      left: targetScroll,
      behavior: 'smooth'
    });
  };

  return (
    <Box position="relative" py={6}>
      <Flex 
        ref={scrollContainerRef}
        overflowX="hidden"
        position="relative"
        px={{ base: "40px", md: "60px" }}
        gap={3}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          scrollBehavior: 'smooth'
        }}
      >
        {items.map((item, index) => (
          <Box 
            key={index}
            flex="0 0 auto"
            width={{ base: "240px", md: "280px" }}
          >
            <MiniCard {...item} />
          </Box>
        ))}
      </Flex>

      <IconButton
        aria-label="Scroll left"
        icon={<ChevronLeftIcon boxSize="24px" />}
        position="absolute"
        left={4}
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
        onClick={() => scroll('left')}
        variant="solid"
        colorScheme="blue"
        size="md"
        borderRadius="full"
        boxShadow="lg"
        _hover={{
          transform: "translateY(-50%) scale(1.1)",
        }}
      />

      <IconButton
        aria-label="Scroll right"
        icon={<ChevronRightIcon boxSize="24px" />}
        position="absolute"
        right={2}
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
        onClick={() => scroll('right')}
        variant="solid"
        colorScheme="blue"
        size="md"
        borderRadius="full"
        boxShadow="lg"
        _hover={{
          transform: "translateY(-50%) scale(1.1)",
        }}
      />
    </Box>
  );
}

export default HorizontalCarousel;
