import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import styles from './CookieBanner.module.css';
import { useNavigate } from 'react-router-dom';

const CookieBanner = () => {
  const navigate = useNavigate();
  const cookieName = "consentCookie";

  const navigateToSettings = () => {
    navigate('/cookie-policy#gestionare');
  };

  const handleAccept = () => {
    // Save preferences with all cookies enabled
    const preferences = {
      essential: true,
      performance: true,
      functionality: true,
      marketing: true,
    };
    document.cookie = `${cookieName}=true; path=/; max-age=${60 * 60 * 24 * 150}`;
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
  };

  const handleDecline = () => {
    // Save preferences with only essential cookies enabled
    const preferences = {
      essential: true,
      performance: false,
      functionality: false,
      marketing: false,
    };
    document.cookie = `${cookieName}=false; path=/; max-age=${60 * 60 * 24 * 150}`;
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
  };

  const bannerStyle = {
    background: "#f3f2ef",
    color: "#333",
    fontSize: "14px",
    padding: "20px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    animation: "fadeIn 1s ease-in-out",
  };

  const buttonStyle = {
    background: "#749db4",
    color: "#fff",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "10px 20px",
    border: "none",
    margin: "5px",
    transition: "background 0.3s",
    cursor: "pointer",
  };

  const declineButtonStyle = {
    background: "#e0e0e0",
    color: "#333",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "10px 20px",
    border: "none",
    margin: "5px",
    transition: "background 0.3s",
    cursor: "pointer",
  };

  const contentStyle = {
    textAlign: "left",
  };

  const linkStyle = {
    textDecoration: "underline",
    color: "#0073b1",
    cursor: "pointer",
  };

  return (
    <CookieConsent
      sameSite="none"
      cookieSecurity={false}
      location="bottom"
      buttonText="Accept"
      declineButtonText="Respinge"
        enableDeclineButton
        cookieName="consentCookie"
        style={bannerStyle}
        buttonStyle={buttonStyle}
        declineButtonStyle={declineButtonStyle}
        contentStyle={contentStyle}
        containerClasses={styles.cookieBanner}
        buttonClasses={styles.button}
        declineButtonClasses={styles.declineButton}
        expires={150}
        overlayStyle={{ animation: "fadeIn 1s ease-in-out" }}
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        Ctrl.fund și terți folosesc cookie-uri esențiale și neesențiale pentru a
        furniza, securiza, analiza și îmbunătăți Serviciile noastre. Aflați mai multe în{" "}
        <span 
          onClick={() => navigate("/cookie-policy")} 
          style={linkStyle}
        >
          Politica noastră privind cookie-urile
        </span>
        .
        <br />
        Selectați Accept pentru a consimți sau Respinge pentru a refuza cookie-urile neesențiale pentru
        această utilizare. Vă puteți actualiza opțiunile în orice moment în{" "}
        <span 
          onClick={navigateToSettings} 
          style={linkStyle}
        >
          setările dvs.
        </span>
        .
      </CookieConsent>
    )
};

export default CookieBanner;