export const ERROR_MESSAGES = {
    // Authentication Errors
    "LOGIN_BAD_CREDENTIALS": "Email sau parolă invalidă. Vă rugăm să încercați din nou.",
    "LOGIN_USER_NOT_VERIFIED": "Vă rugăm să vă verificați emailul înainte de a vă conecta.",
    "REGISTER_USER_ALREADY_EXISTS": "Există deja un cont asociat acestui email.",
    "REGISTER_INVALID_PASSWORD": "Parola trebuie să aibă între 8 și 24 de caractere.",
    "TOKEN_EXPIRED": "Sesiunea dumneavoastră a expirat. Vă rugăm să vă autentificați din nou.",
    "TOKEN_INVALID": "Token de autentificare invalid. Vă rugăm să vă autentificați din nou.",

    // Validation Errors
    "INVALID_EMAIL_FORMAT": "Vă rugăm să introduceți o adresă de email validă.",
    "INVALID_PASSWORD_FORMAT": "Parola trebuie să conțină cel puțin 8 caractere.",
    "INVALID_CUI_FORMAT": "Format CUI invalid. Vă rugăm să verificați și să încercați din nou.",

    // Resource Errors
    "COMPANY_ALREADY_REGISTERED": "Această companie este deja înregistrată în sistemul nostru.",
    "COMPANY_NOT_FOUND": "Compania nu a fost găsită.",
    "USER_NOT_FOUND": "Utilizatorul nu a fost găsit.",

    // Generic Errors
    "REQUIRED_FIELD": "Acest câmp este obligatoriu.",
    "INTERNAL_SERVER_ERROR": "A apărut o eroare neașteptată. Vă rugăm să încercați din nou mai târziu.",
    "NETWORK_ERROR": "Nu se poate conecta la server. Vă rugăm să verificați conexiunea la internet."
  }

  export const error2message = (error) => {
    // Handle API specific error responses
    if (error.response?.data?.detail) {
      const errorDetail = error.response.data.detail;
      
      // Handle string error details
      if (typeof errorDetail === 'string') {
        switch (errorDetail) {
          case 'LOGIN_BAD_CREDENTIALS':
            return ERROR_MESSAGES.LOGIN_BAD_CREDENTIALS;
          case 'LOGIN_USER_NOT_VERIFIED':
            return ERROR_MESSAGES.LOGIN_USER_NOT_VERIFIED;
          case 'REGISTER_USER_ALREADY_EXISTS':
            return ERROR_MESSAGES.REGISTER_USER_ALREADY_EXISTS;
          case 'REGISTER_INVALID_PASSWORD':
            return ERROR_MESSAGES.REGISTER_INVALID_PASSWORD;
          case 'TOKEN_EXPIRED':
            return ERROR_MESSAGES.TOKEN_EXPIRED;
          case 'TOKEN_INVALID':
            return ERROR_MESSAGES.TOKEN_INVALID;
          case 'COMPANY_ALREADY_REGISTERED':
            return ERROR_MESSAGES.COMPANY_ALREADY_REGISTERED;
          default:
            return errorDetail; // Use the API's error message if we don't have a specific mapping
        }
      }
      
      // Handle FastAPI's validation error format
      if (Array.isArray(errorDetail)) {
        return errorDetail
          .map(err => `${err.loc.slice(-1)[0]}: ${err.msg}`)
          .join('\n');
      }
    }
  
    // Handle HTTP status codes for cases where detail isn't provided
    if (error.response?.status) {
      switch (error.response.status) {
        case 401:
          return ERROR_MESSAGES.LOGIN_BAD_CREDENTIALS;
        case 403:
          return ERROR_MESSAGES.TOKEN_INVALID;
        case 404:
          return ERROR_MESSAGES.USER_NOT_FOUND;
        case 500:
          return ERROR_MESSAGES.INTERNAL_SERVER_ERROR;
        default:
          return ERROR_MESSAGES.INTERNAL_SERVER_ERROR;
      }
    }
  
    // Handle network errors
    if (error.message === 'Network Error') {
      return ERROR_MESSAGES.NETWORK_ERROR;
    }
  
    return ERROR_MESSAGES.INTERNAL_SERVER_ERROR;
  };

// export const error2message = (error) => {
//     let errorMessage = "A apărut o eroare neașteptată. Te rugăm să încerci din nou mai târziu.";
    
//     if (error.response) {
//         switch (error.response.status) {
//             case 400:
//                 errorMessage = "Datele introduse sunt incorecte sau incomplete. Te rugăm să verifici informațiile și să încerci din nou.";
//                 break;
//             case 401:
//                 errorMessage = "Nu ești autorizat să efectuezi această acțiune. Te rog să te autentifici.";
//                 break;
//             case 403:
//                 errorMessage = "Acces interzis. Nu ai permisiunea necesară pentru a accesa această resursă.";
//                 break;
//             case 404:
//                 errorMessage = "Resursa solicitată nu a fost găsită. Te rugăm să verifici linkul sau să încerci o altă acțiune.";
//                 break;
//             case 422:
//                 errorMessage = "Datele nu pot fi procesate. Te rugăm să verifici informațiile introduse.";
//                 break;
//             case 500:
//                 errorMessage = "A apărut o eroare internă a serverului. Te rugăm să încerci din nou mai târziu.";
//                 break;
//             default:
//                 errorMessage = "A apărut o eroare. Te rugăm să încerci din nou.";
//         }

//         if (error.response.data.detail) {
//             switch (error.response.data.detail) {
//                 case "REGISTER_USER_ALREADY_EXISTS":
//                     errorMessage = "Un utilizator cu acest email a fost deja înregistrat. Te rog să folosești un alt email.";
//                     break;
//                 case "REGISTER_INVALID_PASSWORD":
//                     errorMessage = "Parola introdusă este invalidă. Te rugăm să încerci din nou.";
//                     break;
//                 default:
//                     errorMessage = "A apărut o eroare. Te rugăm să încerci din nou.";
//             }
//         }
//     } else {
//         errorMessage = "Eroare de rețea sau răspuns necunoscut de la server. Te rugăm să verifici conexiunea ta la internet.";
//     }

//     return errorMessage;
// };

export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email) || "Adresa de email invalida";
  };

function parseDotDate(dateStr) {
    const [day, month, year] = dateStr.split('.').map(Number);
    return new Date(year, month - 1, day); // months are 0-indexed
}

function parseRomanianMonth(dateStr) {
    const monthMap = {
      "ianuarie": 0, "februarie": 1, "martie": 2, "aprilie": 3, "mai": 4, "iunie": 5,
      "iulie": 6, "august": 7, "septembrie": 8, "octombrie": 9, "noiembrie": 10, "decembrie": 11
    };
  
    const [months, year] = dateStr.split(' ');
  
    // Check if it's a month range like "noiembrie-decembrie"
    if (months.includes('-')) {
      const [startMonth, endMonth] = months.split('-').map(m => monthMap[m.toLowerCase()]);
    //   return {
    //     startDate: new Date(Number(year), startMonth, 1),
    //     endDate: new Date(Number(year), endMonth + 1, 0) // last day of the end month
    //   };
    return new Date(Number(year), endMonth + 1, 0);
    } else {
      // Single month case like "noiembrie 2015"
      const month = monthMap[months.toLowerCase()];
    //   return {
    //     startDate: new Date(Number(year), month, 1),
    //     endDate: new Date(Number(year), month + 1, 0) // last day of the same month
    //   };
    return new Date(Number(year), month + 1, 0)
    }
  }

export const parseDate = (dateStr) => {
    // Check if it's in "dd.mm.yyyy" format
    if (/\d{2}\.\d{2}\.\d{4}/.test(dateStr)) {
        // console.log("Date in format dd.mm.yyyy");
        // console.log(parseDotDate(dateStr));
      return parseDotDate(dateStr);
    }
    
    // Check if it's in "month-month year" or "month year" format
    if (/[a-zăâîșț]+(\-[a-zăâîșț]+)? \d{4}/i.test(dateStr)) {
        // console.log("Date in format month-month year");
        // console.log(parseRomanianMonth(dateStr));
      return parseRomanianMonth(dateStr);
    }
    
    // console.log("Unknown date format");
    // console.log(dateStr);
    return null;
  }