import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FinanceCard from "./FinanceCard";
import axiosInstance from "../../services/axios";
import styles from "./FinanceOption.module.css";

function FundingScroll({
  searchResults,
  setSearchResults,
  filters,
  searchText,
  hasMore,
  setHasMore,
  viewMode
}) {
  const fetchMoreData = () => {
    if (!hasMore) {
      console.log("No more data");
      return;
    }
    console.log("Fetching more data");
    const url = `/fundings/load/${searchResults.length}`;

    const company_id = localStorage.getItem("current_company_id");

    const params = {
      company_id: company_id,
      search_text: searchText,
      ...filters,
      offset: searchResults.length
    };
    console.log("Params: ", params);
    axiosInstance
      .get(url, { params, withCredentials: true })
      .then((response) => response.data)
      .then((newItems) => {
        if (newItems.length < 10) {
          setHasMore(false);
        }
        setSearchResults(prevResults => [...prevResults, ...newItems]);
      })
      .catch((error) => console.error("Error:", error));
  };

  const LoadingCard = () => (
    <div className={styles.loadingCard}>
      <div className={styles.loadingAnimation}></div>
    </div>
  );

  const NoResultsMessage = () => (
    <div className={styles.endCard}>
      <p className={styles.noResultsMessage}>
        <b>Nu s-au găsit finanțări relevante.</b>
      </p>
    </div>
  );

  const EndListMessage = () => (
    <div className={styles.endCard}>
      <p className={styles.noResultsMessage}>
        <b>Ați ajuns la sfârșitul listei</b>
        <br />
        Nu mai sunt alte oportunități de finanțare disponibile în acest moment
      </p>
    </div>
  );

  return (
    <div className={styles.container}>
      {searchResults.length === 0 && !hasMore ? (
        <NoResultsMessage />
      ) : (
        <InfiniteScroll
          dataLength={searchResults.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<LoadingCard />}
          endMessage={<EndListMessage />}
          style={{ overflow: 'visible' }}
          className={`${styles.fundingList} ${viewMode === 'grid' ? styles.gridContainer : ''}`}
        >
          {searchResults.map((item, index) => (
            <FinanceCard
              key={item._id || index}
              funding={item}
              grid={viewMode === 'grid'}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default FundingScroll;
