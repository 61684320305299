import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { getUserAuth } from "../action";
import { connect } from "react-redux";
import Landing4 from "./Landing4/Landing";
import AboutUs from "./Landing4/AboutUs/AboutUs";
import Signin2 from "./Auth/Signin2";
import Signup2 from "./Auth/Signup2";
import CookiePolicy from "./Landing4/CookiePolicy";
import TermsAndConditions from "./Landing4/TermsAndConditions";
import Contact from "./Landing4/Contact";
import Coming from "./Coming/Coming";
// import Incubators from "./Helpers/Incubators/Incubators";
import ForgotPassword from "./Auth/ForgotPassword";
import ForgotPassword2 from "./Auth/ForgotPassword2";

import EmailVerification from "./Auth/EmailVerification";
// import Feed from "./Feed/Feed";
import Onboard from "./Feed/Onboarding/CompanyOnboard/OnboardCompany";
import Onboard2 from "./Feed/Onboarding/CompanyOnboard2/OnboardCompany";
import OAuthCallback from "./Auth/OAuthCallback";
// import Plain from "./Feed/Plain";
// import LikesPage from "./Feed/LikesPage";
import CompanyProfile from "./CompanyProfile/Main";
import Feed2 from "./Feed2/Feed2";
import CookieBanner from "./Cookies/CookieBanner";
import { useDispatch } from "react-redux";
import { loadUserFromStorage } from "../reducers/authReducer";
import Chat from "./Feed2/Chat";
import FundingDetailsFullPage from "./Feed2/FundingDetailsFullPage";
import SavedFundings from './Feed2/SavedFundings';
import { ChatProvider } from '../ChatContext';
import Subscription from './Subscription/Subscription';
import SubscriptionSuccess from './Subscription/SubscriptionSuccess';

function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserFromStorage());
  }, [dispatch]);

  return (
    <ChatProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Landing4 />} />
            {/* <Route path="/landing2" element={<Landing2 />} />
            <Route path="/landing3" element={<Landing3 />} />
            <Route path="/landing4" element={<Landing4 />} /> */}
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signin" element={<Signin2 />} />
            <Route path="/signup" element={<Signup2 />} />
            <Route path="/feed" element={<Feed2 />} />
            <Route path="/oauth-callback" element={<OAuthCallback />} />
            <Route path="/coming" element={<Coming />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/subscription/success" element={<SubscriptionSuccess />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />

            {/* <Route path="/register" element={<Register />} /> */}
            {/* <Route path="/signin" element={<Signin />} /> */}
            <Route path="/verify" element={<EmailVerification />} />
            <Route
              path="/verify/:verificationToken"
              element={<EmailVerification />}
            />
            <Route path="/forgot-password-old" element={<ForgotPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword2 />} />

            <Route
              path="/forgot-password/:resetToken"
              element={<ForgotPassword />}
            />
            {/* <Route path="/feed0" element={<Feed />} /> */}
            {/* <Route path="/linkedin" element={<LinkedInCallback />} /> */}
            <Route path="/onboard" element={<Onboard />} />
            <Route path="/onboard2" element={<Onboard2 />} />

            <Route path="/funding/:id" element={<FundingDetailsFullPage />} />
            {/* <Route path="/savedFundings" element={<LikesPage />} /> */}
            {/* <Route path="/profile" element={<UserProfile />} /> */}
            <Route path="/profile" element={<CompanyProfile />} />
            {/* <Route path="/incubators" element={<Incubators />} /> */}
            <Route path="/saved-fundings" element={<SavedFundings />} />
          </Routes>
          <CookieBanner />
        </Router>
      </div>
    </ChatProvider>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  getUserAuth: () => dispatch(getUserAuth()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
