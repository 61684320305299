import React, { useState, useEffect, useRef } from 'react';
import { useChat } from '../../ChatContext';
import styles from './Chat.module.css';
import { Button, Spinner, Icon, Avatar, useDisclosure } from "@chakra-ui/react"
import { IoSend, IoClose } from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from "react-icons/fa";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import FundingDetails from "./FundingDetails";
import { useSelector } from "react-redux";

const MiniFinanceCard = ({ funding, citationRefs, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const handleMouseEnter = () => {
    citationRefs[index].forEach(ref => {
      if (ref.current) {
        ref.current.classList.add(styles.highlighted);
      }
    });
  };

  const handleMouseLeave = () => {
    citationRefs[index].forEach(ref => {
      if (ref.current) {
        ref.current.classList.remove(styles.highlighted);
      }
    });
  };

  const processValue = (valoareMin, valoareMax) => {
    valoareMin = valoareMin || "null";
    valoareMax = valoareMax || "null";
    if (valoareMin.includes("null") && valoareMax.includes("null")) {
      return "Neclar";
    } else if (valoareMin.includes("null")) {
      return `până la ${valoareMax}`;
    } else if (valoareMax.includes("null")) {
      return `de la ${valoareMin}`;
    } else {
      return `${valoareMin} - ${valoareMax}`;
    }
  };

  return (
    <>
      <div 
        className={styles.miniFinanceCard} 
        onClick={onOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.miniCardHeader}>
          <Avatar 
            src="/images/european_flag.png" 
            size="sm"
            className={styles.miniCardAvatar}
          />
          <div className={styles.miniCardTitle}>
            {funding.title}
          </div>
        </div>
        <div className={styles.miniCardBody}>
          <div className={styles.miniCardDetails}>
            <div className={styles.detailItem}>
              <span>Valoare:</span> {processValue(funding.valoare_min, funding.valoare_max)}
            </div>
            <div className={styles.detailItem}>
              <span>Termen:</span> {funding.termen_depunere}
            </div>
            <div className={styles.detailItem}>
              <span>Cofinanțare:</span> {funding.valoare_cofinantare}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxWidth="1100px" maxHeight="90vh">
          <ModalHeader>{funding.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <FundingDetails funding={funding} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

function Chat({ fundingId, onClose, showFundings }) {
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const { threadId, isInitialized, messages, addMessage } = useChat();
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const currentCompanyId = useSelector((state) => state.auth.current_company_id);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = async () => {
        if (!input.trim() || !threadId) return;

        setIsLoading(true);
        addMessage((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);
        setInput('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/chat`, {
                method: 'POST',
                credentials: 'include',
                company_id: currentCompanyId,
                headers: {
                    'Content-Type': 'application/json',
                    'company_id': currentCompanyId,
                },
                body: JSON.stringify({ message: input, thread_id: threadId, funding_id: fundingId }),
            });
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let botMessage = '';
    
            setIsTyping(true);
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value, { stream: true });
                
                // Force immediate processing of each chunk
                await new Promise(resolve => setTimeout(resolve, 0));
                
                botMessage += chunk;
                const currentMessage = botMessage;
                addMessage((prevMessages) => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    
                    if (lastMessage?.sender === 'bot') {
                        if (chunk.startsWith('data: IndexMap:')) {
                            const indexMap = JSON.parse(chunk.replace('data: IndexMap:', '').trim());
                            return prevMessages.map((msg, index) => 
                                index === prevMessages.length - 1 
                                    ? { ...msg, indexMap }
                                    : msg
                            );
                        }
                        if (chunk.startsWith('data: Citations:')) {
                            const citationsData = JSON.parse(chunk.replace('data: Citations:', '').trim());
                            console.log(citationsData);

                            return prevMessages.map((msg, index) => 
                                index === prevMessages.length - 1 
                                    ? { 
                                        ...msg, 
                                        citations: msg.citations ? 
                                            [...msg.citations, ...citationsData] : 
                                            citationsData 
                                      }
                                    : msg
                            );
                        }
                        return prevMessages.map((msg, index) => 
                            index === prevMessages.length - 1 
                                ? { ...msg, text: currentMessage }
                                : msg
                        );
                    } else {
                        return [...prevMessages, { text: currentMessage, sender: 'bot' }];
                    }
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            addMessage((prevMessages) => [...prevMessages, { text: 'Error sending message', sender: 'system' }]);
        } finally {
            setIsTyping(false);
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.chatContainer}>
            <div className={styles.chatHeader}>
                <div className={styles.chatHeaderLeft}>
                    <Avatar 
                        icon={<RiRobot2Fill />} 
                        bg="linear-gradient(135deg, #6B46C1, #805AD5)" 
                        color="white" 
                        size="sm" 
                        mr={2}
                    />
                    <div className={styles.headerInfo}>
                        <span className={styles.headerTitle}>Asistent AI</span>
                        <span className={styles.headerStatus}>Online</span>
                    </div>
                </div>
                <Button 
                    onClick={onClose} 
                    className={styles.closeButton}
                    variant="ghost"
                    size="sm"
                >
                    <IoClose size={20} />
                </Button>
            </div>
            
            <div className={styles.chatBody}>
                <div className={styles.welcomeMessage}>
                    <div className={styles.welcomeIcon}>
                        <RiRobot2Fill size={32} />
                    </div>
                    <h3>Bun venit! Sunt asistentul tău AI</h3>
                    <p>Te pot ajuta să găsești oportunitatea de finanțare potrivită pentru tine. Întreabă-mă orice despre finanțările disponibile!</p>
                </div>

                <div className={styles.messagesContainer}>
                    {messages.map((msg, index) => {
                        if (msg.sender === 'bot' && msg.text) {
                            const citationRefs = {};
                            const uniqueCitations = msg.citations ? msg.citations : [];
                            
                            uniqueCitations.forEach((citation, idx) => {
                                citationRefs[idx] = [];
                            });

                            const processedText = msg.text.replace(/【(\d+):(\d+)†source】/g, (match, fileNum, citationNum) => {
                                const citationIndex = uniqueCitations.findIndex(c => 
                                    c.original_indices && c.original_indices.includes(parseInt(fileNum))
                                );
                                
                                if (citationIndex >= 0) {
                                    const ref = React.createRef();
                                    citationRefs[citationIndex].push(ref);
                                    return (
                                        <span
                                            key={`${fileNum}:${citationNum}`}
                                            ref={ref}
                                            className={styles.citationReference}
                                            onMouseEnter={() => {
                                                const card = document.querySelector(`[data-citation-index="${citationIndex}"]`);
                                                if (card) card.classList.add(styles.highlighted);
                                            }}
                                            onMouseLeave={() => {
                                                const card = document.querySelector(`[data-citation-index="${citationIndex}"]`);
                                                if (card) card.classList.remove(styles.highlighted);
                                            }}
                                        >
                                            [{citationNum}]
                                        </span>
                                    );
                                }
                                return match;
                            });

                            return (
                                <div key={index} className={`${styles.message} ${styles[msg.sender]}`}>
                                    <div className={styles.messageContent}>
                                        <Avatar 
                                            icon={<RiRobot2Fill />} 
                                            bg="linear-gradient(135deg, #6B46C1, #805AD5)" 
                                            color="white" 
                                            size="xs" 
                                            mr={2} 
                                        />
                                        <div className={styles.messageBubble}>
                                            <div className={styles.messageText}>
                                                {typeof processedText === 'string' 
                                                    ? processedText 
                                                    : React.Children.map(processedText, child => {
                                                        if (React.isValidElement(child)) return child;
                                                        return child;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {uniqueCitations.length > 0 && (
                                        <div className={styles.citationsContainer}>
                                            <div className={styles.citationsLabel}>
                                                <FaCheckCircle className={styles.citationIcon} />
                                                Surse relevante
                                            </div>
                                            <div className={styles.miniCardsGrid}>
                                                {uniqueCitations.map((funding, idx) => (
                                                    <div key={funding._id || idx} data-citation-index={idx}>
                                                        <MiniFinanceCard 
                                                            funding={funding} 
                                                            citationRefs={citationRefs}
                                                            index={idx}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        }
                        return (
                            <div key={index} className={`${styles.message} ${styles.user}`}>
                                <div className={styles.messageContent}>
                                    <div className={styles.messageBubble}>
                                        <div className={styles.messageText}>{msg.text}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {isTyping && (
                        <div className={`${styles.message} ${styles.bot}`}>
                            <div className={styles.messageContent}>
                                <Avatar 
                                    icon={<RiRobot2Fill />} 
                                    bg="linear-gradient(135deg, #6B46C1, #805AD5)" 
                                    color="white" 
                                    size="xs" 
                                    mr={2} 
                                />
                                <div className={`${styles.messageBubble} ${styles.typing}`}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
            </div>

            <div className={styles.inputContainer}>
                <input
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                    disabled={isLoading}
                    placeholder="Scrie un mesaj..."
                    className={styles.chatInput}
                />
                <Button 
                    onClick={sendMessage} 
                    disabled={isLoading}
                    className={styles.sendButton}
                    variant="solid"
                    colorScheme="purple"
                >
                    {isLoading ? <Spinner size="sm" /> : <Icon as={IoSend} />}
                </Button>
            </div>
        </div>
    );
}

export default Chat;
