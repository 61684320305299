import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FinanceCard.module.css";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  useToast,
  Avatar,
  useDisclosure,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  useEditableControls,
} from "@chakra-ui/react";
import {
  FaChevronUp,
  FaChevronDown,
  FaTimesCircle,
  FaCheckCircle,
  FaQuestionCircle,
  FaHandHoldingUsd,
  FaThumbsUp,
  FaLinkedinIn,
  FaTwitter,
  FaFacebook,
  FaComments,
  FaShare,
  FaRegThumbsUp,
  FaStar,
  FaClipboardCheck,
  FaClock,
  FaHourglassEnd,
  FaHandSparkles,
  FaUserTie,
  FaBookmark,
  FaRegBookmark,
  FaLink,
  FaEdit,
  FaSave,
  FaTimes,
} from "react-icons/fa";
import { BiLike, BiChat, BiShare, BiSend } from "react-icons/bi";
import { TimeIcon, EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { PopupModal } from "react-calendly";
import { useSelector } from "react-redux";
import axiosInstance from "../../services/axios";
import { updateLikes } from "../../reducers/authReducer";
// import ColoredExpandableText from "../Feed/FundingCard/ColoredExpandableText";
import FundingDetails from "./FundingDetails"; // Assume this component exists
import { parseDate } from "../../utils/general";
import { sendFeedback } from '../../utils/feedback';
import FeedbackModal from '../Common/FeedbackModal';

const processValue = (valoareMin, valoareMax) => {
  if (!valoareMin && !valoareMax) {
    return "Indisponibil";
  } else if (!valoareMin) {
    return `până la ${valoareMax}`;
  } else if (!valoareMax) {
    return `de la ${valoareMin}`;
  } else {
    return `${valoareMin} - ${valoareMax}`;
  }
};

const processPercentage = (minPercent, maxPercent) => {
  if (minPercent === 'None' && maxPercent === 'None') {
    return "Indisponibil";
  } else if (minPercent === 'None') {
    return `${maxPercent}%`;
  } else if (maxPercent === 'None') {
    return `de la ${minPercent}`;
  } else {
    return `${minPercent} - ${maxPercent}`;
  }
};

const formatFieldValue = (value) => {
  if (!value) return "Indisponibil";
  if (Array.isArray(value)) {
    const formatted = value.join(', ');
    return formatted.length > 50 ? `${formatted.substring(0, 47)}...` : formatted;
  }
  return value;
};

const EditableControls = ({ onSubmit, onCancel }) => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

  return isEditing ? (
    <>
      <IconButton icon={<FaSave />} {...getSubmitButtonProps()} onClick={onSubmit} />
      <IconButton icon={<FaTimes />} {...getCancelButtonProps()} onClick={onCancel} />
    </>
  ) : (
    <IconButton icon={<FaEdit />} {...getEditButtonProps()} />
  );
};

const EditableField = ({ isEditing, value, onSave, field }) => {
  const [editedValue, setEditedValue] = useState(value);
  
  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleBlur = () => {
    if (value !== editedValue) {
      onSave(field, editedValue);
    }
  };

  const displayValue = formatFieldValue(value);

  if (Array.isArray(value)) {
    return isEditing ? (
      <textarea
        value={value.join(', ')}
        onChange={(e) => setEditedValue(e.target.value.split(',').map(item => item.trim()))}
        onBlur={handleBlur}
        className={styles.editableTextarea}
      />
    ) : (
      <span>{value.join(', ')}</span>
    );
  }

  return isEditing ? (
    <textarea
      value={editedValue || ''}
      onChange={(e) => setEditedValue(e.target.value)}
      onBlur={handleBlur}
      className={styles.editableTextarea}
    />
  ) : (
    <span>{displayValue}</span>
  );
};

const FinanceCard = ({ funding, grid }) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const selectedCompany = user.companies[0];
  const dispatch = useDispatch();
  const toast = useToast();
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [message, setMessage] = useState(user.name);
  const [isEligibilityVisible, setIsEligibilityVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [editedFunding, setEditedFunding] = useState(funding);
  const [isEditing, setIsEditing] = useState(false);
  const isSuperUser = useSelector((state) => state.auth.user.is_superuser);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalFunding, setModalFunding] = useState(null);

  const copyToClipboard = async () => {
    try {
      const url = `${window.location.origin}/funding/${funding._id}`;
      await navigator.clipboard.writeText(url);
      // Optional: Add a toast notification
      toast({
        title: "Link copied!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error('Failed to copy:', err);
      toast({
        title: "Failed to copy link",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const toggleEligibilityVisibility = () => {
    setIsEligibilityVisible(!isEligibilityVisible);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    if (user.liked_fundings.includes(funding.funding_id)) {
      setIsLiked(true);
    }
  }, [funding.funding_id, user]);

  const toggleLike = () => {
    setIsLiked(!isLiked);
    // console.log(funding);
    let params = {
      fundingId: funding.funding_id,
      isLiked: !isLiked,
    };
    axiosInstance
      .get(`/users/like/`, { params })
      .then((response) => {
        // console.log("Likes: ", response);
        dispatch(
          updateLikes({ funding_id: funding.funding_id, isLiked: !isLiked })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEligibilityColor = (eligibility) => {
    // if false in the list, return red else green
    if (eligibility.includes(false)) {
      return "red";
    } else {
      return "green";
    }
  };

  const shareOnSocialMedia = (platform) => {
    // Construct the URL for sharing
    const url = `${window.location.origin}/funding/${funding._id}`; //encodeURIComponent()
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check out this great opportunity: ${encodeURIComponent(
          funding.title
        )}`;
        break;
      case "linkedin":
        // shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodeURIComponent(
        //   funding.title
        // )}`;
        shareUrl = `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${url}&text=${encodeURIComponent(
          funding.title
        )}`;
        break;
      case "email":
        const subject = encodeURIComponent(`Check this out: ${funding.title}`);
        const body = encodeURIComponent(`Take a look at this: ${url}`);
        window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
        break;
      default:
        console.log("error");
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  const getCircleColor = (score) => {
    if (score >= 75) {
      return "green";
    } else if (score >= 50) {
      return "#f1c40f"; // Yellow for medium scores
    } else {
      return "#e74c3c"; // Red for low scores
    }
  };

  const calendlyUrl = "https://calendly.com/cosmin-n-moisii/discutie-initiala";

  // const handleToggleExpandSuitable = () => {
  //   setSuitableExpanded(!expandedSuitable);
  // };

  const handleCardClick = (e) => {
    // Don't trigger click when clicking on editable fields or the edit button
    if (
      e.target.closest(`.${styles.editableTextarea}`) || 
      e.target.closest(`.${styles.editButton}`) ||
      e.target.closest(`.${styles.footerActions}`)
    ) {
      return;
    }
    
    if (funding) {
      setModalFunding(funding);
      onOpen();
    } else {
      console.error("Invalid funding");
    }
  };

  const getEligibilityBadge = () => {
    if (!funding.evaluation) {
      console.log("no eligibility");
      console.log(funding);
      return {
        icon: <FaQuestionCircle color="#400000" />,
        text: "Eligibilitate necunoscută",
        color: "#3b3b3b",
        description: "Fă onboarding pentru a verifica potrivirea",
        link: "/onboard2"
      };
    }
    console.log("found eligibility");
    console.log(funding.evaluation);
    const totalScore = funding.evaluation.eligibility_note.mark + funding.evaluation.scope_note.mark;
    if (totalScore >= 150) {
      return {
        icon: <FaCheckCircle color="green" />,
        text: "Potrivit pentru tine",
        color: "green",
        eligibilityMark: funding.evaluation.eligibility_note.mark,
        scopeMark: funding.evaluation.scope_note.mark
      };
    } else if (totalScore >= 100) {
      return {
        icon: <FaQuestionCircle color="darkorange" />,
        text: "Poate fi potrivit pentru tine",
        color: "darkorange",
        eligibilityMark: funding.evaluation.eligibility_note.mark,
        scopeMark: funding.evaluation.scope_note.mark
      };
    } else {
      return {
        icon: <FaTimesCircle color="#e74c3c" />,
        text: "Mai puțin potrivit pentru tine",
        color: "#e74c3c",
        eligibilityMark: funding.evaluation.eligibility_note.mark,
        scopeMark: funding.evaluation.scope_note.mark
      };
    }
  };

  const eligibilityBadge = getEligibilityBadge();

  const getStatusInfo = () => {
    const currentDate = new Date();
    const startDate = funding.data_start_depunere_exact ? parseDate(funding.data_start_depunere_exact) : null;
    const endDate = funding.data_inchidere_depunere_exact ? parseDate(funding.data_inchidere_depunere_exact) : null;

    if (!startDate && !endDate) {
      return { text: "Consultare publica", color: "#f39c12", icon: FaClock };
    } else if (startDate && currentDate < startDate) {
      return { text: "Consultare publica", color: "#f39c12", icon: FaClock };
    } else if ((!startDate || currentDate >= startDate) && (!endDate || currentDate <= endDate)) {
      return { text: "Activ", color: "#2ecc71", icon: FaCheckCircle };
    } else {
      return { text: "Închis", color: "#e74c3c", icon: FaHourglassEnd };
    }
  };

  const statusInfo = getStatusInfo();

  const handleSave = async (field, value) => {
    try {
      const response = await axiosInstance.put(`/fundings/update/${funding._id}`, {
        [field]: value
      });
      
      if (response.data) {
        setEditedFunding(prev => ({
          ...prev,
          [field]: value
        }));
        toast({
          title: "Success",
          description: "Funding updated successfully",
          status: "success",
          duration: 3000,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update funding",
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <div className={`${styles.cardContainer} ${grid ? styles.gridView : ''}`}>
      {isSuperUser && (
        <Button
          leftIcon={isEditMode ? <FaSave /> : <FaEdit />}
          onClick={() => setIsEditMode(!isEditMode)}
          colorScheme={isEditMode ? "green" : "blue"}
          className={styles.editButton}
          size="sm"
        >
          {isEditMode ? "Save" : "Edit"}
        </Button>
      )}
      
      <div className={`${styles.header} ${styles.clickable}`} onClick={handleCardClick}>
        <div className={styles.headerContent}>
          <Avatar size="md" src="/images/european_flag.png" />
          <div>
            <EditableField
              isEditing={isEditMode}
              value={editedFunding.title}
              onSave={handleSave}
              field="title"
            />
          </div>
        </div>
      </div>

      <div className={`${styles.body} ${styles.clickable}`} onClick={handleCardClick}>
        <div 
          className={styles.badge} 
          onClick={(e) => {
            e.stopPropagation();
            if (!funding.evaluation) {
              window.location.href = '/onboard2';
            }
          }}
          style={{ cursor: !funding.evaluation ? 'pointer' : 'default' }}
        >
          <div className={styles.visibleBadge}>
            {eligibilityBadge.icon}
            <div className={styles.badgeContent}>
              {!funding.evaluation ? (
                <span className={styles.onboardingLink}>
                  Incepe onboarding-ul pentru a verifica potrivirea
                </span>
              ) : (
                <>
                  <span className={styles.badgeText} style={{ color: eligibilityBadge.color }}>
                    {eligibilityBadge.text}
                  </span>
                  <div className={styles.scoreContainer}>
                    <span className={styles.score}>Eligibilitate: {eligibilityBadge.eligibilityMark}/100</span>
                    <span className={styles.score}>Scop: {eligibilityBadge.scopeMark}/100</span>
                  </div>
                  {/* <a href={`/funding/${funding._id}`} className={styles.moreDetails}>
                    Mai multe detalii
                  </a> */}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.status}>
          <div className={styles.details}>
            <div className={styles.statusChip} style={{ backgroundColor: statusInfo.color }}>
              <statusInfo.icon color="white" />
              <span style={{ color: "white", marginLeft: "5px" }}>{statusInfo.text}</span>
            </div>
            <div className={styles.detailItem}>
                <span className={styles.detailItemTitle}>Valoare:</span>
              <div className={styles.valueFields}>
                {processValue(editedFunding.valoare_min, editedFunding.valoare_max)}
              </div>
            </div>
            <div className={styles.detailItem}>
                <span className={styles.detailItemTitle}>Start:</span>
                {formatFieldValue(editedFunding.data_start_depunere_exact)}
            </div>
            <div className={styles.detailItem}>
                <span className={styles.detailItemTitle}>Deadline:</span>
                {formatFieldValue(editedFunding.data_inchidere_depunere_exact)}
            </div>
            <div className={styles.detailItem}>
                <span className={styles.detailItemTitle}>Cofinanțare:</span>
              <div className={styles.valueFields}>
                {processPercentage(editedFunding.procent_minim, editedFunding.procent_maxim)}
              </div>
            </div>
            <div className={styles.detailItem}>
                <span className={styles.detailItemTitle}>Locatie:</span>
                {formatFieldValue(editedFunding.locatii_eligibile)}
            </div>
          </div>
        </div>

        <div className={styles.description}>
          <EditableField
            isEditing={isEditMode}
            value={editedFunding.descriere_medie}
            onSave={handleSave}
            field="descriere_medie"
          />
        </div>

        {/* <div className={styles.keywords}>
          <span>Keywords:</span>
          <div className={styles.keywordChips}>
            <EditableField
              isEditing={isEditMode}
              value={editedFunding.cuvinte_cheie}
              onSave={handleSave}
              field="cuvinte_cheie"
            />
          </div>
        </div> */}
      </div>

      <div className={styles.footer}>
        <div className={`${styles.footerActions} ${grid ? styles.footerGrid : ""}`}>
          <div className={styles.smallActions}>
          <Button
            className={`${styles.actionButton} ${styles.likeButton} ${isLiked ? styles.liked : ''}`}
            onClick={toggleLike}
          >
            {isLiked ? <FaBookmark /> : <FaRegBookmark />}
            {!grid && !isMobile && <span>{isLiked ? 'Saved' : 'Save'}</span>}
          </Button>
          
          <Menu>
            <MenuButton
              as={Button}
              className={`${styles.actionButton} ${styles.shareButton}`}
            >
              <Icon as={FaShare} />
              {!grid && !isMobile && <span style={{marginLeft: "8px"}}>Share</span>}
            </MenuButton>
            <MenuList>
              <MenuItem icon={<Icon as={FaLink} />} onClick={copyToClipboard}>
                Copy Link
              </MenuItem>
              <MenuItem icon={<FaFacebook />} onClick={() => shareOnSocialMedia("facebook")}>
                Share on Facebook
              </MenuItem>
              <MenuItem icon={<FaLinkedinIn />} onClick={() => shareOnSocialMedia("linkedin")}>
                Share on LinkedIn
              </MenuItem>
              <MenuItem icon={<FaTwitter />} onClick={() => shareOnSocialMedia("twitter")}>
                Share on Twitter
              </MenuItem>
              <MenuItem icon={<EmailIcon />} onClick={() => shareOnSocialMedia("email")}>
                Share via Email
              </MenuItem>
            </MenuList>
          </Menu>
          </div>
          
          {/* <Button
            className={`${styles.actionButton} ${styles.aiButton}`}
            onClick={() => setIsModalOpen(true)}
            leftIcon={<img alt="sparkles" src="/images/sparkles_16.png" />}
            colorScheme="purple"
            variant="outline"
            _hover={{ bg: 'purple.50' }}
          >
            {isMobile ? 'Expert AI' : 'Intreaba un expert AI'}
          </Button> */}
          
          <Button
            className={`${styles.actionButton} ${styles.consultantButton}`}
            onClick={togglePopup}
            leftIcon={<FaUserTie />}
            colorScheme="blue"
            variant="outline"
            _hover={{ bg: 'blue.50' }}
          >
            {grid ? 'Consultant' : 'Programează o consultație gratuită'}
          </Button>
        </div>
      </div>
      <PopupModal
        url={calendlyUrl}
        prefill={{
          email: user.email,
          customAnswers: {
            a1: `I would like to learn more about the ${funding.title} for company ${selectedCompany}`,
          },
        }}
        utm={{
          utmCampaign: user.id,
          utmSource: "company",
          utmMedium: "program",
        }}
        onModalClose={togglePopup}
        open={isPopupOpen}
        rootElement={document.getElementById("root")}
      />
      <FeedbackModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialData={{
          email: user.email,
          name: user.name,
          subject: `Question about: ${funding.title} for ${selectedCompany}`
        }}
        title="Ask a Question"
        submitButtonText="Send Question"
        axiosInstance={axiosInstance}
        onSuccess={() => setMessage("")}
      />
      
      <Modal isOpen={isOpen} onClose={onClose} size="l">
        <ModalOverlay />
        <ModalContent maxWidth="1100px" maxHeight="90vh">
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            {modalFunding && <FundingDetails funding={modalFunding} />}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FinanceCard;
