import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  Divider,
  useColorModeValue,
  Switch,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useToast,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

function CookiePolicy() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const headingColor = useColorModeValue("gray.800", "white");
  const tableBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const managementSectionRef = useRef(null);

  useEffect(() => {
    // Check if URL has #gestionare hash and scroll to it
    if (window.location.hash === '#gestionare' && managementSectionRef.current) {
      setTimeout(() => {
        managementSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, []);

  const toast = useToast();
  const [cookies, setCookies] = useState([]);
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true, // Always true and disabled
    performance: true,
    functionality: true,
    marketing: false,
  });

  // Load saved preferences on mount
  useEffect(() => {
    const savedPreferences = localStorage.getItem('cookiePreferences');
    if (savedPreferences) {
      try {
        const parsed = JSON.parse(savedPreferences);
        setCookiePreferences(prev => ({
          ...prev,
          ...parsed,
          essential: true // Always keep essential true
        }));
      } catch (error) {
        console.error('Error parsing saved cookie preferences:', error);
      }
    }
  }, []);

  // Function to get all cookies
  const getAllCookies = () => {
    const cookieList = document.cookie.split(';').map(cookie => {
      const [name, value] = cookie.split('=').map(c => c.trim());
      return { name, value };
    });
    setCookies(cookieList);
  };

  useEffect(() => {
    getAllCookies();
  }, []);

  // Function to delete a specific cookie
  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    getAllCookies();
    toast({
      title: "Cookie șters",
      description: `Cookie-ul ${cookieName} a fost șters cu succes.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  // Function to delete all non-essential cookies
  const deleteAllCookies = () => {
    const cookieList = document.cookie.split(';');
    cookieList.forEach(cookie => {
      const cookieName = cookie.split('=')[0].trim();
      // You might want to keep certain essential cookies
      if (cookieName !== 'consentCookie') {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      }
    });
    getAllCookies();
    
    // Update preferences to only essential
    const newPreferences = {
      essential: true,
      performance: false,
      functionality: false,
      marketing: false,
    };
    setCookiePreferences(newPreferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(newPreferences));
    
    toast({
      title: "Cookie-urile au fost șterse",
      description: "Toate cookie-urile neesențiale au fost șterse cu succes.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  // Function to save cookie preferences
  const saveCookiePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
    
    // If any type is disabled, delete its corresponding cookies
    if (!cookiePreferences.performance) {
      // Delete performance cookies
      // Add specific cookie names for your performance cookies
    }
    if (!cookiePreferences.functionality) {
      // Delete functionality cookies
      // Add specific cookie names for your functionality cookies
    }
    if (!cookiePreferences.marketing) {
      // Delete marketing cookies
      // Add specific cookie names for your marketing cookies
    }
    
    getAllCookies(); // Refresh the cookie list
    
    toast({
      title: "Preferințe salvate",
      description: "Preferințele pentru cookie-uri au fost salvate cu succes.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box bg={bgColor} minH="100vh">
      <Box 
        position="fixed"
        top="0"
        left="0"
        right="0"
        bg={bgColor}
        py={4}
        px={4}
        borderBottom="1px"
        borderColor={borderColor}
        zIndex={1000}
        boxShadow="sm"
      >
        <Container maxW="4xl">
          <HStack justify="space-between" align="center">
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              aria-label="Go back"
              variant="ghost"
              size="lg"
            />
            <Heading as="h1" size="lg" color={headingColor}>
              Politica privind Cookie-urile
            </Heading>
            <Box w="40px" /> {/* Spacer for alignment */}
          </HStack>
        </Container>
      </Box>

      <Container maxW="4xl">
        <Box pt="100px"> {/* Add padding to account for fixed header */}
          <VStack spacing={8} align="stretch">
            <Text color={textColor}>
              Această politică privind cookie-urile explică ce sunt cookie-urile și cum le folosim pe platforma ctrl.fund. 
              Vă rugăm să citiți această politică pentru a înțelege ce tipuri de cookie-uri folosim, ce informații colectăm și cum folosim aceste informații.
            </Text>

            <Box>
              <Heading as="h2" size="lg" color={headingColor} mb={4}>
                Ce sunt Cookie-urile?
              </Heading>
              <Text color={textColor}>
                Cookie-urile sunt fișiere text mici care sunt stocate pe dispozitivul dumneavoastră atunci când vizitați un site web. 
                Acestea sunt folosite pe scară largă pentru a face site-urile web să funcționeze mai eficient și pentru a oferi informații proprietarilor site-ului.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" color={headingColor} mb={4}>
                Cum folosim Cookie-urile
              </Heading>
              <Text color={textColor} mb={4}>
                Folosim cookie-uri pentru următoarele scopuri:
              </Text>
              <UnorderedList spacing={3} color={textColor} pl={4}>
                <ListItem>
                  <Text fontWeight="bold">Cookie-uri Esențiale</Text>
                  <Text>
                    Acestea sunt necesare pentru funcționarea site-ului și nu pot fi dezactivate. 
                    Includ cookie-uri pentru autentificare și gestionarea sesiunii.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontWeight="bold">Cookie-uri de Performanță</Text>
                  <Text>
                    Ne ajută să înțelegem cum interacționați cu site-ul nostru, permițându-ne să îmbunătățim funcționalitatea și experiența utilizatorului.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontWeight="bold">Cookie-uri de Funcționalitate</Text>
                  <Text>
                    Permit site-ului să rețină alegerile pe care le faceți (cum ar fi limba sau regiunea) și să ofere funcții îmbunătățite și mai personale.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontWeight="bold">Cookie-uri de Marketing</Text>
                  <Text>
                    Sunt utilizate pentru a vă urmări între site-uri web și a afișa reclame relevante pentru interesele dumneavoastră.
                  </Text>
                </ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box ref={managementSectionRef} id="gestionare">
              <Heading as="h2" size="lg" color={headingColor} mb={6}>
                Gestionarea Cookie-urilor
              </Heading>
              
              <Box mb={8} p={6} bg={tableBg} borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
                <Heading as="h3" size="md" mb={4}>
                  Preferințe Cookie-uri
                </Heading>
                <VStack align="stretch" spacing={4}>
                  <HStack justify="space-between">
                    <Box>
                      <Text fontWeight="bold">Cookie-uri Esențiale</Text>
                      <Text fontSize="sm" color={textColor}>Necesare pentru funcționarea site-ului</Text>
                    </Box>
                    <Switch isChecked={true} isDisabled colorScheme="blue" />
                  </HStack>

                  <HStack justify="space-between">
                    <Box>
                      <Text fontWeight="bold">Cookie-uri de Performanță</Text>
                      <Text fontSize="sm" color={textColor}>Pentru analiza și îmbunătățirea site-ului</Text>
                    </Box>
                    <Switch 
                      isChecked={cookiePreferences.performance}
                      onChange={(e) => setCookiePreferences({...cookiePreferences, performance: e.target.checked})}
                      colorScheme="blue"
                    />
                  </HStack>

                  <HStack justify="space-between">
                    <Box>
                      <Text fontWeight="bold">Cookie-uri de Funcționalitate</Text>
                      <Text fontSize="sm" color={textColor}>Pentru funcții personalizate</Text>
                    </Box>
                    <Switch 
                      isChecked={cookiePreferences.functionality}
                      onChange={(e) => setCookiePreferences({...cookiePreferences, functionality: e.target.checked})}
                      colorScheme="blue"
                    />
                  </HStack>

                  <HStack justify="space-between">
                    <Box>
                      <Text fontWeight="bold">Cookie-uri de Marketing</Text>
                      <Text fontSize="sm" color={textColor}>Pentru publicitate personalizată</Text>
                    </Box>
                    <Switch 
                      isChecked={cookiePreferences.marketing}
                      onChange={(e) => setCookiePreferences({...cookiePreferences, marketing: e.target.checked})}
                      colorScheme="blue"
                    />
                  </HStack>

                  <Button colorScheme="blue" onClick={saveCookiePreferences}>
                    Salvează Preferințele
                  </Button>
                </VStack>
              </Box>

              <Box p={6} bg={tableBg} borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
                <Heading as="h3" size="md" mb={4}>
                  Cookie-uri Active
                </Heading>
                
                <HStack spacing={4} mb={4}>
                  <Button colorScheme="red" variant="outline" onClick={deleteAllCookies}>
                    Șterge Toate Cookie-urile
                  </Button>
                  <Button colorScheme="blue" variant="outline" onClick={getAllCookies}>
                    Reîmprospătează Lista
                  </Button>
                </HStack>

                <Box overflowX="auto">
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Nume Cookie</Th>
                        <Th>Valoare</Th>
                        <Th>Acțiuni</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cookies.length > 0 ? (
                        cookies.map((cookie, index) => (
                          <Tr key={index}>
                            <Td>{cookie.name}</Td>
                            <Td>
                              <Text noOfLines={1} maxW="200px">
                                {cookie.value}
                              </Text>
                            </Td>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="red"
                                variant="ghost"
                                onClick={() => deleteCookie(cookie.name)}
                              >
                                Șterge
                              </Button>
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td colSpan={3} textAlign="center">
                            <Text color={textColor}>Nu există cookie-uri active</Text>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" color={headingColor} mb={4}>
                Modificări ale Politicii
              </Heading>
              <Text color={textColor}>
                Ne rezervăm dreptul de a modifica această politică privind cookie-urile în orice moment. Orice modificări vor fi postate pe această pagină 
                și, dacă modificările sunt semnificative, vom furniza o notificare mai vizibilă.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor} mb={4}>
                Contact
              </Heading>
              <Text color={textColor}>
                Dacă aveți întrebări despre politica noastră privind cookie-urile, vă rugăm să ne contactați la adresa de email: privacy@ctrl.fund
              </Text>
            </Box>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}

export default CookiePolicy; 