import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Button, useToast, HStack, VStack, Text } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserSuccess } from '../../../reducers/authReducer'
import AccountSettings from './AccountSettings'
import CompanySettings from './CompanySettings'
import Notifications from './Notifications'
import axiosInstance from '../../../services/axios'
import { useNavigate, useLocation } from 'react-router-dom'

const Content = ({ companyData, loading, onSave }) => {
  const tabs = ['Personale', 'Companie']  //, 'Generale'
  const user = useSelector((state) => state.auth.user)
  const navigate = useNavigate()
  const location = useLocation()
  const [formData, setFormData] = useState({})
  const [activeTab, setActiveTab] = useState(0)
  const toast = useToast()
  const dispatch = useDispatch()

  // Set initial active tab based on URL parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tabParam = params.get('tab')
    if (tabParam) {
      const tabIndex = tabs.indexOf(tabParam)
      if (tabIndex !== -1) {
        setActiveTab(tabIndex)
      }
    }
  }, [location.search])

  // Initialize form data when user or company data changes
  useEffect(() => {
    if (user) {
      setFormData(prevData => ({
        // Always include user data
        firstName: user.first_name || "",
        lastName: user.last_name || "",
        email: user.email || "",
        name: user.name || "",
        profilePicture: user.profile_picture || "",
        emailNotifications: user.preferences?.emailNotifications || false,
        fundingAlerts: user.preferences?.fundingAlerts || false,
        deadlineReminders: user.preferences?.deadlineReminders || false,
        // Include company data if available
        ...(companyData || {}),
        // Preserve any unsaved form changes for non-company fields if no company data
        ...(!companyData && prevData ? {
          firstName: prevData.firstName || user.first_name || "",
          lastName: prevData.lastName || user.last_name || "",
          email: prevData.email || user.email || "",
          name: prevData.name || user.name || "",
          profilePicture: prevData.profilePicture || user.profile_picture || "",
          emailNotifications: prevData.emailNotifications || user.preferences?.emailNotifications || false,
          fundingAlerts: prevData.fundingAlerts || user.preferences?.fundingAlerts || false,
          deadlineReminders: prevData.deadlineReminders || user.preferences?.deadlineReminders || false,
        } : {})
      }))
    }
  }, [user, companyData?._id]) // Reset when user or company ID changes


  const handleSaveCompany = async () => {
    // Validate required company fields
    if (!formData.company_name || !formData.company_form || !formData.registration_date || 
        !formData.registration_number || !formData.company_category || !formData.company_address) {
      toast({
        title: "Validation Error",
        description: "Please fill in all required company fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }

    try {
      const success = await onSave(formData)
      if (success) {
        toast({
          title: "Success",
          description: "Company information updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Could not save company information. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleSaveNotifications = async () => {
    try {
      const notificationData = {
        preferences: {
          emailNotifications: formData.emailNotifications,
          fundingAlerts: formData.fundingAlerts,
          deadlineReminders: formData.deadlineReminders
        }
      }
      
      const response = await axiosInstance.post('/users/update-preferences', notificationData)
      if (response.data) {
        toast({
          title: "Success",
          description: "Notification preferences updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        return true
      }
      return false
    } catch (error) {
      console.error("Error saving notification preferences:", error)
      toast({
        title: "Error",
        description: "Could not save notification preferences. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return false
    }
  }

  const handleTabChange = (index) => {
    setActiveTab(index)
  }

  return (
    <Box
      as="main"
      flex={3}
      d="flex"
      flexDir="column"
      justifyContent="space-between"
      pt={5}
      bg="white"
      rounded="md"
      borderWidth={1}
      borderColor="gray.200"
      style={{ transform: 'translateY(-100px)' }}
    >
      <Tabs index={activeTab} onChange={handleTabChange}>
        <TabList px={5}>
          {tabs.map(tab => (
            <Tab
              key={tab}
              mx={3}
              px={4}
              py={3}
              fontWeight="semibold"
              color="gray.500"
              borderBottomWidth={2}
              _active={{ bg: 'transparent' }}
              _selected={{ 
                color: 'purple.600', 
                borderColor: 'purple.500',
                bg: 'purple.50',
                fontWeight: 'bold',
                borderBottomWidth: '3px',
                transform: 'translateY(1px)'
              }}
              _hover={{
                color: 'purple.600',
                borderColor: 'purple.200'
              }}
              transition="all 0.2s"
            >
              {tab}
            </Tab>
          ))}
        </TabList>

        <TabPanels px={3} mt={5}>
          <TabPanel>
            <AccountSettings 
              data={formData} 
              updateData={setFormData}
              loading={loading}
            />
          </TabPanel>
          <TabPanel>
            {companyData ? (
              <CompanySettings 
                data={formData} 
                updateData={setFormData}
                loading={loading}
              />
            ) : (
              <VStack spacing={4} p={8} textAlign="center">
                <Text color="gray.600">
                  Nu ai nicio companie adăugată în cont.
                </Text>
                <Button
                  colorScheme="blue"
                  onClick={() => navigate('/onboard2')}
                >
                  Adaugă o companie nouă
                </Button>
              </VStack>
            )}
          </TabPanel>
          <TabPanel>
            <Notifications 
              data={formData} 
              updateData={setFormData}
              loading={loading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Box mt={5} py={5} px={8} borderTopWidth={1} borderColor="brand.light">
        <HStack spacing={4} justifyContent="flex-end">
          {activeTab === 1 && companyData && (
            <Button 
              colorScheme="blue"
              onClick={handleSaveCompany}
              isLoading={loading}
            >
              Salvează Informațiile Companiei
            </Button>
          )}
          {activeTab === 2 && (
            <Button 
              colorScheme="blue"
              onClick={handleSaveNotifications}
              isLoading={loading}
            >
              Salvează Setările de Notificare
            </Button>
          )}
        </HStack>
      </Box>
    </Box>
  )
}

export default Content
