export const validateFeedbackData = (name, email, message) => {
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // if (!name || name.trim() === "") {
  //   return "Numele este obligatoriu";
  // }
  if (!email || !validateEmail(email)) {
    return "Email invalid";
  }
  if (!message || message.trim() === "") {
    return "Mesajul este obligatoriu";
  }
  return false;
};

export const sendFeedback = async (axiosInstance, data) => {
  const { email, name, message, subject = "Feedback" } = data;
  
  const validation = validateFeedbackData(name, email, message);
  if (validation) {
    throw new Error(validation);
  }

  try {
    const response = await axiosInstance.post("/users/send-feedback", {
      data: {
        email,
        name,
        message,
        subject
      },
    });
    return response;
  } catch (error) {
    throw new Error("Am intampinat o eroare la trimiterea mesajului.");
  }
}; 