import { Container } from "@chakra-ui/layout";
import { useState, useEffect } from "react";
import Content from "./Content/Content";
import Sidebar from "./Sidebar/Sidebar";
import Cover from "./Cover";
import Header from "../Feed/Header";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../services/axios";
import { useToast } from "@chakra-ui/react";
import { setCompaniesSuccess, updateUser } from "../../reducers/authReducer";

export default function CompanyProfile() {
  const filters = useSelector((state) => state.auth.filters);
  const user = useSelector((state) => state.auth.user);
  const companies = useSelector((state) => state.auth.companies);
  const currentCompanyId = useSelector((state) => state.auth.current_company_id);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axiosInstance.get('/companies/getAll');
        if (response.data && response.data.length > 0) {
          dispatch(setCompaniesSuccess(response.data));
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching company data:", error);
        toast({
          title: "Error",
          description: "Could not fetch company data. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [dispatch]);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const response = await axiosInstance.get('/users/me')
  //     dispatch(updateUser({payload: response.data}));
  //   }
  //   fetchUser();
  // }, [dispatch]);

  // Get current company data and merge with user data
  const currentCompany = companies?.find(company => company._id === currentCompanyId) || companies?.[0];
  const companyData = currentCompany ? {
    ...currentCompany,
    // Merge user data for personal info tab
    firstName: user?.first_name || "",
    lastName: user?.last_name || "",
    email: user?.email || "",
    name: user?.name || "",
    profilePicture: user?.profile_picture || "",
    // Add notification preferences (these would need to be added to the backend)
    emailNotifications: user?.preferences?.emailNotifications || false,
    fundingAlerts: user?.preferences?.fundingAlerts || false,
    deadlineReminders: user?.preferences?.deadlineReminders || false,
  } : null;

  const handleSaveCompany = async (updatedData) => {
    try {
      // Extract only company-related data
      const companyData = {
        _id: currentCompanyId, // Include the company ID
        cif: updatedData.cif,
        company_name: updatedData.company_name,
        company_form: updatedData.company_form,
        registration_date: updatedData.registration_date,
        registration_number: updatedData.registration_number,
        company_category: updatedData.company_category,
        company_address: updatedData.company_address,
        company_regions: updatedData.company_regions,
        authorized_caen_codes: updatedData.authorized_caen_codes,
        company_description: updatedData.company_description,
        tva: updatedData.tva,
        financial_year1: updatedData.financial_year1 || {},
        financial_year2: updatedData.financial_year2 || {},
        financial_year3: updatedData.financial_year3 || {},
        financing_types: updatedData.financing_types,
        requests: updatedData.requests ? [
          {
            request_description: updatedData.purpose,
            request_aquisitions: updatedData.purchases,
            request_budget: updatedData.amount
          }
        ] : []
      };

      const response = await axiosInstance.post('/companies/update', companyData, {
        params: {
          companyId: currentCompanyId
        }
      });
      if (response.data) {
        // Update the specific company in the companies array
        const updatedCompanies = companies.map(company => 
          company._id === currentCompanyId ? response.data : company
        );
        dispatch(setCompaniesSuccess(updatedCompanies));
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error saving company data:", error);
      throw error;
    }
  };

  return (
    <div>
      <Header 
        user={user}
        filters={filters}
      />
      <Cover />
      <Container display={{ base: "block", md: "flex" }} maxW="container.xl">
        <Sidebar loading={loading} />
        <Content 
          companyData={companyData} 
          loading={loading} 
          onSave={handleSaveCompany}
        />
      </Container>
    </div>
  );
}
