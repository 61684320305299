import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Button,
  HStack,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Divider,
  Link,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaLinkedin, FaFacebook } from "react-icons/fa";
import { BiSend } from "react-icons/bi";
import { useState } from "react";
import axiosInstance from "../../services/axios";
import { sendFeedback } from "../../utils/feedback";

function Contact() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const headingColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const formBg = useColorModeValue("white", "gray.800");
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await sendFeedback(axiosInstance, {
        ...formData,
        subject: formData.subject || "Contact Form Message"
      });

      toast({
        title: "Mesaj trimis cu succes",
        description: "Vom reveni cu un răspuns în cel mai scurt timp posibil.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      toast({
        title: "Eroare",
        description: error.message || "Nu am putut trimite mesajul. Te rugăm să încerci din nou.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box bg={bgColor} minH="100vh">
      <Box 
        position="fixed"
        top="0"
        left="0"
        right="0"
        bg={bgColor}
        py={4}
        px={4}
        borderBottom="1px"
        borderColor={borderColor}
        zIndex={1000}
        boxShadow="sm"
      >
        <Container maxW="4xl">
          <HStack justify="space-between" align="center">
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              aria-label="Go back"
              variant="ghost"
              size="lg"
            />
            <Heading as="h1" size="lg" color={headingColor}>
              Contact
            </Heading>
            <Box w="40px" /> {/* Spacer for alignment */}
          </HStack>
        </Container>
      </Box>

      <Container maxW="4xl">
        <Box pt="100px"> {/* Add padding to account for fixed header */}
          <VStack spacing={8} align="stretch">
            <Text color={textColor}>
              Suntem aici pentru a te ajuta. Completează formularul de mai jos sau folosește una dintre metodele de contact alternative pentru a ne contacta.
            </Text>

            {/* Contact Form Section */}
            <Box 
              bg={formBg} 
              p={8} 
              borderRadius="lg" 
              borderWidth="1px" 
              borderColor={borderColor}
              shadow="sm"
            >
              <VStack as="form" spacing={4} onSubmit={handleSubmit}>
                <FormControl isRequired>
                  <FormLabel>Nume complet</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Ion Ionescu"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="exemplu@email.com"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Subiect</FormLabel>
                  <Input
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Motivul contactării"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Mesaj</FormLabel>
                  <Textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Scrie mesajul tău aici..."
                    rows={6}
                  />
                </FormControl>

                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Button
                    type="submit"
                    colorScheme="blue"
                    size="lg"
                    isLoading={isSubmitting}
                    leftIcon={<BiSend />}
                  >
                    Trimite Mesajul
                  </Button>
                </Box>
              </VStack>
            </Box>

            <Divider />

            {/* Alternative Contact Methods */}
            <Box>
              <Heading as="h2" size="lg" color={headingColor} mb={6}>
                Alte Modalități de Contact
              </Heading>
              
              <VStack spacing={6} align="stretch">
                <HStack spacing={4}>
                  <Icon as={FaEnvelope} boxSize={6} color="blue.500" />
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">Email</Text>
                    <Link href="mailto:contact@ctrl.fund" color="blue.500">
                      contact@ctrl.fund
                    </Link>
                  </VStack>
                </HStack>

                <HStack spacing={4}>
                  <Icon as={FaPhone} boxSize={6} color="blue.500" />
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">Telefon</Text>
                    <Link href="tel:+40123456789" color="blue.500">
                      +40 123 456 789
                    </Link>
                  </VStack>
                </HStack>

                {/* <HStack spacing={4}>
                  <Icon as={FaMapMarkerAlt} boxSize={6} color="blue.500" />
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">Adresă</Text>
                    <Text color={textColor}>
                      Strada Exemplu, Nr. 123<br />
                      București, România
                    </Text>
                  </VStack>
                </HStack> */}
              </VStack>
            </Box>

            <Divider />

            {/* Social Media Section */}
            <Box>
              <Heading as="h2" size="lg" color={headingColor} mb={6}>
                Social Media
              </Heading>
              
              <HStack spacing={6}>
                <Link href="https://www.linkedin.com/company/ctrl-fund/about/" isExternal>
                  <Icon as={FaLinkedin} boxSize={8} color="blue.500" />
                </Link>
                <Link href="https://www.facebook.com/people/CtrlFUND/61557965881172/" isExternal>
                  <Icon as={FaFacebook} boxSize={8} color="blue.500" />
                </Link>
              </HStack>
            </Box>

            <Box pb={8}>
              <Text color={textColor} fontSize="sm">
                Multumim ca ne-ai contactat!
              </Text>
            </Box>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}

export default Contact; 