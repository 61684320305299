import React, { useState } from "react";
import {
  Box,
  Button,
  CheckboxGroup,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  VStack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Switch,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { GroupBase, OptionBase, Select as MSelect } from "chakra-react-select";
import { Container } from "@chakra-ui/layout";
import { FixedSizeList as List } from "react-window";
import { components } from "react-select";

const counties = [
  "Alba",
  "Arad",
  "Arges",
  "Bacau",
  "Bihor",
  "Bistrita-Nasaud",
  "Botosani",
  "Braila",
  "Brasov",
  "Bucuresti",
  "Buzau",
  "Calarasi",
  "Caras-Severin",
  "Cluj",
  "Constanta",
  "Covasna",
  "Dambovita",
  "Dolj",
  "Galati",
  "Giurgiu",
  "Gorj",
  "Harghita",
  "Hunedoara",
  "Ialomita",
  "Iasi",
  "Ilfov",
  "Maramures",
  "Mehedinti",
  "Mures",
  "Neamt",
  "Olt",
  "Prahova",
  "Salaj",
  "Satu Mare",
  "Sibiu",
  "Suceava",
  "Teleorman",
  "Timis",
  "Tulcea",
  "Vaslui",
  "Valcea",
  "Vrancea",
];

const caenOptions = [
  { value: "01", label: "Agricultură, vânătoare și servicii anexe" },
  { value: "011", label: "Cultivarea plantelor nepermanente" },
  {
    value: "0111",
    label:
      "Cultivarea cerealelor (exclusiv orez), plantelor leguminoase și a plantelor producătoare de semințe oleaginoase",
  },
  { value: "0112", label: "Cultivarea orezului" },
  {
    value: "0113",
    label:
      "Cultivarea legumelor și a pepenilor, a rădăcinoaselor și tuberculilor",
  },
  { value: "0114", label: "Cultivarea trestiei de zahăr" },
  { value: "0115", label: "Cultivarea tutunului" },
  { value: "0116", label: "Cultivarea plantelor pentru fibre textile" },
  { value: "0119", label: "Cultivarea altor plante din culturi nepermanente" },
  { value: "012", label: "Cultivarea plantelor din culturi permanente" },
  { value: "0121", label: "Cultivarea strugurilor" },
  { value: "0122", label: "Cultivarea fructelor tropicale si subtropicale" },
  { value: "0123", label: "Cultivarea fructelor citrice" },
  { value: "0124", label: "Cultivarea fructelor semintoase si samburoase" },
  {
    value: "0125",
    label:
      "Cultivarea fructelor arbuștilor fructiferi, capsunilor, nuciferilor si a altor pomi fructiferi",
  },
  { value: "0126", label: "Cultivarea fructelor oleaginoase" },
  { value: "0127", label: "Cultivarea plantelor pentru prepararea băuturilor" },
  {
    value: "0128",
    label:
      "Cultivarea condimentelor, plantelor aromatice, medicinale si a plantelor de uz farmaceutic",
  },
  { value: "0129", label: "Cultivarea altor plante permanente" },
  { value: "013", label: "Cultivarea plantelor pentru inmultire" },
  { value: "0130", label: "Cultivarea plantelor pentru inmultire" },
  { value: "014", label: "Creșterea animalelor" },
  { value: "0141", label: "Creșterea bovinelor de lapte" },
  { value: "0142", label: "Creșterea altor bovine" },
  { value: "0143", label: "Creșterea cailor si a altor cabaline" },
  { value: "0144", label: "Creșterea camilelor si a camelidelor" },
  { value: "0145", label: "Creșterea ovinelor si caprinelor" },
  { value: "0146", label: "Creșterea porcinelor" },
  { value: "0147", label: "Creșterea pasarilor" },
  { value: "0149", label: "Creșterea altor animale" },
  {
    value: "015",
    label:
      "Activități in ferme mixte (cultura vegetala combinata cu creșterea animalelor)",
  },
  {
    value: "0150",
    label:
      "Activități in ferme mixte (cultura vegetala combinata cu creșterea animalelor)",
  },
  {
    value: "016",
    label: "Activități auxiliare agriculturii si activități după recoltare",
  },
  { value: "0161", label: "Activități auxiliare pentru producția vegetala" },
  { value: "0162", label: "Activități auxiliare pentru creșterea animalelor" },
  { value: "0163", label: "Activități după recoltare" },
  { value: "0164", label: "Pregatirea semințelor" },
  {
    value: "017",
    label:
      "Vanatoare, capturarea cu capcane a vanatului si activități de servicii anexe vanatorii",
  },
  {
    value: "0170",
    label:
      "Vanatoare,capturarea cu capcane a vanatului si activități de servicii anexe vanatorii",
  },
  { value: "02", label: "Silvicultură și exploatare forestieră" },
  { value: "021", label: "Silvicultura si alte activități forestiere" },
  { value: "0210", label: "Silvicultura si alte activități forestiere" },
  { value: "022", label: "Exploatarea forestiera" },
  { value: "0220", label: "Exploatarea forestiera" },
  {
    value: "023",
    label: "Colectarea produselor forestiere nelemnoase din flora spontana",
  },
  {
    value: "0230",
    label: "Colectarea produselor forestiere nelemnoase din flora spontana",
  },
  { value: "024", label: "Activități de servicii anexe silviculturii" },
  { value: "0240", label: "Activități de servicii anexe silviculturii" },
  { value: "03", label: "Pescuitul și acvacultura" },
  { value: "031", label: "Pescuitul" },
  { value: "0311", label: "Pescuitul maritim" },
  { value: "0312", label: "Pescuitul in ape dulci" },
  { value: "032", label: "Acvacultura" },
  { value: "0321", label: "Acvacultura maritima" },
  { value: "0322", label: "Acvacultura in ape dulci" },
  { value: "05", label: "Extracția cărbunelui superior și inferior" },
  { value: "051", label: "Extracția carbunelui superior (PCS=>23865 kJ/kg)" },
  { value: "0510", label: "Extracția carbunelui superior (PCS=>23865 kJ/kg)" },
  { value: "052", label: "Extracția carbunelui inferior (PCS<23865 kJ/kg)" },
  { value: "0520", label: "Extracția carbunelui inferior (PCS" },
  { value: "06", label: "Extracția petrolului brut și a gazelor naturale" },
  { value: "061", label: "Extracția petrolului brut" },
  { value: "0610", label: "Extracția petrolului brut" },
  { value: "062", label: "Extracția gazelor naturale" },
  { value: "0620", label: "Extracția gazelor naturale" },
  { value: "07", label: "Extracția minereurilor metalifere" },
  { value: "071", label: "Extracția minereurilor feroase" },
  { value: "0710", label: "Extracția minereurilor feroase" },
  { value: "072", label: "Extracția minereurilor metalifere neferoase" },
  { value: "0721", label: "Extracția minereurilor de uraniu si toriu" },
  { value: "0729", label: "Extracția altor minereuri metalifere neferoase" },
  { value: "08", label: "Alte activități extractive" },
  { value: "081", label: "Extracția pietrei, nisipului si argilei" },
  {
    value: "0811",
    label:
      "Extracția pietrei ornamentale si a pietrei pentru construcții, extracția pietrei calcaroase, ghipsului, cretei si a ardeziei",
  },
  {
    value: "0812",
    label:
      "Extracția pietrisului si nisipului; extracția argilei si caolinului",
  },
  { value: "089", label: "Alte activități extractive n.c.a." },
  {
    value: "0891",
    label:
      "Extracția mineralelor pentru industria chimica si a ingrasamintelor naturale",
  },
  { value: "0892", label: "Extracția turbei" },
  { value: "0893", label: "Extracția sarii" },
  { value: "0899", label: "Alte activități extractive n.c.a." },
  { value: "09", label: "Activități de servicii anexe extracției" },
  {
    value: "091",
    label:
      "Activități de servicii anexe extracției petrolului brut si gazelor naturale",
  },
  {
    value: "0910",
    label:
      "Activități de servicii anexe extracției petrolului brut si gazelor naturale",
  },
  {
    value: "099",
    label: "Activități de servicii anexe pentru extracția mineralelor",
  },
  {
    value: "0990",
    label: "Activități de servicii anexe pentru extracția mineralelor",
  },
  { value: "10", label: "Industria alimentară" },
  {
    value: "101",
    label:
      "Producția, prelucrarea si conservarea carnii si a produselor din carne",
  },
  { value: "1011", label: "Prelucrarea si conservarea carnii" },
  { value: "1012", label: "Prelucrarea si conservarea carnii de pasare" },
  {
    value: "1013",
    label: "Fabricarea produselor din carne (inclusiv din carne de pasare)",
  },
  {
    value: "102",
    label: "Prelucrarea si conservarea pestelui, crustaceelor si molustelor",
  },
  {
    value: "1020",
    label: "Prelucrarea si conservarea pestelui, crustaceelor si molustelor",
  },
  { value: "103", label: "Prelucrarea si conservarea fructelor si legumelor" },
  { value: "1031", label: "Prelucrarea si conservarea cartofilor" },
  { value: "1032", label: "Fabricarea sucurilor de fructe si legume" },
  {
    value: "1039",
    label: "Prelucrarea si conservarea fructelor si legumelor n.c.a.",
  },
  {
    value: "104",
    label: "Fabricarea uleiurilor si a grasimilor vegetale si animale",
  },
  { value: "1041", label: "Fabricarea uleiurilor si grasimilor" },
  {
    value: "1042",
    label: "Fabricarea margarinei si a altor produse comestibile similare",
  },
  { value: "105", label: "Fabricarea produselor lactate" },
  { value: "1051", label: "Fabricarea produselor lactate si a branzeturilor" },
  { value: "1052", label: "Fabricarea inghetatei" },
  {
    value: "106",
    label:
      "Fabricarea produselor de morarit, a amidonului si produselor din amidon",
  },
  { value: "1061", label: "Fabricarea produselor de morarit" },
  { value: "1062", label: "Fabricarea amidonului si a produselor din amidon" },
  {
    value: "107",
    label: "Fabricarea produselor de brutarie si a produselor fainoase",
  },
  {
    value: "1071",
    label:
      "Fabricarea painii; fabricarea prajiturilor si a produselor proaspete de patiserie",
  },
  {
    value: "1072",
    label:
      "Fabricarea biscuitilor si piscoturilor; fabricarea prajiturilor si a produselor conservate de patiserie",
  },
  {
    value: "1073",
    label:
      "Fabricarea macaroanelor, taiteilor, cus-cus-ului si a altor produse fainoase similare",
  },
  { value: "108", label: "Fabricarea altor produse alimentare" },
  { value: "1081", label: "Fabricarea zaharului" },
  {
    value: "1082",
    label:
      "Fabricarea produselor din cacao, a ciocolatei si a produselor zaharoase",
  },
  { value: "1083", label: "Prelucrarea ceaiului si cafelei" },
  { value: "1084", label: "Fabricarea condimentelor si ingredientelor" },
  { value: "1085", label: "Fabricarea de mancaruri preparate" },
  {
    value: "1086",
    label:
      "Fabricarea preparatelor alimentare omogenizate si alimentelor dietetice",
  },
  { value: "1089", label: "Fabricarea altor produse alimentare n.c.a." },
  { value: "109", label: "Fabricarea preparatelor pentru hrana animalelor" },
  {
    value: "1091",
    label: "Fabricarea preparatelor pentru hrana animalelor de ferma",
  },
  {
    value: "1092",
    label: "Fabricarea preparatelor pentru hrana animalelor de companie",
  },
  { value: "11", label: "Fabricarea băuturilor" },
  { value: "110", label: "Fabricarea băuturilor" },
  {
    value: "1101",
    label: "Distilarea, rafinarea si mixarea băuturilor alcoolice",
  },
  { value: "1102", label: "Fabricarea vinurilor din struguri" },
  { value: "1103", label: "Fabricarea cidrului si a altor vinuri din fructe" },
  {
    value: "1104",
    label: "Fabricarea altor bauturi nedistilate, obținute prin fermentare",
  },
  { value: "1105", label: "Fabricarea berii" },
  { value: "1106", label: "Fabricarea maltului" },
  {
    value: "1107",
    label:
      "Producția de bauturi racoritoare nealcoolice; producția de ape minerale si alte ape imbuteliate",
  },
  { value: "12", label: "Fabricarea produselor din tutun" },
  { value: "120", label: "Fabricarea produselor din tutun" },
  { value: "1200", label: "Fabricarea produselor din tutun" },
  { value: "13", label: "Fabricarea produselor textile" },
  { value: "131", label: "Pregatirea fibrelor si filarea fibrelor textile" },
  { value: "1310", label: "Pregatirea fibrelor si filarea fibrelor textile" },
  { value: "132", label: "Producția de tesaturi" },
  { value: "1320", label: "Producția de tesaturi" },
  { value: "133", label: "Finisarea materialelor textile" },
  { value: "1330", label: "Finisarea materialelor textile" },
  { value: "139", label: "Fabricarea altor articole textile" },
  {
    value: "1391",
    label: "Fabricarea de metraje prin tricotare sau crosetare",
  },
  {
    value: "1392",
    label:
      "Fabricarea de articole confectionate din textile (cu excepția imbracamintei si lenjeriei de corp)",
  },
  { value: "1393", label: "Fabricarea de covoare si mochete" },
  { value: "1394", label: "Fabricarea de odgoane, franghii, sfori si plase" },
  {
    value: "1395",
    label:
      "Fabricarea de textile netesute si articole din acestea, cu excepția confectiilor de imbracaminte",
  },
  {
    value: "1396",
    label: "Fabricarea de articole tehnice si industriale din textile",
  },
  { value: "1399", label: "Fabricarea altor articole textile n.c.a." },
  { value: "14", label: "Fabricarea articolelor de îmbrăcăminte" },
  {
    value: "141",
    label:
      "Fabricarea articolelor de imbracaminte, cu excepția articolelor din blana",
  },
  { value: "1411", label: "Fabricarea articolelor de imbracaminte din piele" },
  {
    value: "1412",
    label: "Fabricarea de articole de imbracaminte pentru lucru",
  },
  {
    value: "1413",
    label:
      "Fabricarea altor articole de imbracaminte (exclusiv lenjeria de corp)",
  },
  { value: "1414", label: "Fabricarea de articole de lenjerie de corp" },
  {
    value: "1419",
    label: "Fabricarea altor articole de imbracaminte si accesorii n.c.a.",
  },
  { value: "142", label: "Fabricarea articolelor din blana" },
  { value: "1420", label: "Fabricarea articolelor din blana" },
  {
    value: "143",
    label:
      "Fabricarea articolelor de imbracaminte prin tricotare sau crosetare",
  },
  {
    value: "1431",
    label:
      "Fabricarea prin tricotare sau crosetare a ciorapilor si articolelor de galanterie",
  },
  {
    value: "1439",
    label:
      "Fabricarea prin tricotare sau crosetare a altor articole de imbracaminte",
  },
  {
    value: "15",
    label:
      "Tăbăcirea și finisarea pieilor; fabricarea articolelor de voiaj și marochinărie, harnașamentelor și încălțămintei; prepararea și vopsirea blănurilor",
  },
  {
    value: "151",
    label:
      "Tăbăcirea si finisarea pieilor; fabricarea articolelor de voiaj si marochinarie si a articolelor de harnasament; prepararea si vopsirea blanurilor",
  },
  {
    value: "1511",
    label: "Tăbăcirea si finisarea pieilor; prepararea si vopsirea blanurilor",
  },
  {
    value: "1512",
    label:
      "Fabricarea articolelor de voiaj si marochinarie si a articolelor de harnasament",
  },
  { value: "152", label: "Fabricarea incaltamintei" },
  { value: "1520", label: "Fabricarea incaltamintei" },
  {
    value: "16",
    label:
      "Prelucrarea lemnului, fabricarea produselor din lemn și plută, cu excepția mobilei; fabricarea articolelor din paie și din alte materiale vegetale împletite",
  },
  { value: "161", label: "Tăierea si rindeluirea lemnului" },
  { value: "1610", label: "Tăierea si rindeluirea lemnului" },
  {
    value: "162",
    label:
      "Fabricarea produselor din lemn, pluta, paie si din alte materiale vegetale",
  },
  { value: "1621", label: "Fabricarea de furnire si a panourilor de lemn" },
  { value: "1622", label: "Fabricarea parchetului asamblat in panouri" },
  {
    value: "1623",
    label:
      "Fabricarea altor elemente de dulgherie si tamplarie, pentru construcții",
  },
  { value: "1624", label: "Fabricarea ambalajelor din lemn" },
  {
    value: "1629",
    label:
      "Fabricarea altor produse din lemn; fabricarea articolelor din pluta, paie si din alte materiale vegetale impletite",
  },
  { value: "17", label: "Fabricarea hârtiei și a produselor din hârtie" },
  { value: "171", label: "Fabricarea celulozei, hârtiei si cartonului" },
  { value: "1711", label: "Fabricarea celulozei" },
  { value: "1712", label: "Fabricarea hârtiei si cartonului" },
  { value: "172", label: "Fabricarea articolelor din hârtie și carton" },
  {
    value: "1721",
    label:
      "Fabricarea hârtiei si cartonului ondulat si a ambalajelor din hartie si carton",
  },
  {
    value: "1722",
    label:
      "Fabricarea produselor de uz gospodaresc si sanitar, din hartie sau carton",
  },
  { value: "1723", label: "Fabricarea articolelor de papetarie" },
  { value: "1724", label: "Fabricarea tapetului" },
  {
    value: "1729",
    label: "Fabricarea altor articole din hârtie și carton n.c.a",
  },
  {
    value: "18",
    label: "Tipărire și reproducerea pe suporți a înregistrărilor",
  },
  { value: "181", label: "Alte activități de tiparire n.c.a." },
  { value: "1811", label: "Tiparirea ziarelor" },
  { value: "1812", label: "Alte activități de tiparire n.c.a." },
  { value: "1813", label: "Servicii pregatitoare pentru pretiparire" },
  { value: "1814", label: "Legatorie si servicii conexe" },
  { value: "182", label: "Reproducerea inregistrarilor" },
  { value: "1820", label: "Reproducerea inregistrarilor" },
  {
    value: "19",
    label:
      "Fabricarea produselor de cocserie și a produselor obținute din prelucrarea țițeiului",
  },
  { value: "191", label: "Fabricarea produselor de cocserie" },
  { value: "1910", label: "Fabricarea produselor de cocserie" },
  {
    value: "192",
    label: "Fabricarea produselor obținute din prelucrarea titeiului",
  },
  {
    value: "1920",
    label: "Fabricarea produselor obținute din prelucrarea titeiului",
  },
  { value: "20", label: "Fabricarea substanțelor și a produselor chimice" },
  {
    value: "201",
    label:
      "Fabricarea produselor chimice de baza, a ingrasamintelor si produselor azotoase; fabricarea materialelor plastice si a cauciucului sintetic, in forme primare",
  },
  { value: "2011", label: "Fabricarea gazelor industriale" },
  { value: "2012", label: "Fabricarea colorantilor si a pigmentilor" },
  {
    value: "2013",
    label: "Fabricarea altor produse chimice anorganice, de baza",
  },
  {
    value: "2014",
    label: "Fabricarea altor produse chimice organice, de baza",
  },
  { value: "2015", label: "Fabricarea ingrasamintelor si produselor azotoase" },
  { value: "2016", label: "Fabricarea materialelor plastice in forme primare" },
  { value: "2017", label: "Fabricarea cauciucului sintetic in forme primare" },
  {
    value: "202",
    label: "Fabricarea pesticidelor si a altor produse agrochimice",
  },
  {
    value: "2020",
    label: "Fabricarea pesticidelor si a altor produse agrochimice",
  },
  {
    value: "203",
    label:
      "Fabricarea vopselelor, lacurilor, cernelii tipografice si masticurilor",
  },
  {
    value: "2030",
    label:
      "Fabricarea vopselelor, lacurilor, cernelii tipografice si masticurilor",
  },
  {
    value: "204",
    label:
      "Fabricarea sapunurilor, detergentilor si a produselor de intretinere, cosmetice si de parfumerie",
  },
  {
    value: "2041",
    label:
      "Fabricarea sapunurilor, detergentilor si a produselor de intretinere",
  },
  {
    value: "2042",
    label: "Fabricarea parfumurilor si a produselor cosmetice (de toaleta)",
  },
  { value: "205", label: "Fabricarea altor produse chimice" },
  { value: "2051", label: "Fabricarea explozivilor" },
  { value: "2052", label: "Fabricarea cleiurilor" },
  { value: "2053", label: "Fabricarea uleiurilor esentiale" },
  { value: "2059", label: "Fabricarea altor produse chimice n.c.a." },
  { value: "206", label: "Fabricarea fibrelor sintetice si artificiale" },
  { value: "2060", label: "Fabricarea fibrelor sintetice si artificiale" },
  {
    value: "21",
    label:
      "Fabricarea produselor farmaceutice de bază și a preparatelor farmaceutice",
  },
  { value: "211", label: "Fabricarea produselor farmaceutice de baza" },
  { value: "2110", label: "Fabricarea produselor farmaceutice de baza" },
  { value: "212", label: "Fabricarea preparatelor farmaceutice" },
  { value: "2120", label: "Fabricarea preparatelor farmaceutice" },
  { value: "22", label: "Fabricarea produselor din cauciuc și mase plastice" },
  { value: "221", label: "Fabricarea articolelor din cauciuc" },
  {
    value: "2211",
    label:
      "Fabricarea anvelopelor si a camerelor de aer; reșaparea si refacerea anvelopelor",
  },
  { value: "2219", label: "Fabricarea altor produse din cauciuc" },
  {
    value: "222",
    label: "Fabricarea articolelor de ambalaj din material plastic",
  },
  {
    value: "2221",
    label:
      "Fabricarea placilor, foliilor, tuburilor si profilelor din material plastic",
  },
  {
    value: "2222",
    label: "Fabricarea articolelor de ambalaj din material plastic",
  },
  {
    value: "2223",
    label: "Fabricarea articolelor din material plastic pentru construcții",
  },
  { value: "2229", label: "Fabricarea altor produse din material plastic" },
  { value: "23", label: "Fabricarea altor produse din minerale nemetalice" },
  { value: "231", label: "Fabricarea sticlei si a articolelor din sticla" },
  { value: "2311", label: "Fabricarea sticlei plate" },
  { value: "2312", label: "Prelucrarea si fasonarea sticlei plate" },
  { value: "2313", label: "Fabricarea articolelor din sticla" },
  { value: "2314", label: "Fabricarea fibrelor din sticla" },
  { value: "2319", label: "Fabricarea de sticlarie tehnica" },
  { value: "232", label: "Fabricarea de produse refractare" },
  { value: "2320", label: "Fabricarea de produse refractare" },
  { value: "233", label: "Fabricarea materialelor de construcții din argila" },
  { value: "2331", label: "Fabricarea placilor si dalelor din ceramica" },
  {
    value: "2332",
    label:
      "Fabricarea cărămizilor, tiglelor si a altor produse pentru construcții, din argila arsa",
  },
  { value: "234", label: "Fabricarea altor articole din ceramica si portelan" },
  {
    value: "2341",
    label:
      "Fabricarea articolelor ceramice pentru uz gospodaresc si ornamental",
  },
  { value: "2342", label: "Fabricarea de obiecte sanitare din ceramica" },
  {
    value: "2343",
    label: "Fabricarea izolatorilor si pieselor izolante din ceramica",
  },
  { value: "2344", label: "Fabricarea altor produse tehnice din ceramica" },
  { value: "2349", label: "Fabricarea altor produse ceramice n.c.a." },
  { value: "235", label: "Fabricarea cimentului, varului si ipsosului" },
  { value: "2351", label: "Fabricarea cimentului" },
  { value: "2352", label: "Fabricarea varului si ipsosului" },
  { value: "236", label: "Fabricarea articolelor din beton, ciment si ipsos" },
  {
    value: "2361",
    label: "Fabricarea produselor din beton pentru construcții",
  },
  {
    value: "2362",
    label: "Fabricarea produselor din ipsos pentru construcții",
  },
  { value: "2363", label: "Fabricarea betonului" },
  { value: "2364", label: "Fabricarea mortarului" },
  { value: "2365", label: "Fabricarea produselor din azbociment" },
  {
    value: "2369",
    label: "Fabricarea altor articole din beton, ciment si ipsos",
  },
  { value: "237", label: "Tăierea, fasonarea si finisarea pietrei" },
  { value: "2370", label: "Tăierea, fasonarea si finisarea pietrei" },
  {
    value: "239",
    label:
      "Fabricarea produselor abrazive si a altor produse din minerale nemetalice n.c.a.",
  },
  { value: "2391", label: "Fabricarea de produse abrazive" },
  {
    value: "2399",
    label: "Fabricarea altor produse din minerale nemetalice, n.c.a.",
  },
  { value: "24", label: "Industria metalurgică" },
  {
    value: "241",
    label: "Producția de metale feroase sub forme primare si de feroaliaje",
  },
  {
    value: "2410",
    label: "Producția de metale feroase sub forme primare si de feroaliaje",
  },
  {
    value: "242",
    label:
      "Producția de tuburi, tevi, profile tubulare si accesorii pentru acestea, din otel",
  },
  {
    value: "2420",
    label:
      "Producția de tuburi, tevi, profile tubulare si accesorii pentru acestea, din otel",
  },
  { value: "243", label: "Producția de profile obținute la rece" },
  { value: "2431", label: "Tragere la rece a barelor" },
  { value: "2432", label: "Laminare la rece a benzilor inguste" },
  { value: "2433", label: "Producția de profile obținute la rece" },
  { value: "2434", label: "Trefilarea firelor la rece" },
  {
    value: "244",
    label: "Producția metalelor pretioase si a altor metale neferoase",
  },
  { value: "2441", label: "Producția metalelor pretioase" },
  { value: "2442", label: "Metalurgia aluminiului" },
  { value: "2443", label: "Producția plumbului, zincului si cositorului" },
  { value: "2444", label: "Metalurgia cuprului" },
  { value: "2445", label: "Producția altor metale neferoase" },
  { value: "2446", label: "Prelucrarea combustibililor nucleari" },
  { value: "245", label: "Turnarea metalelor" },
  { value: "2451", label: "Turnarea fontei" },
  { value: "2452", label: "Turnarea otelului" },
  { value: "2453", label: "Turnarea metalelor neferoase usoare" },
  { value: "2454", label: "Turnarea altor metale neferoase" },
  {
    value: "25",
    label:
      "Industria construcțiilor metalice și a produselor din metal, exclusiv mașini, utilaje și instalații",
  },
  { value: "251", label: "Fabricarea de construcții metalice" },
  {
    value: "2511",
    label:
      "Fabricarea de construcții metalice si parti componente ale structurilor metalice",
  },
  { value: "2512", label: "Fabricarea de usi si ferestre din metal" },
  {
    value: "252",
    label:
      "Producția de rezervoare, cisterne si containere metalice; producția de radiatoare si cazane pentru incalzire centrala",
  },
  {
    value: "2521",
    label: "Producția de radiatoare si cazane pentru incalzire centrala",
  },
  {
    value: "2529",
    label: "Producția de rezervoare, cisterne si containere metalice",
  },
  {
    value: "253",
    label:
      "Producția generatoarelor de aburi (cu excepția cazanelor pentru incalzire centrala)",
  },
  {
    value: "2530",
    label:
      "Producția generatoarelor de aburi (cu excepția cazanelor pentru incalzire centrala)",
  },
  { value: "254", label: "Fabricarea armamentului si muniției" },
  { value: "2540", label: "Fabricarea armamentului si muniției" },
  {
    value: "255",
    label:
      "Fabricarea produselor metalice obținute prin deformare plastica; metalurgia pulberilor",
  },
  {
    value: "2550",
    label:
      "Fabricarea produselor metalice obținute prin deformare plastica; metalurgia pulberilor",
  },
  {
    value: "256",
    label:
      "Tratarea si acoperirea metalelor; operatiuni de mecanica generala pe baza de plata sau contract",
  },
  { value: "2561", label: "Tratarea si acoperirea metalelor" },
  { value: "2562", label: "Operatiuni de mecanica generala" },
  { value: "257", label: "Producția de unelte si articole de fierarie" },
  { value: "2571", label: "Fabricarea produselor de taiat" },
  { value: "2572", label: "Fabricarea articolelor de feronerie" },
  { value: "2573", label: "Fabricarea uneltelor" },
  { value: "259", label: "Fabricarea altor produse prelucrate din metal" },
  {
    value: "2591",
    label:
      "Fabricarea de recipienti, containere si alte produse similare din otel",
  },
  { value: "2592", label: "Fabricarea ambalajelor usoare din metal" },
  {
    value: "2593",
    label:
      "Fabricarea articolelor din fire metalice; fabricarea de lanturi si arcuri",
  },
  {
    value: "2594",
    label:
      "Fabricarea de suruburi, buloane si alte articole filetate; fabricarea de nituri si saibe",
  },
  { value: "2599", label: "Fabricarea altor articole din metal n.c.a." },
  {
    value: "26",
    label: "Fabricarea calculatoarelor și a produselor electronice și optice",
  },
  { value: "261", label: "Fabricarea componentelor electronice" },
  { value: "2611", label: "Fabricarea subansamblurilor electronice (module)" },
  { value: "2612", label: "Fabricarea altor componente electronice" },
  {
    value: "262",
    label: "Fabricarea calculatoarelor si a echipamentelor periferice",
  },
  {
    value: "2620",
    label: "Fabricarea calculatoarelor si a echipamentelor periferice",
  },
  { value: "263", label: "Fabricarea echipamentelor de comunicații" },
  { value: "2630", label: "Fabricarea echipamentelor de comunicații" },
  { value: "264", label: "Fabricarea produselor electronice de larg consum" },
  { value: "2640", label: "Fabricarea produselor electronice de larg consum" },
  {
    value: "265",
    label:
      "Fabricarea de echipamente de masura, verificare, control si navigație; producția de ceasuri",
  },
  {
    value: "2651",
    label:
      "Fabricarea de instrumente si dispozitive pentru masura, verificare, control, navigație",
  },
  { value: "2652", label: "Producția de ceasuri" },
  {
    value: "266",
    label:
      "Fabricarea de echipamente pentru radiologie, electrodiagnostic si electroterapie",
  },
  {
    value: "2660",
    label:
      "Fabricarea de echipamente pentru radiologie, electrodiagnostic si electroterapie",
  },
  {
    value: "267",
    label: "Fabricarea de instrumente optice si echipamente fotografice",
  },
  {
    value: "2670",
    label: "Fabricarea de instrumente optice si echipamente fotografice",
  },
  {
    value: "268",
    label:
      "Fabricarea suporților magnetici si optici destinați inregistrarilor",
  },
  {
    value: "2680",
    label:
      "Fabricarea suporților magnetici si optici destinați inregistrarilor",
  },
  { value: "27", label: "Fabricarea echipamentelor electrice" },
  {
    value: "271",
    label:
      "Fabricarea motoarelor electrice, generatoarelor si transformatoarelor electrice si a aparatelor de distributie si control a electricitatii",
  },
  {
    value: "2711",
    label:
      "Fabricarea motoarelor, generatoarelor si transformatoarelor electrice",
  },
  {
    value: "2712",
    label: "Fabricarea aparatelor de distributie si control a electricitatii",
  },
  { value: "272", label: "Fabricarea de acumulatori si baterii" },
  { value: "2720", label: "Fabricarea de acumulatori si baterii" },
  {
    value: "273",
    label:
      "Fabricarea de fire si cabluri; fabricarea dispozitivelor de conexiune pentru acestea",
  },
  { value: "2731", label: "Fabricarea de cabluri cu fibra optica" },
  {
    value: "2732",
    label: "Fabricarea altor fire si cabluri electrice si electronice",
  },
  {
    value: "2733",
    label:
      "Fabricarea dispozitivelor de conexiune pentru fire si cabluri electrice si electronice",
  },
  { value: "274", label: "Fabricarea de echipamente electrice de iluminat" },
  { value: "2740", label: "Fabricarea de echipamente electrice de iluminat" },
  { value: "275", label: "Fabricarea de echipamente casnice" },
  { value: "2751", label: "Fabricarea de aparate electrocasnice" },
  { value: "2752", label: "Fabricarea de echipamente casnice neelectrice" },
  { value: "279", label: "Fabricarea altor echipamente electrice" },
  { value: "2790", label: "Fabricarea altor echipamente electrice" },
  { value: "28", label: "Fabricarea de mașini, utilaje și echipamente n.c.a." },
  {
    value: "281",
    label: "Fabricarea de masini si utilaje de utilizare generala",
  },
  {
    value: "2811",
    label:
      "Fabricarea de motoare si turbine (cu excepția celor pentru avioane, autovehicule si motociclete)",
  },
  { value: "2812", label: "Fabricarea de motoare hidraulice" },
  { value: "2813", label: "Fabricarea de pompe si compresoare" },
  { value: "2814", label: "Fabricarea de articole de robinetarie" },
  {
    value: "2815",
    label:
      "Fabricarea lagarelor, angrenajelor, cutiilor de viteza si a elementelor mecanice de transmisie",
  },
  {
    value: "282",
    label: "Fabricarea altor masini si utilaje de utilizare generala",
  },
  {
    value: "2821",
    label: "Fabricarea cuptoarelor, furnalelor si arzatoarelor",
  },
  { value: "2822", label: "Fabricarea echipamentelor de ridicat si manipulat" },
  {
    value: "2823",
    label:
      "Fabricarea mașinilor si echipamentelor de birou (exclusiv fabricarea calculatoarelor si a echipamentelor periferice)",
  },
  {
    value: "2824",
    label: "Fabricarea mașinilor-unelte portabile actionate electric",
  },
  {
    value: "2825",
    label:
      "Fabricarea echipamentelor de ventilatie si frigorifice, exclusiv a echipamentelor de uz casnic",
  },
  {
    value: "2829",
    label: "Fabricarea altor masini si utilaje de utilizare generala n.c.a.",
  },
  {
    value: "283",
    label:
      "Fabricarea mașinilor si utilajelor pentru agricultura si exploatari forestiere",
  },
  {
    value: "2830",
    label:
      "Fabricarea mașinilor si utilajelor pentru agricultura si exploatari forestiere",
  },
  {
    value: "284",
    label:
      "Fabricarea utilajelor pentru prelucrarea metalului si a mașinilor-unelte",
  },
  {
    value: "2841",
    label:
      "Fabricarea utilajelor si a mașinilor-unelte pentru prelucrarea metalului",
  },
  { value: "2849", label: "Fabricarea altor masini-unelte n.c.a." },
  {
    value: "289",
    label:
      "Fabricarea utilajelor pentru industria textila, a imbracamintei si a pielariei",
  },
  { value: "2891", label: "Fabricarea utilajelor pentru metalurgie" },
  {
    value: "2892",
    label: "Fabricarea utilajelor pentru extracție si construcții",
  },
  {
    value: "2893",
    label:
      "Fabricarea utilajelor pentru prelucrarea produselor alimentare, băuturilor si tutunului",
  },
  {
    value: "2894",
    label:
      "Fabricarea utilajelor pentru industria textila, a imbracamintei si a pielariei",
  },
  {
    value: "2895",
    label: "Fabricarea utilajelor pentru industria hârtiei si cartonului",
  },
  {
    value: "2896",
    label:
      "Fabricarea utilajelor pentru prelucrarea maselor plastice si a cauciucului",
  },
  {
    value: "2899",
    label: "Fabricarea altor masini si utilaje specifice n.c.a.",
  },
  {
    value: "29",
    label:
      "Fabricarea autovehiculelor de transport rutier, a remorcilor și semiremorcilor",
  },
  { value: "291", label: "Fabricarea autovehiculelor de transport rutier" },
  { value: "2910", label: "Fabricarea autovehiculelor de transport rutier" },
  {
    value: "292",
    label:
      "Producția de caroserii pentru autovehicule; fabricarea de remorci si semiremorci",
  },
  {
    value: "2920",
    label:
      "Producția de caroserii pentru autovehicule; fabricarea de remorci si semiremorci",
  },
  {
    value: "293",
    label:
      "Producția de piese si accesorii pentru autovehicule si pentru motoare de autovehicule",
  },
  {
    value: "2931",
    label:
      "Fabricarea de echipamente electrice si electronice pentru autovehicule si pentru motoare de autovehicule",
  },
  {
    value: "2932",
    label:
      "Fabricarea altor piese si accesorii pentru autovehicule si pentru motoare de autovehicule",
  },
  { value: "30", label: "Fabricarea altor mijloace de transport" },
  { value: "301", label: "Construcția de nave si bărci" },
  { value: "3011", label: "Construcția de nave si structuri plutitoare" },
  {
    value: "3012",
    label: "Construcția de ambarcatiuni sportive si de agrement",
  },
  { value: "302", label: "Fabricarea materialului rulant" },
  { value: "3020", label: "Fabricarea materialului rulant" },
  { value: "303", label: "Fabricarea de aeronave si nave spatiale" },
  { value: "3030", label: "Fabricarea de aeronave si nave spatiale" },
  { value: "304", label: "Fabricarea vehiculelor militare de lupta" },
  { value: "3040", label: "Fabricarea vehiculelor militare de lupta" },
  { value: "309", label: "Fabricarea altor echipamente de transport n.c.a." },
  { value: "3091", label: "Fabricarea de motociclete" },
  {
    value: "3092",
    label: "Fabricarea de biciclete si de vehicule pentru invalizi",
  },
  { value: "3099", label: "Fabricarea altor mijloace de transport n.c.a." },
  { value: "31", label: "Fabricarea de mobilă" },
  { value: "310", label: "Fabricarea de mobila" },
  { value: "3101", label: "Fabricarea de mobila pentru birouri si magazine" },
  { value: "3102", label: "Fabricarea de mobila pentru bucatarii" },
  { value: "3103", label: "Fabricarea de saltele si somiere" },
  { value: "3109", label: "Fabricarea de mobila n.c.a." },
  { value: "32", label: "Alte activități industriale n.c.a" },
  {
    value: "321",
    label:
      "Fabricarea bijuteriilor, imitatiilor de bijuterii si articolelor similare",
  },
  { value: "3211", label: "Baterea monedelor" },
  {
    value: "3212",
    label:
      "Fabricarea bijuteriilor si articolelor similare din metale si pietre pretioase",
  },
  {
    value: "3213",
    label: "Fabricarea imitatiilor de bijuterii si articole similare",
  },
  { value: "322", label: "Fabricarea instrumentelor muzicale" },
  { value: "3220", label: "Fabricarea instrumentelor muzicale" },
  { value: "323", label: "Fabricarea articolelor pentru sport" },
  { value: "3230", label: "Fabricarea articolelor pentru sport" },
  { value: "324", label: "Fabricarea jocurilor si jucariilor" },
  { value: "3240", label: "Fabricarea jocurilor si jucariilor" },
  {
    value: "325",
    label:
      "Fabricarea de dispozitive, aparate si instrumente medicale si stomatologice",
  },
  {
    value: "3250",
    label:
      "Fabricarea de dispozitive, aparate si instrumente medicale si stomatologice",
  },
  { value: "329", label: "Alte activități industriale" },
  { value: "3291", label: "Fabricarea maturilor si periilor" },
  { value: "3299", label: "Fabricarea altor produse manufacturiere n.c.a." },
  {
    value: "33",
    label: "Repararea, întreținerea și instalarea mașinilor și echipamentelor",
  },
  {
    value: "331",
    label:
      "Repararea articolelor fabricate din metal, repararea mașinilor si echipamentelor",
  },
  { value: "3311", label: "Repararea articolelor fabricate din metal" },
  { value: "3312", label: "Repararea mașinilor" },
  { value: "3313", label: "Repararea echipamentelor electronice si optice" },
  { value: "3314", label: "Repararea echipamentelor electrice" },
  { value: "3315", label: "Repararea si intretinerea navelor si bărcilor" },
  {
    value: "3316",
    label: "Repararea si intretinerea aeronavelor si navelor spatiale",
  },
  {
    value: "3317",
    label: "Repararea si intretinerea altor echipamente de transport n.c.a.",
  },
  { value: "3319", label: "Repararea altor echipamente" },
  { value: "332", label: "Instalarea mașinilor si echipamentelor industriale" },
  {
    value: "3320",
    label: "Instalarea mașinilor si echipamentelor industriale",
  },
  {
    value: "35",
    label:
      "Producția și furnizarea de energie electrică și termică, gaze, apă caldă și aer condiționat",
  },
  {
    value: "351",
    label: "Producția, transportul si distributia energiei electrice",
  },
  { value: "3511", label: "Producția de energie electrica" },
  { value: "3512", label: "Transportul energiei electrice" },
  { value: "3513", label: "Distributia energiei electrice" },
  { value: "3514", label: "Comercializarea energiei electrice" },
  {
    value: "352",
    label:
      "Producția gazelor; distributia combustibililor gazosi prin conducte",
  },
  { value: "3521", label: "Producția gazelor" },
  { value: "3522", label: "Distributia combustibililor gazosi, prin conducte" },
  {
    value: "3523",
    label: "Comercializarea combustibililor gazosi, prin conducte",
  },
  { value: "353", label: "Furnizarea de abur si aer conditionat" },
  { value: "3530", label: "Furnizarea de abur si aer conditionat" },
  { value: "36", label: "Captarea, tratarea și distribuția apei" },
  { value: "360", label: "Captarea, tratarea si distributia apei" },
  { value: "3600", label: "Captarea, tratarea si distributia apei" },
  { value: "37", label: "Colectarea și epurarea apelor uzate" },
  { value: "370", label: "Colectarea si epurarea apelor uzate" },
  { value: "3700", label: "Colectarea si epurarea apelor uzate" },
  {
    value: "38",
    label:
      "Colectarea, tratarea și eliminarea deșeurilor; activități de recuperare a materialelor reciclabile",
  },
  { value: "381", label: "Colectarea deseurilor" },
  { value: "3811", label: "Colectarea deseurilor nepericuloase" },
  { value: "3812", label: "Colectarea deseurilor periculoase" },
  { value: "382", label: "Tratarea si eliminarea deseurilor" },
  { value: "3821", label: "Tratarea si eliminarea deseurilor nepericuloase" },
  { value: "3822", label: "Tratarea si eliminarea deseurilor periculoase" },
  { value: "383", label: "Recuperare materialelor" },
  {
    value: "3831",
    label:
      "Demontarea (dezasamblarea) mașinilor si echipamentelor scoase din uz pentru recuperarea materialelor",
  },
  { value: "3832", label: "Recuperarea materialelor reciclabile sortate" },
  { value: "39", label: "Activități și servicii de decontaminare" },
  { value: "390", label: "Activități si servicii de decontaminare" },
  { value: "3900", label: "Activități si servicii de decontaminare" },
  { value: "41", label: "Construcții de clădiri" },
  { value: "411", label: "Dezvoltare (promovare) imobiliara" },
  { value: "4110", label: "Dezvoltare (promovare) imobiliara" },
  {
    value: "412",
    label: "Lucrari de construcții a cladirilor rezidentiale si nerezidentiale",
  },
  {
    value: "4120",
    label: "Lucrari de construcții a cladirilor rezidentiale si nerezidentiale",
  },
  { value: "42", label: "Lucrări de geniu civil" },
  {
    value: "421",
    label: "Lucrari de construcții a drumurilor si a cailor ferate",
  },
  {
    value: "4211",
    label: "Lucrari de construcții a drumurilor si autostrazilor",
  },
  {
    value: "4212",
    label: "Lucrari de construcții a cailor ferate de suprafata si subterane",
  },
  { value: "4213", label: "Construcția de poduri si tuneluri" },
  { value: "422", label: "Lucrari de construcții a proiectelor utilitare" },
  {
    value: "4221",
    label: "Lucrari de construcții a proiectelor utilitare pentru fluide",
  },
  {
    value: "4222",
    label:
      "Lucrari de construcții a proiectelor utilitare pentru electricitate si telecomunicații",
  },
  {
    value: "429",
    label: "Lucrari de construcții a altor proiecte ingineresti",
  },
  { value: "4291", label: "Construcții hidrotehnice" },
  {
    value: "4299",
    label: "Lucrari de construcții a altor proiecte ingineresti n.c.a",
  },
  { value: "43", label: "Lucrări speciale de construcții" },
  { value: "431", label: "Lucrari de demolare si de pregatire a terenului" },
  { value: "4311", label: "Lucrari de demolare a construcțiilor" },
  { value: "4312", label: "Lucrari de pregatire a terenului" },
  { value: "4313", label: "Lucrari de foraj si sondaj pentru construcții" },
  {
    value: "432",
    label:
      "Lucrari de instalatii electrice si tehnico-sanitare si alte lucrari de instalatii pentru construcții",
  },
  { value: "4321", label: "Lucrari de instalatii electrice" },
  {
    value: "4322",
    label: "Lucrari de instalatii sanitare, de incalzire si de aer conditionat",
  },
  { value: "4329", label: "Alte lucrari de instalatii pentru construcții" },
  { value: "433", label: "Lucrari de finisare" },
  { value: "4331", label: "Lucrari de ipsoserie" },
  { value: "4332", label: "Lucrari de tamplarie si dulgherie" },
  { value: "4333", label: "Lucrari de pardosire si placare a peretilor" },
  {
    value: "4334",
    label: "Lucrari de vopsitorie, zugraveli si montari de geamuri",
  },
  { value: "4339", label: "Alte lucrari de finisare" },
  { value: "439", label: "Alte lucrari speciale de construcții" },
  {
    value: "4391",
    label: "Lucrari de invelitori, sarpante si terase la construcții",
  },
  { value: "4399", label: "Alte lucrari speciale de construcții n.c.a." },
  {
    value: "45",
    label:
      "Comerț cu ridicata și cu amănuntul, întreținerea și repararea autovehiculelor și a motocicletelor",
  },
  { value: "451", label: "Comerț cu autovehicule" },
  {
    value: "4511",
    label: "Comerț cu autoturisme si autovehicule usoare (sub 3,5 tone)",
  },
  { value: "4519", label: "Comerț cu alte autovehicule" },
  { value: "452", label: "Intretinerea si repararea autovehiculelor" },
  { value: "4520", label: "Intretinerea si repararea autovehiculelor" },
  { value: "453", label: "Comerț cu piese si accesorii pentru autovehicule" },
  {
    value: "4531",
    label: "Comerț cu ridicata de piese si accesorii pentru autovehicule",
  },
  {
    value: "4532",
    label: "Comerț cu amănuntul de piese si accesorii pentru autovehicule",
  },
  {
    value: "454",
    label:
      "Comerț cu motociclete, piese si accesorii aferente; intretinerea si repararea motocicletelor",
  },
  {
    value: "4540",
    label:
      "Comerț cu motociclete, piese si accesorii aferente; intretinerea si repararea motocicletelor",
  },
  {
    value: "46",
    label:
      "Comerț cu ridicata cu excepția comerțului cu autovehicule și motociclete",
  },
  { value: "461", label: "Activitați de intermediere în comerțul cu ridicata" },
  {
    value: "4611",
    label:
      "Intermedieri in comerțul cu materii prime agricole, animale vii, materii prime textile si cu semifabricate",
  },
  {
    value: "4612",
    label:
      "Intermedieri in comerțul cu combustibili, minereuri, metale si produse chimice pentru industrie",
  },
  {
    value: "4613",
    label:
      "Intermedieri in comerțul cu material lemnos si materiale de construcții",
  },
  {
    value: "4614",
    label:
      "Intermedieri in comerțul cu masini, echipamente industriale, nave si avioane",
  },
  {
    value: "4615",
    label:
      "Intermedieri in comerțul cu mobila, articole de menaj si de fierarie",
  },
  {
    value: "4616",
    label:
      "Intermedieri in comerțul cu textile, confectii din blana, incaltaminte si articole din piele",
  },
  {
    value: "4617",
    label: "Intermedieri in comerțul cu produse alimentare, bauturi si tutun",
  },
  {
    value: "4618",
    label:
      "Intermedieri in comerțul specializat in vânzarea produselor cu caracter specific, n.c.a.",
  },
  { value: "4619", label: "Intermedieri in comerțul cu produse diverse" },
  {
    value: "462",
    label:
      "Comerț cu ridicata al produselor agricole brute si al animalelor vii",
  },
  {
    value: "4621",
    label:
      "Comerț cu ridicata al cerealelor, semințelor, furajelor si tutunului neprelucrat",
  },
  { value: "4622", label: "Comerț cu ridicata al florilor si al plantelor" },
  { value: "4623", label: "Comerț cu ridicata al animalelor vii" },
  {
    value: "4624",
    label:
      "Comerț cu ridicata al blanurilor, pieilor brute si al pieilor prelucrate",
  },
  {
    value: "463",
    label:
      "Comerț cu ridicata al produselor alimentare, al băuturilor si al tutunului",
  },
  { value: "4631", label: "Comerț cu ridicata al fructelor si legumelor" },
  {
    value: "4632",
    label: "Comerț cu ridicata al carnii si produselor din carne",
  },
  {
    value: "4633",
    label:
      "Comerț cu ridicata al produselor lactate, oualor, uleiurilor si grasimilor comestibile",
  },
  { value: "4634", label: "Comerț cu ridicata al băuturilor" },
  { value: "4635", label: "Comerț cu ridicata al produselor din tutun" },
  {
    value: "4636",
    label:
      "Comerț cu ridicata al zaharului, ciocolatei si produselor zaharoase",
  },
  {
    value: "4637",
    label: "Comerț cu ridicata cu cafea, ceai, cacao si condimente",
  },
  {
    value: "4638",
    label:
      "Comerț cu ridicata specializat al altor alimente, inclusiv peste, crustacee si moluste",
  },
  {
    value: "4639",
    label:
      "Comerț cu ridicata nespecializat de produse alimentare, bauturi si tutun",
  },
  { value: "464", label: "Comerț cu ridicata al bunurilor de consum" },
  { value: "4641", label: "Comerț cu ridicata al produselor textile" },
  {
    value: "4642",
    label: "Comerț cu ridicata al imbracamintei si incaltamintei",
  },
  {
    value: "4643",
    label:
      "Comerț cu ridicata al aparatelor electrice de uz gospodaresc, al aparatelor de radio si televizoarelor",
  },
  {
    value: "4644",
    label:
      "Comerț cu ridicata al produselor din ceramica, sticlarie, si produse de intretinere",
  },
  {
    value: "4645",
    label: "Comerț cu ridicata al produselor cosmetice si de parfumerie",
  },
  { value: "4646", label: "Comerț cu ridicata al produselor farmaceutice" },
  {
    value: "4647",
    label:
      "Comerț cu ridicata al mobilei, covoarelor si a articolelor de iluminat",
  },
  { value: "4648", label: "Comerț cu ridicata al ceasurilor si bijuteriilor" },
  {
    value: "4649",
    label: "Comerț cu ridicata al altor bunuri de uz gospodaresc",
  },
  {
    value: "465",
    label:
      "Comerț cu ridicata al echipamentului informatic si de telecomunicații",
  },
  {
    value: "4651",
    label:
      "Comerț cu ridicata al calculatoarelor, echipamentelor periferice si software- ului",
  },
  {
    value: "4652",
    label:
      "Comerț cu ridicata de componente si echipamente electronice si de telecomunicații",
  },
  {
    value: "466",
    label: "Comerț cu ridicata al altor masini, echipamente si furnituri",
  },
  {
    value: "4661",
    label:
      "Comerț cu ridicata al mașinilor agricole, echipamentelor si furniturilor",
  },
  { value: "4662", label: "Comerț cu ridicata al mașinilor- unelte" },
  {
    value: "4663",
    label:
      "Comerț cu ridicata al mașinilor pentru industria miniera si construcții",
  },
  {
    value: "4664",
    label:
      "Comerț cu ridicata al mașinilor pentru industria textila si al mașinilor de cusut si de tricotat",
  },
  { value: "4665", label: "Comerț cu ridicata al mobilei de birou" },
  {
    value: "4666",
    label: "Comerț cu ridicata al altor masini si echipamente de birou",
  },
  { value: "4669", label: "Comerț cu ridicata al altor masini si echipamente" },
  { value: "467", label: "Comerț cu ridicata specializat al altor produse" },
  {
    value: "4671",
    label:
      "Comerț cu ridicata al combustibililor solizi, lichizi si gazosi si al produselor derivate",
  },
  {
    value: "4672",
    label: "Comerț cu ridicata al metalelor si minereurilor metalice",
  },
  {
    value: "4673",
    label:
      "Comerț cu ridicata al materialului lemnos si al materialelor de construcții si echipamentelor sanitare",
  },
  {
    value: "4674",
    label:
      "Comerț cu ridicata al echipamentelor si furniturilor de fierarie pentru instalatii sanitare si de incalzire",
  },
  { value: "4675", label: "Comerț cu ridicata al produselor chimice" },
  { value: "4676", label: "Comerț cu ridicata al altor produse intermediare" },
  { value: "4677", label: "Comerț cu ridicata al deseurilor si resturilor" },
  { value: "469", label: "Comerț cu ridicata nespecializat" },
  { value: "4690", label: "Comerț cu ridicata nespecializat" },
  {
    value: "47",
    label: "Comerț cu amănuntul, cu excepția autovehiculelor și motocicletelor",
  },
  { value: "471", label: "Comerț cu amănuntul in magazine nespecializate" },
  {
    value: "4711",
    label:
      "Comerț cu amănuntul in magazine nespecializate, cu vânzare predominanta de produse alimentare, bauturi si tutun",
  },
  {
    value: "4719",
    label:
      "Comerț cu amănuntul in magazine nespecializate, cu vânzare predominanta de produse nealimentare",
  },
  {
    value: "472",
    label:
      "Comerț cu amănuntul al produselor alimentare, băuturilor si al produselor din tutun, in magazine specializate",
  },
  {
    value: "4721",
    label:
      "Comerț cu amănuntul al fructelor si legumelor proaspete, in magazine specializate",
  },
  {
    value: "4722",
    label:
      "Comerț cu amănuntul al carnii si al produselor din carne, in magazine specializate",
  },
  {
    value: "4723",
    label:
      "Comerț cu amănuntul al pestelui, crustaceelor si molustelor, in magazine specializate",
  },
  {
    value: "4724",
    label:
      "Comerț cu amănuntul al painii, produselor de patiserie si produselor zaharoase, in magazine specializate",
  },
  {
    value: "4725",
    label: "Comerț cu amănuntul al băuturilor, in magazine specializate",
  },
  {
    value: "4726",
    label:
      "Comerț cu amănuntul al produselor din tutun, in magazine specializate",
  },
  {
    value: "4729",
    label:
      "Comerț cu amănuntul al altor produse alimentare, in magazine specializate",
  },
  {
    value: "473",
    label:
      "Comerț cu amănuntul al carburantilor pentru autovehicule in magazine specializate",
  },
  {
    value: "4730",
    label:
      "Comerț cu amănuntul al carburantilor pentru autovehicule in magazine specializate",
  },
  {
    value: "474",
    label:
      "Comerț cu amănuntul al echipamentului informatic si de telecomunicații in magazine specializate",
  },
  {
    value: "4741",
    label:
      "Comerț cu amănuntul al calculatoarelor, unitatilor periferice si software-ului in magazine specializate",
  },
  {
    value: "4742",
    label:
      "Comerț cu amănuntul al echipamentului pentru telecomunicații in magazine specializate",
  },
  {
    value: "4743",
    label:
      "Comerț cu amănuntul al echipamentului audio/video in magazine specializate",
  },
  {
    value: "475",
    label:
      "Comerț cu amănuntul al altor produse casnice, in magazine specializate",
  },
  {
    value: "4751",
    label: "Comerț cu amănuntul al textilelor, in magazine specializate",
  },
  {
    value: "4752",
    label:
      "Comerț cu amănuntul al articolelor de fierarie, al articolelor din sticla si a celor pentru vopsit, in magazine specializate",
  },
  {
    value: "4753",
    label:
      "Comerț cu amănuntul al covoarelor, carpetelor, tapetelor si a altor acoperitoare de podea, in magazine specializate",
  },
  {
    value: "4754",
    label:
      "Comerț cu amănuntul al articolelor si aparatelor electrocasnice, in magazine specializate",
  },
  {
    value: "4759",
    label:
      "Comerț cu amănuntul al mobilei, al articolelor de iluminat si al articolelor de uz casnic n.c.a., in magazine specializate",
  },
  {
    value: "476",
    label:
      "Comerț cu amănuntul de bunuri culturale si recreative, in magazine specializate",
  },
  {
    value: "4761",
    label: "Comerț cu amănuntul al cartilor, in magazine specializate",
  },
  {
    value: "4762",
    label:
      "Comerț cu amănuntul al ziarelor si articolelor de papetarie, in magazine specializate",
  },
  {
    value: "4763",
    label:
      "Comerț cu amănuntul al discurilor si benzilor magnetice cu sau fără inregistrari audio/video , in magazine specializate",
  },
  {
    value: "4764",
    label:
      "Comerț cu amănuntul al echipamentelor sportive, in magazine specializate",
  },
  {
    value: "4765",
    label:
      "Comerț cu amănuntul al jocurilor si jucariilor, in magazine specializate",
  },
  {
    value: "477",
    label: "Comerț cu amănuntul al altor bunuri, în magazine specializate",
  },
  {
    value: "4771",
    label: "Comerț cu amănuntul al imbracamintei, in magazine specializate",
  },
  {
    value: "4772",
    label:
      "Comerț cu amănuntul al incaltamintei si articolelor din piele, in magazine specializate",
  },
  {
    value: "4773",
    label:
      "Comerț cu amănuntul al produselor farmaceutice, in magazine specializate",
  },
  {
    value: "4774",
    label:
      "Comerț cu amănuntul al articolelor medicale si ortopedice, in magazine specializate",
  },
  {
    value: "4775",
    label:
      "Comerț cu amănuntul al produselor cosmetice si de parfumerie, in magazine specializate",
  },
  {
    value: "4776",
    label:
      "Comerț cu amănuntul al florilor, plantelor si semințelor, comerț cu amănuntul al animalelor de companie si a hranei pentru acestea, in magazine specializate",
  },
  {
    value: "4777",
    label:
      "Comerț cu amănuntul al ceasurilor si bijuteriilor, in magazine specializate",
  },
  {
    value: "4778",
    label: "Comerț cu amănuntul al altor bunuri noi, in magazine specializate",
  },
  {
    value: "4779",
    label: "Comerț cu amănuntul al bunurilor de ocazie vandute prin magazine",
  },
  {
    value: "478",
    label: "Comerț cu amănuntul efectuat prin standuri, chioșcuri si piete",
  },
  {
    value: "4781",
    label:
      "Comerț cu amănuntul al produselor alimentare, băuturilor si produselor din tutun efectuat prin standuri, chioșcuri si piete",
  },
  {
    value: "4782",
    label:
      "Comerț cu amănuntul al textilelor, imbracamintei si incaltamintei efectuat prin standuri, chioșcuri si piete",
  },
  {
    value: "4789",
    label:
      "Comerț cu amănuntul prin standuri, chioșcuri si piete al altor produse",
  },
  {
    value: "479",
    label:
      "Comerț cu amănuntul care nu se efectueaza prin magazine, standuri, chioșcuri si piete",
  },
  {
    value: "4791",
    label:
      "Comerț cu amănuntul prin intermediul caselor de comenzi sau prin Internet",
  },
  {
    value: "4799",
    label:
      "Comerț cu amănuntul efectuat in afara magazinelor, standurilor, chioșcurilor si piețelor",
  },
  { value: "49", label: "Transporturi terestre și transporturi prin conducte" },
  {
    value: "491",
    label: "Transporturi interurbane de călători pe calea ferata",
  },
  {
    value: "4910",
    label: "Transporturi interurbane de călători pe calea ferata",
  },
  { value: "492", label: "Transporturi de marfa pe calea ferata" },
  { value: "4920", label: "Transporturi de marfa pe calea ferata" },
  { value: "493", label: "Alte transporturi terestre de călători" },
  {
    value: "4931",
    label: "Transporturi urbane, suburbane si metropolitane de călători",
  },
  { value: "4932", label: "Transporturi cu taxiuri" },
  { value: "4939", label: "Alte transporturi terestre de călători n.c.a" },
  {
    value: "494",
    label: "Transporturi rutiere de marfuri si servicii de mutare",
  },
  { value: "4941", label: "Transporturi rutiere de marfuri" },
  { value: "4942", label: "Servicii de mutare" },
  { value: "495", label: "Transporturi prin conducte" },
  { value: "4950", label: "Transporturi prin conducte" },
  { value: "50", label: "Transporturi pe apă" },
  { value: "501", label: "Transporturi maritime si costiere de pasageri" },
  { value: "5010", label: "Transporturi maritime si costiere de pasageri" },
  { value: "502", label: "Transporturi maritime si costiere de marfa" },
  { value: "5020", label: "Transporturi maritime si costiere de marfa" },
  {
    value: "503",
    label: "Transporturi de pasageri pe cai navigabile interioare",
  },
  {
    value: "5030",
    label: "Transporturi de pasageri pe cai navigabile interioare",
  },
  { value: "504", label: "Transportul de marfa pe cai navigabile interioare" },
  { value: "5040", label: "Transportul de marfa pe cai navigabile interioare" },
  { value: "51", label: "Transporturi aeriene" },
  { value: "511", label: "Transporturi aeriene de pasageri" },
  { value: "5110", label: "Transporturi aeriene de pasageri" },
  {
    value: "512",
    label: "Transporturi aeriene de marfa si transporturi spatiale",
  },
  { value: "5121", label: "Transporturi aeriene de marfa" },
  { value: "5122", label: "Transporturi spatiale" },
  {
    value: "52",
    label: "Depozitare și activități auxiliare pentru transporturi",
  },
  { value: "521", label: "Depozitări" },
  { value: "5210", label: "Depozitări" },
  { value: "522", label: "Activități anexe pentru transporturi" },
  {
    value: "5221",
    label: "Activități de servicii anexe pentru transporturi terestre",
  },
  { value: "5222", label: "Activități de servicii anexe transportului pe apa" },
  {
    value: "5223",
    label: "Activități de servicii anexe transporturilor aeriene",
  },
  { value: "5224", label: "Manipulari" },
  { value: "5229", label: "Alte activități anexe transporturilor" },
  { value: "53", label: "Activități de poștă și de curier" },
  {
    value: "531",
    label:
      "Activități poștale desfășurate sub obligativitatea serviciului universal",
  },
  {
    value: "5310",
    label:
      "Activități poștale desfășurate sub obligativitatea serviciului universal",
  },
  { value: "532", label: "Alte activități poștale si de curier" },
  { value: "5320", label: "Alte activități poștale si de curier" },
  { value: "55", label: "Hoteluri și alte facilități de cazare" },
  { value: "551", label: "Hoteluri si alte facilități de cazare similare" },
  { value: "5510", label: "Hoteluri si alte facilități de cazare similare" },
  {
    value: "552",
    label: "Facilități de cazare pentru vacante si perioade de scurta durata",
  },
  {
    value: "5520",
    label: "Facilități de cazare pentru vacante si perioade de scurta durata",
  },
  { value: "553", label: "Parcuri pentru rulote, campinguri si tabere" },
  { value: "5530", label: "Parcuri pentru rulote, campinguri si tabere" },
  { value: "559", label: "Alte servicii de cazare" },
  { value: "5590", label: "Alte servicii de cazare" },
  {
    value: "56",
    label: "Restaurante și alte activități de servicii de alimentație",
  },
  { value: "561", label: "Restaurante" },
  { value: "5610", label: "Restaurante" },
  {
    value: "562",
    label:
      "Activități de alimentatie (catering) pentru evenimente si alte servicii de alimentatie",
  },
  {
    value: "5621",
    label: "Activități de alimentatie (catering) pentru evenimente",
  },
  { value: "5629", label: "Alte servicii de alimentatie n.c.a." },
  { value: "563", label: "Baruri si alte activități de servire a băuturilor" },
  { value: "5630", label: "Baruri si alte activități de servire a băuturilor" },
  { value: "58", label: "Activități de editare" },
  {
    value: "581",
    label:
      "Activități de editare a cartilor, ziarelor, revistelor si alte activități de editare",
  },
  { value: "5811", label: "Activități de editare a cartilor" },
  {
    value: "5812",
    label:
      "Activități de editare de ghiduri, compendii, liste de adrese si similare",
  },
  { value: "5813", label: "Activități de editare a ziarelor" },
  {
    value: "5814",
    label: "Activități de editare a revistelor si periodicelor",
  },
  { value: "5819", label: "Alte activități de editare" },
  { value: "582", label: "Activități de editare a produselor software" },
  { value: "5821", label: "Activități de editare a jocurilor de calculator" },
  { value: "5829", label: "Activități de editare a altor produse software" },
  {
    value: "59",
    label:
      "Activități de producție cinematografică, video și de programe de televiziune; înregistrări audio și activități de editare muzicală",
  },
  {
    value: "591",
    label:
      "Activități de productie cinematografica, video si de programe de televiziune",
  },
  {
    value: "5911",
    label:
      "Activități de productie cinematografica, video si de programe de televiziune",
  },
  {
    value: "5912",
    label:
      "Activități de post-productie cinematografica, video si de programe de televiziune",
  },
  {
    value: "5913",
    label:
      "Activități de distributie a filmelor cinematografice, video si a programelor de televiziune",
  },
  { value: "5914", label: "Proiectia de filme cinematografice" },
  {
    value: "592",
    label:
      "Activități de realizare a inregistrarilor audio si activități de editare muzicala",
  },
  {
    value: "5920",
    label:
      "Activități de realizare a inregistrarilor audio si activități de editare muzicala",
  },
  { value: "60", label: "Activități de difuzare și transmitere de programe" },
  { value: "601", label: "Activități de difuzare a programelor de radio" },
  { value: "6010", label: "Activități de difuzare a programelor de radio" },
  {
    value: "602",
    label: "Activități de difuzare a programelor de televiziune",
  },
  {
    value: "6020",
    label: "Activități de difuzare a programelor de televiziune",
  },
  { value: "61", label: "Telecomunicații" },
  { value: "611", label: "Activități de telecomunicații prin retele cu cablu" },
  {
    value: "6110",
    label: "Activități de telecomunicații prin retele cu cablu",
  },
  {
    value: "612",
    label: "Activități de telecomunicații prin retele fără cablu",
  },
  {
    value: "6120",
    label:
      "Activități de telecomunicații prin retele fără cablu (exclusiv prin satelit)",
  },
  { value: "613", label: "Activități de telecomunicații prin satelit" },
  { value: "6130", label: "Activități de telecomunicații prin satelit" },
  { value: "619", label: "Alte activități de telecomunicații" },
  { value: "6190", label: "Alte activități de telecomunicații" },
  { value: "62", label: "Activități de servicii în tehnologia informației" },
  { value: "620", label: "Activități de servicii in tehnologia informatiei" },
  {
    value: "6201",
    label:
      "Activități de realizare a soft-ului la comanda (software orientat client)",
  },
  {
    value: "6202",
    label: "Activități de consultanta in tehnologia informatiei",
  },
  {
    value: "6203",
    label:
      "Activități de management (gestiune si exploatare) a mijloacelor de calcul",
  },
  {
    value: "6209",
    label: "Alte activități de servicii privind tehnologia informatiei",
  },
  { value: "63", label: "Activități de servicii informatice" },
  {
    value: "631",
    label:
      "Activități ale portalurilor web, prelucrarea datelor, administrarea paginilor web si activități conexe",
  },
  {
    value: "6311",
    label:
      "Prelucrarea datelor, administrarea paginilor web si activități conexe",
  },
  { value: "6312", label: "Activități ale portalurilor web" },
  { value: "639", label: "Alte activități de servicii informaționale" },
  { value: "6391", label: "Activități ale agențiilor de stiri" },
  { value: "6399", label: "Alte activități de servicii informationale n.c.a." },
  {
    value: "64",
    label:
      "Intermedieri financiare, cu excepția activităților de asigurări și ale fondurilor de pensii",
  },
  { value: "641", label: "Intermediere monetara" },
  { value: "6411", label: "Activități ale bancii centrale (nationale)" },
  { value: "6419", label: "Alte activități de intermedieri monetare" },
  { value: "642", label: "Activități ale holdingurilor" },
  { value: "6420", label: "Activități ale holdingurilor" },
  {
    value: "643",
    label: "Fonduri mutuale si alte entități financiare similare",
  },
  {
    value: "6430",
    label: "Fonduri mutuale si alte entități financiare similare",
  },
  {
    value: "649",
    label:
      "Alte activități de intermedieri financiare, exclusiv activități de asigurări si fonduri de pensii",
  },
  { value: "6491", label: "Leasing financiar" },
  { value: "6492", label: "Alte activități de creditare" },
  { value: "6499", label: "Alte intermedieri financiare n.c.a." },
  {
    value: "65",
    label:
      "Activități de asigurări, reasigurări și ale fondurilor de pensii (cu excepția celor din sistemul public de asigurări sociale)",
  },
  { value: "651", label: "Activități de asigurări" },
  { value: "6511", label: "Activități de asigurări de viata" },
  {
    value: "6512",
    label: "Alte activități de asigurări (exceptand asigurările de viata)",
  },
  { value: "652", label: "Activități de reasigurare" },
  { value: "6520", label: "Activități de reasigurare" },
  {
    value: "653",
    label:
      "Activități ale fondurilor de pensii (cu excepția celor din sistemul public de asigurări sociale)",
  },
  {
    value: "6530",
    label:
      "Activități ale fondurilor de pensii (cu excepția celor din sistemul public de asigurări sociale)",
  },
  {
    value: "66",
    label:
      "Activități auxiliare pentru intermedieri financiare, activități de asigurare și fonduri de pensii",
  },
  {
    value: "661",
    label:
      "Activități auxiliare intermedierilor financiare, cu excepția activităților de asigurări si fonduri de pensii",
  },
  { value: "6611", label: "Administrarea piețelor financiare" },
  {
    value: "6612",
    label: "Activități de intermediere a tranzactiilor financiare",
  },
  {
    value: "6619",
    label:
      "Activități auxiliare intermedierilor financiare, exclusiv activități de asigurări si fonduri de pensii",
  },
  {
    value: "662",
    label: "Activități auxiliare de asigurări si fonduri de pensii",
  },
  {
    value: "6621",
    label: "Activități de evaluare a riscului de asigurare si a pagubelor",
  },
  {
    value: "6622",
    label: "Activități ale agenților si broker-ilor de asigurări",
  },
  {
    value: "6629",
    label: "Alte activități auxiliare de asigurări si fonduri de pensii",
  },
  { value: "663", label: "Activități de administrare a fondurilor" },
  { value: "6630", label: "Activități de administrare a fondurilor" },
  { value: "68", label: "Tranzacții imobiliare" },
  {
    value: "681",
    label: "Cumpararea si vânzarea de bunuri imobiliare proprii",
  },
  {
    value: "6810",
    label: "Cumpararea si vânzarea de bunuri imobiliare proprii",
  },
  {
    value: "682",
    label:
      "Inchirierea si subinchirierea bunurilor imobiliare proprii sau inchiriate",
  },
  {
    value: "6820",
    label:
      "Inchirierea si subinchirierea bunurilor imobiliare proprii sau inchiriate",
  },
  {
    value: "683",
    label: "Activități imobiliare pe baza de comision sau contract",
  },
  { value: "6831", label: "Agentii imobiliare" },
  {
    value: "6832",
    label: "Administrarea imobilelor pe baza de comision sau contract",
  },
  { value: "69", label: "Activități juridice și de contabilitate" },
  { value: "691", label: "Activități juridice" },
  { value: "6910", label: "Activități juridice" },
  {
    value: "692",
    label:
      "Activități de contabilitate si audit financiar; consultanta in domeniul fiscal",
  },
  {
    value: "6920",
    label:
      "Activități de contabilitate si audit financiar; consultanta in domeniul fiscal",
  },
  {
    value: "70",
    label:
      "Activități ale direcțiilor (centralelor), birourilor administrative centralizate; activități de management și de consultanță în management",
  },
  {
    value: "701",
    label:
      "Activități ale directiilor (centralelor), birourilor administrative centralizate",
  },
  {
    value: "7010",
    label:
      "Activități ale directiilor (centralelor), birourilor administrative centralizate",
  },
  { value: "702", label: "Activități de consultanta in management" },
  {
    value: "7021",
    label:
      "Activități de consultanta in domeniul relatiilor publice si al comunicarii",
  },
  {
    value: "7022",
    label: "Activități de consultanta pentru afaceri si management",
  },
  {
    value: "71",
    label:
      "Activități de arhitectură și inginerie; activități de testări și analiză tehnică",
  },
  {
    value: "711",
    label:
      "Activități de arhitectura, inginerie si servicii de consultanta tehnica legate de acestea",
  },
  { value: "7111", label: "Activități de arhitectura" },
  {
    value: "7112",
    label: "Activități de inginerie si consultanta tehnica legate de acestea",
  },
  { value: "712", label: "Activități de testari si analize tehnice" },
  { value: "7120", label: "Activități de testari si analize tehnice" },
  { value: "72", label: "Cercetare-dezvoltare" },
  {
    value: "721",
    label: "Cercetare-dezvoltare in stiinte naturale si inginerie",
  },
  { value: "7211", label: "Cercetare - dezvoltare in biotehnologie" },
  {
    value: "7219",
    label: "Cercetare- dezvoltare in alte stiinte naturale si inginerie",
  },
  {
    value: "722",
    label: "Cercetare-dezvoltare in stiinte sociale si umaniste",
  },
  {
    value: "7220",
    label: "Cercetare- dezvoltare in stiinte sociale si umaniste",
  },
  { value: "73", label: "Publicitate și activități de studiere a pieței" },
  { value: "731", label: "Publicitate" },
  { value: "7311", label: "Activități ale agențiilor de publicitate" },
  { value: "7312", label: "Servicii de reprezentare media" },
  {
    value: "732",
    label: "Activități de studiere a pieței si de sondare a opiniei publice",
  },
  {
    value: "7320",
    label: "Activități de studiere a pieței si de sondare a opiniei publice",
  },
  {
    value: "74",
    label: "Alte activități profesionale, științifice și tehnice",
  },
  { value: "741", label: "Activități de design specializat" },
  { value: "7410", label: "Activități de design specializat" },
  { value: "742", label: "Activități fotografice" },
  { value: "7420", label: "Activități fotografice" },
  {
    value: "743",
    label: "Activități de traducere scrisă si orala (interpreti)",
  },
  {
    value: "7430",
    label: "Activități de traducere scrisă si orala (interpreti)",
  },
  {
    value: "749",
    label: "Alte activități profesionale, stiintifice si tehnice n.c.a.",
  },
  {
    value: "7490",
    label: "Alte activități profesionale, stiintifice si tehnice n.c.a.",
  },
  { value: "75", label: "Activități veterinare" },
  { value: "750", label: "Activități veterinare" },
  { value: "7500", label: "Activități veterinare" },
  { value: "77", label: "Activități de închiriere și leasing" },
  {
    value: "771",
    label: "Activități de inchiriere si leasing cu autovehicule",
  },
  {
    value: "7711",
    label:
      "Activități de inchiriere si leasing cu autoturisme si autovehicule rutiere usoare",
  },
  {
    value: "7712",
    label: "Activități de inchiriere si leasing cu autovehicule rutiere grele",
  },
  {
    value: "772",
    label:
      "Activități de inchiriere si leasing cu bunuri personale si gospodaresti",
  },
  {
    value: "7721",
    label:
      "Activități de inchiriere si leasing cu bunuri recreationale si echipament sportiv",
  },
  {
    value: "7722",
    label: "Inchirierea de casete video si discuri (CD-uri, DVD-uri)",
  },
  {
    value: "7729",
    label:
      "Activități de inchiriere si leasing cu alte bunuri personale si gospodaresti n.c.a.",
  },
  {
    value: "773",
    label:
      "Activități de inchiriere si leasing cu alte masini, echipamente si bunuri tangibile",
  },
  {
    value: "7731",
    label:
      "Activități de inchiriere si leasing cu masini si echipamente agricole",
  },
  {
    value: "7732",
    label:
      "Activități de inchiriere si leasing cu masini si echipamente pentru construcții",
  },
  {
    value: "7733",
    label:
      "Activități de inchiriere si leasing cu masini si echipamente de birou (inclusiv calculatoare)",
  },
  {
    value: "7734",
    label:
      "Activități de inchiriere si leasing cu masini si echipamente de transport pe apa",
  },
  {
    value: "7735",
    label:
      "Activități de inchiriere si leasing cu masini si echipamente de transport aerian",
  },
  {
    value: "7739",
    label:
      "Activități de inchiriere si leasing cu alte masini, echipamente si bunuri tangibile n.c.a.",
  },
  {
    value: "774",
    label: "Leasing cu bunuri intangibile (exclusiv financiare)",
  },
  {
    value: "7740",
    label: "Leasing cu bunuri intangibile (exclusiv financiare)",
  },
  { value: "78", label: "Activități de servicii privind forța de muncă" },
  {
    value: "781",
    label: "Activități ale agențiilor de plasare a forței de munca",
  },
  {
    value: "7810",
    label: "Activități ale agențiilor de plasare a forței de munca",
  },
  {
    value: "782",
    label: "Activități de contractare, pe baze temporare, a personalului",
  },
  {
    value: "7820",
    label: "Activități de contractare, pe baze temporare, a personalului",
  },
  {
    value: "783",
    label: "Servicii de furnizare si management a forței de munca",
  },
  {
    value: "7830",
    label: "Servicii de furnizare si management a forței de munca",
  },
  {
    value: "79",
    label:
      "Activități ale agențiilor turistice și a tur-operatorilor; alte servicii de rezervare și asistență turistică",
  },
  {
    value: "791",
    label: "Activități ale agențiilor turistice si a tur-operatorilor",
  },
  { value: "7911", label: "Activități ale agențiilor turistice" },
  { value: "7912", label: "Activități ale tur-operatorilor" },
  { value: "799", label: "Alte servicii de rezervare si asistenta turistica" },
  { value: "7990", label: "Alte servicii de rezervare si asistenta turistica" },
  { value: "80", label: "Activități de investigații și protecție" },
  { value: "801", label: "Activități de protectie si garda" },
  { value: "8010", label: "Activități de protectie si garda" },
  {
    value: "802",
    label: "Activități de servicii privind sistemele de securizare",
  },
  {
    value: "8020",
    label: "Activități de servicii privind sistemele de securizare",
  },
  { value: "803", label: "Activități de investigații" },
  { value: "8030", label: "Activități de investigații" },
  {
    value: "81",
    label: "Activități de peisagistică și servicii pentru clădiri",
  },
  { value: "811", label: "Activități de servicii suport combinate" },
  { value: "8110", label: "Activități de servicii suport combinate" },
  { value: "812", label: "Activități de curatenie" },
  { value: "8121", label: "Activități generale de curatenie a cladirilor" },
  { value: "8122", label: "Activități specializate de curatenie" },
  { value: "8129", label: "Alte activități de curatenie" },
  { value: "813", label: "Activități de intetinere peisagistica" },
  { value: "8130", label: "Activități de intretinere peisagistica" },
  {
    value: "82",
    label: "Activități de peisagistică și servicii pentru clădiri",
  },
  { value: "821", label: "Activități de secretariat și servicii suport" },
  { value: "8211", label: "Activități combinate de secretariat" },
  {
    value: "8219",
    label:
      "Activități de fotocopiere, de pregatire a documentelor si alte activități specializate de secretariat",
  },
  {
    value: "822",
    label: "Activități ale centrelor de intermediere telefonica (call center)",
  },
  {
    value: "8220",
    label: "Activități ale centrelor de intermediere telefonica (call center)",
  },
  {
    value: "823",
    label: "Activități de organizare a expozitiilor, targurilor si congreselor",
  },
  {
    value: "8230",
    label: "Activități de organizare a expozitiilor, targurilor si congreselor",
  },
  {
    value: "829",
    label: "Activități de servicii suport pentru intreprinderi n.c.a.",
  },
  {
    value: "8291",
    label:
      "Activități ale agențiilor de colectare si a birourilor (oficiilor) de raportare a creditului",
  },
  { value: "8292", label: "Activități de ambalare" },
  {
    value: "8299",
    label: "Alte activități de servicii suport pentru intreprinderi n.c.a.",
  },
  {
    value: "84",
    label:
      "Administrație publică și apărare; asigurări sociale din sistemul public",
  },
  {
    value: "841",
    label: "Administratie publica generala, economica si sociala",
  },
  { value: "8411", label: "Servicii de administratie publica generala" },
  {
    value: "8412",
    label:
      "Reglementarea activităților organismelor care presteaza servicii in domeniul ingrijirii sanatatii, invatamantului, culturii si al altor activități sociale, exclusiv protectia sociala",
  },
  {
    value: "8413",
    label: "Reglementarea si eficientizarea activităților economice",
  },
  { value: "842", label: "Activități de servicii pentru societate" },
  { value: "8421", label: "Activități de servicii externe" },
  { value: "8422", label: "Activități de aparare nationala" },
  { value: "8423", label: "Activități de justitie" },
  {
    value: "8424",
    label: "Activități de ordine publica si de protectie civila",
  },
  {
    value: "8425",
    label:
      "Activități de lupta impotriva incendiilor si de prevenire a acestora",
  },
  { value: "843", label: "Activități de protectie sociala obligatorie" },
  { value: "8430", label: "Activități de protectie sociala obligatorie" },
  { value: "85", label: "Învățământ" },
  { value: "851", label: "Invatamant preșcolar" },
  { value: "8510", label: "Invatamant preșcolar" },
  { value: "852", label: "Invatamant primar" },
  { value: "8520", label: "Invatamant primar" },
  { value: "853", label: "Invatamant secundar" },
  { value: "8531", label: "Invatamant secundar general" },
  { value: "8532", label: "Invatamant secundar, tehnic sau profesional" },
  { value: "854", label: "Invatamant superior" },
  { value: "8541", label: "Invatamant superior non- universitar" },
  { value: "8542", label: "Invatamant superior universitar" },
  { value: "855", label: "Alte forme de invatamant" },
  { value: "8551", label: "Invatamant in domeniul sportiv si recreational" },
  {
    value: "8552",
    label:
      "Invatamant in domeniul cultural (limbi straine, muzica, teatru, dans, arte plastice, etc.)",
  },
  { value: "8553", label: "Scoli de conducere (pilotaj)" },
  { value: "8559", label: "Alte forme de invatamant n.c.a." },
  { value: "856", label: "Activități de servicii suport pentru invatamant" },
  { value: "8560", label: "Activități de servicii suport pentru invatamant" },
  { value: "86", label: "Activități referitoare la sănătatea umană" },
  { value: "861", label: "Activități de asistenta spitaliceasca" },
  { value: "8610", label: "Activități de asistenta spitaliceasca" },
  {
    value: "862",
    label: "Activități de asistenta medicala ambulatorie si stomatologica",
  },
  { value: "8621", label: "Activități de asistenta medicala generala" },
  { value: "8622", label: "Activități de asistenta medicala specializata" },
  { value: "8623", label: "Activități de asistenta stomatologica" },
  { value: "869", label: "Alte activități referitoare la sanatatea umana" },
  { value: "8690", label: "Alte activități referitoare la sanatatea umana" },
  {
    value: "87",
    label:
      "Servicii combinate de îngrijire medicală și asistență socială, cu cazare",
  },
  { value: "871", label: "Activități ale centrelor de ingrijire medicala" },
  { value: "8710", label: "Activități ale centrelor de ingrijire medicala" },
  {
    value: "872",
    label:
      "Activități ale centrelor de recuperare pshica si de dezintoxicare, exclusiv spitale",
  },
  {
    value: "8720",
    label:
      "Activități ale centrelor de recuperare psihica si de dezintoxicare, exclusiv spitale",
  },
  {
    value: "873",
    label:
      "Activități ale caminelor de bătrâni si ale caminelor pentru persoane aflate in incapacitate de a se ingriji singure",
  },
  {
    value: "8790",
    label: "Alte activități de asistenta sociala, cu cazare n.c.a.",
  },
  { value: "88", label: "Activități de asistență socială, fără cazare" },
  {
    value: "881",
    label:
      "Activități de asistenta sociala, fără cazare, pentru bătrâni si pentru persoane aflate in incapacitate de a se ingriji singure",
  },
  {
    value: "8810",
    label:
      "Activități de asistenta sociala, fără cazare, pentru bătrâni si pentru persoane aflate in incapacitate de a se ingriji singure",
  },
  { value: "889", label: "Alte activități de asistenta sociala, fără cazare" },
  { value: "8891", label: "Activități de ingrijire zilnica pentru copii" },
  {
    value: "8899",
    label: "Alte activități de asistenta sociala, fără cazare, n.c.a.",
  },
  { value: "90", label: "Activități de creație și interpretare artistică" },
  { value: "900", label: "Activități de creație si interpretare artistica" },
  { value: "9001", label: "Activități de interpretare artistica (spectacole)" },
  {
    value: "9002",
    label: "Activități suport pentru interpretare artistica (spectacole)",
  },
  { value: "9003", label: "Activități de creație artistica" },
  { value: "9004", label: "Activități de gestionare a salilor de spectacole" },
  {
    value: "91",
    label:
      "Activități ale bibliotecilor, arhivelor, muzeelor și alte activități culturale",
  },
  {
    value: "910",
    label:
      "Activități ale bibliotecilor, arhivelor, muzeelor si alte activități culturale",
  },
  { value: "9101", label: "Activități ale bibliotecilor si arhivelor" },
  { value: "9102", label: "Activități ale muzeelor" },
  {
    value: "9103",
    label:
      "Gestionarea monumentelor, cladirilor istorice si a altor obiective de interes turistic",
  },
  {
    value: "9104",
    label:
      "Activități ale gradinilor zoologice, botanice si ale rezervatiilor naturale",
  },
  { value: "92", label: "Activități de jocuri de noroc și pariuri" },
  { value: "920", label: "Activități de jocuri de noroc si pariuri" },
  { value: "9200", label: "Activități de jocuri de noroc si pariuri" },
  { value: "93", label: "Activități sportive, recreative și distractive" },
  { value: "931", label: "Activități sportive" },
  { value: "9311", label: "Activități ale bazelor sportive" },
  { value: "9312", label: "Activități ale cluburilor sportive" },
  { value: "9313", label: "Activități ale centrelor de fitness" },
  { value: "9319", label: "Alte activități sportive" },
  { value: "932", label: "Alte activități recreative si distractive" },
  { value: "9321", label: "Bâlciuri si parcuri de distracții" },
  { value: "9329", label: "Alte activități recreative si distractive n.c.a." },
  { value: "94", label: "Activități asociative diverse" },
  {
    value: "941",
    label: "Activități ale organizatiilor economice, patronale si profesionale",
  },
  {
    value: "9411",
    label: "Activități ale organizatiilor economice si patronale",
  },
  { value: "9412", label: "Activități ale organizatiilor profesionale" },
  { value: "942", label: "Activități ale sindicatelor salariatilor" },
  { value: "9420", label: "Activități ale sindicatelor salariatilor" },
  { value: "949", label: "Alte activități asociative" },
  { value: "9491", label: "Activități ale organizatiilor religioase" },
  { value: "9492", label: "Activități ale organizatiilor politice" },
  { value: "9499", label: "Activități ale altor organizatii n.c.a." },
  {
    value: "95",
    label:
      "Reparații de calculatoare, de articole personale și de uz gospodăresc",
  },
  {
    value: "951",
    label: "Repararea calculatoarelor si a echipamentelor de comunicații",
  },
  {
    value: "9511",
    label: "Repararea calculatoarelor si a echipamentelor periferice",
  },
  { value: "9512", label: "Repararea echipamentelor de comunicații" },
  {
    value: "952",
    label: "Reparatii de articole personale si de uz gospodaresc",
  },
  { value: "9521", label: "Repararea aparatelor electronice de uz casnic" },
  {
    value: "9522",
    label:
      "Repararea dispozitivelor de uz gospodaresc si a echipamentelor pentru casa si gradina",
  },
  {
    value: "9523",
    label: "Repararea incaltamintei si a articolelor din piele",
  },
  { value: "9524", label: "Repararea mobilei si a furniturilor casnice" },
  { value: "9525", label: "Repararea ceasurilor si a bijuteriilor" },
  {
    value: "9529",
    label: "Repararea articolelor de uz personal si gospodaresc n.c.a.",
  },
  { value: "96", label: "Alte activități de servicii" },
  { value: "960", label: "Alte activități de servicii" },
  {
    value: "9601",
    label:
      "Spalarea si curatarea (uscata) articolelor textile si a produselor din blana",
  },
  { value: "9602", label: "Coafura si alte activități de infrumusetare" },
  { value: "9603", label: "Activități de pompe funebre si similare" },
  { value: "9604", label: "Activități de intretinere corporala" },
  { value: "9609", label: "Alte activități de servicii n.c.a." },
  {
    value: "97",
    label:
      "Activități ale gospodăriilor private în calitate de angajator de personal casnic",
  },
  {
    value: "970",
    label:
      "Activități ale gospodariilor private in calitate de angajator de personal casnic",
  },
  {
    value: "9700",
    label:
      "Activități ale gospodariilor private in calitate de angajator de personal casnic",
  },
  {
    value: "98",
    label:
      "Activități ale gospodăriilor private de producere de bunuri și servicii destinate consumului propriu",
  },
  {
    value: "981",
    label:
      "Activități ale gospodariilor private de producere de bunuri destinate consumului propriu",
  },
  {
    value: "9810",
    label:
      "Activități ale gospodariilor private de producere de bunuri destinate consumului propriu",
  },
  {
    value: "982",
    label:
      "Activități ale gospodariilor private de producere de servicii pentru scopuri proprii",
  },
  {
    value: "9820",
    label:
      "Activități ale gospodariilor private de producere de servicii pentru scopuri proprii",
  },
  {
    value: "99",
    label: "Activități ale organizațiilor și organismelor extrateritoriale",
  },
  {
    value: "990",
    label: "Activități ale organizatiilor si organismelor extrateritoriale",
  },
  {
    value: "9900",
    label: "Activități ale organizatiilor si organismelor extrateritoriale",
  },
];

const extraIndicators = [
  { label: "Cifra de afaceri", key: "cifra_de_afaceri_neta" },
  { label: "Capitaluri proprii", key: "capitaluri_total" },
  { label: "Active circulante", key: "active_circulante_total" },
  { label: "Active imobilizate", key: "active_imobilizate_total" },
  { label: "Datorii totale", key: "datorii_total" },
  { label: "Venituri totale", key: "venituri_totale" },
  { label: "Cheltuieli totale", key: "cheltuieli_totale" },
  { label: "Profit brut", key: "profit_brut" },
  { label: "Pierdere brută", key: "pierdere_bruta" },
  { label: "Casă și conturi", key: "casa_si_conturi" },
  { label: "Stocuri", key: "stocuri" },
  { label: "Creanțe", key: "creante" },
  { label: "Provizioane", key: "provizioane" },
  { label: "Venituri în avans", key: "venituri_in_avans" },
  { label: "Cheltuieli în avans", key: "cheltuieli_in_avans" },
];

function Step1Content({
  handleAutocomplete,
  data,
  updateData,
  showSteps,
  setSwitchStep,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const financialYears = [currentYear - 1, currentYear - 2, currentYear - 3];
  console.log("Step1: ", data);
  const mainIndicators = [
    { label: "Numar mediu angajati", key: "numar_mediu_de_salariati" },
    { label: "Profit net", key: "profit_net" },
    { label: "Pierdere netă", key: "pierdere_neta" },
  ];

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>
          <strong>{props.data.value}</strong> - {props.data.label}
        </div>
      </components.Option>
    );
  };
  console.log("Step1 TVA: ", data.tva);
  console.log("Step1 category: ", data.companyCategory);
  const MenuList = (props) => {
    const { children, maxHeight } = props;
    const height = 35; // Item height

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        width="100%"
        style={{ backgroundColor: "white" }} // Background color
      >
        {({ index, style }) => (
          <div style={{ ...style, overflow: "hidden" }}>{children[index]}</div>
        )}
      </List>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAutocomplete(data.cui);
    }
  };

  const [showExtraInfo, setShowExtraInfo] = useState(false);

  return (
    <Container
      borderRadius={10}
      marginTop={15}
      padding={5}
      maxW="800"
      backgroundColor="white"
    >
      <Heading paddingTop={3} fontSize="xl">
        Introdu CUI-ul companiei
      </Heading>
      <HStack padding={5} flexDirection={["column", "row", "row"]}>
        <FormControl id="cui" isRequired>
          <Input
            placeholder="RO42171928"
            value={data.cui}
            onChange={(e) => updateData({ ...data, cui: e.target.value })}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        {isLoading ? (
          <Button isLoading loadingText="Preluam datele" disabled={true}>
            <Spinner />
          </Button>
        ) : (
          <Button
            colorScheme="blue"
            w={["100%", "240px", "240px"]}
            onClick={() => {
              setIsLoading(true);
              handleAutocomplete(data.cui);
              setIsLoading(false);
            }}
          >
            Autocompletează
          </Button>
        )}
      </HStack>

      {showSteps && (
        <>
          <Heading paddingTop={10} fontSize="xl">
            Informatii generale
          </Heading>
          <VStack spacing={5} padding={5}>
            <HStack
              w="full"
              spacing={5}
              flexDirection={["column", "column", "row", "row"]}
            >
              <FormControl
                id="companyName"
                isRequired
                isInvalid={!data.companyName || data.companyName === ""}
              >
                <FormLabel>Numele companiei</FormLabel>
                <Input
                  placeholder="COMPANY NAME S.R.L."
                  value={data.companyName}
                  onChange={(e) =>
                    updateData({ ...data, companyName: e.target.value })
                  }
                />
              </FormControl>
              <FormControl
                id="companyForm"
                isRequired
                isInvalid={!data.companyForm || data.companyForm === ""}
              >
                <FormLabel>Forma juridica</FormLabel>
                <Select
                  placeholder="Selectează forma juridică"
                  value={data.companyForm || ""}
                  onChange={(e) =>
                    updateData({ ...data, companyForm: e.target.value })
                  }
                >
                  <option value="S.R.L.">S.R.L.</option>
                  <option value="S.A.">S.A.</option>
                  <option value="P.F.A.">P.F.A.</option>
                  <option value="I.I.">I.I.</option>
                  <option value="I.F.">I.F.</option>
                </Select>
              </FormControl>
            </HStack>
            <HStack
              w="full"
              spacing={5}
              flexDirection={["column", "column", "row", "row"]}
            >
              <FormControl
                id="registrationDate"
                isRequired
                isInvalid={
                  !data.registrationDate || data.registrationDate === ""
                }
              >
                <FormLabel>Data înregistrării</FormLabel>
                <Input
                  type="date"
                  value={data.registrationDate}
                  onChange={(e) =>
                    updateData({ ...data, registrationDate: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="registrationNumber" isRequired>
                <FormLabel>Numarul inregistrarii</FormLabel>
                <Input
                  type="text"
                  value={data.registrationNumber}
                  onChange={(e) =>
                    updateData({ ...data, registrationNumber: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="companyCategory" isRequired>
                <FormLabel>Categoria entității</FormLabel>
                <Select
                  placeholder="Selectează categoria"
                  value={data.companyCategory || ""}
                  onChange={(e) =>
                    updateData({ ...data, companyCategory: e.target.value })
                  }
                >
                  <option value="Micro">Micro</option>
                  <option value="Small">Mică</option>
                  <option value="Medium">Medie</option>
                  <option value="Big">Mare</option>
                </Select>
              </FormControl>
            </HStack>
            forma juridică
            <FormControl id="companyAddress" isRequired>
              <FormLabel>Adresa sediului social</FormLabel>
              <Input
                placeholder="Oraș, Strada FINACCESS 3, et.1"
                value={data.companyAddress || ""}
                onChange={(e) =>
                  updateData({ ...data, companyAddress: e.target.value })
                }
              />
            </FormControl>
            <FormControl
              id="companyRegions"
              isInvalid={!data.companyRegions || data.companyRegions === ""}
            >
              <FormLabel>Locatiile tuturor sediilor</FormLabel>
              <MSelect
                isMulti
                value={data.companyRegions.map((region) => ({
                  value: region,
                  label: region,
                }))}
                name="secondaryAddresses"
                options={counties.map((county) => ({
                  value: county,
                  label: county,
                }))}
                placeholder="Selectează județele"
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  // Update your state based on selected values
                  const selectedCounties = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  updateData({
                    ...data,
                    companyRegions: selectedCounties,
                  });
                }}
              />
            </FormControl>
            <FormControl
              id="authorizedCaen"
              isInvalid={
                !data.authorizedCaenCodes || data.authorizedCaenCodes === ""
              }
            >
              <FormLabel>Codurile CAEN autorizate</FormLabel>
              <MSelect
                components={{ MenuList, Option }}
                options={caenOptions}
                isMulti
                // isSearchable={true}
                value={data.authorizedCaenCodes.map((code) => ({
                  value: code,
                  label: code,
                }))}
                name="authorizedCaen"
                placeholder="Selectează codurile CAEN"
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  // Update your state based on selected values
                  const selectedCodes = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  updateData({
                    ...data,
                    authorizedCaenCodes: selectedCodes,
                  });
                }}
              />
            </FormControl>
            <FormControl
              id="companyDescription"
              isRequired
              isInvalid={
                !data.companyDescription || data.companyDescription === ""
              }
            >
              <FormLabel>Scurta descriere</FormLabel>
              <Textarea
                value={data.companyDescription}
                placeholder="Suntem un producator de mobila, care fabrica mese si scaune pt birou."
                onChange={(e) =>
                  updateData({ ...data, companyDescription: e.target.value })
                }
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" id="TVA">
              <FormLabel htmlFor="tva-switch" mb="0">
                Plătitor de TVA
              </FormLabel>
              <Switch
                id="tva-switch"
                isChecked={data.tva !== null}
                onChange={(e) =>
                  updateData({ ...data, tva: e.target.checked ? "da" : "nu" })
                }
                colorScheme="green"
              />
            </FormControl>
          </VStack>

          <Box>
            <Heading fontSize="xl">Indicatori Financiari</Heading>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Indicatori</Th>
                    {financialYears.map((year) => (
                      <Th key={year}>{year}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {mainIndicators.map((indicator) => (
                    <Tr key={indicator.key}>
                      <Td>{indicator.label}</Td>
                      {financialYears.map((year, index) => (
                        <Td key={year}>
                          <Input
                            width={["95px", "100%"]}
                            type="number"
                            value={
                              data[`financialYear${index + 1}`]?.[
                                indicator.key
                              ] !== null &&
                              data[`financialYear${index + 1}`]?.[
                                indicator.key
                              ] !== undefined
                                ? data[`financialYear${index + 1}`]?.[
                                    indicator.key
                                  ]
                                : ""
                            }
                            onChange={(e) =>
                              updateData({
                                ...data,
                                [`financialYear${index + 1}`]: {
                                  ...data[`financialYear${index + 1}`],
                                  [indicator.key]: e.target.value,
                                },
                              })
                            }
                          />
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            
            <Button
              mt={4}
              variant="ghost"
              size="sm"
              colorScheme="gray"
              onClick={() => setShowExtraInfo(!showExtraInfo)}
              leftIcon={showExtraInfo ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {showExtraInfo ? "Ascunde Informatii Extra" : "Arata Informatii Extra"}
            </Button>

            {showExtraInfo && (
              <TableContainer mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Indicator</Th>
                      {financialYears.map((year) => (
                        <Th key={year}>{year}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {extraIndicators.map((indicator) => (
                      <Tr key={indicator.key}>
                        <Td>{indicator.label}</Td>
                        {financialYears.map((year, index) => (
                          <Td key={year}>
                            <Input
                              width={["95px", "100%"]}
                              type="number"
                              value={
                                data[`financialYear${index + 1}`]?.[
                                  indicator.key
                                ] !== null &&
                                data[`financialYear${index + 1}`]?.[
                                  indicator.key
                                ] !== undefined
                                  ? data[`financialYear${index + 1}`]?.[
                                      indicator.key
                                    ]
                                  : ""
                              }
                              onChange={(e) =>
                                updateData({
                                  ...data,
                                  [`financialYear${index + 1}`]: {
                                    ...data[`financialYear${index + 1}`],
                                    [indicator.key]: e.target.value,
                                  },
                                })
                              }
                            />
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
      )}
    </Container>
  );
}

export default Step1Content;
