import React from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineHeart, AiOutlineComment } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function MiniCard({
  title,
  description,
  image,
  type,
  amount,
  likes,
  comments,
}) {
  const navigate = useNavigate();
  let boxBg = useColorModeValue("white !important", "#111c44 !important");
  let secondaryBg = useColorModeValue("gray.50", "whiteAlpha.100");
  let mainText = useColorModeValue("gray.800", "white");
  let iconBox = useColorModeValue("gray.100", "whiteAlpha.200");

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent card click when clicking the button
    navigate("/signin");
  };

  return (
    <Flex
      borderRadius="20px"
      bg={boxBg}
      h="250px"
      w={{ base: "240px", md: "280px" }}
      direction="column"
      overflow="hidden"
      margin="10px"
      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      transition="all 0.3s ease"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
      }}
      cursor="pointer"
      onClick={() => navigate("/signin")}
    >
      <Box position="relative" h="90px" bg="white">
        <Image 
          src={image} 
          w="auto"
          h="70px"
          maxW="80%"
          objectFit="contain"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          bg="rgba(0,0,0,0.03)"
          px="3"
          py="1"
          borderTopRightRadius="10px"
        >
          <Text fontSize="xs" fontWeight="500" color="gray.600">
            {type}
          </Text>
        </Box>
      </Box>

      <Box p="12px" flex="1" overflowY="hidden">
        {title && (
          <Text
            fontWeight="600"
            color={mainText}
            w="100%"
            fontSize="sm"
            mb="4px"
            noOfLines={2}
          >
            {title}
          </Text>
        )}
        <Text 
          fontSize="xs" 
          color="gray.600" 
          lineHeight="1.4"
          noOfLines={2}
          mb="4px"
        >
          {description}
        </Text>
        {amount && (
          <Text 
            fontSize="sm" 
            color="blue.500" 
            fontWeight="600"
          >
            {amount}
          </Text>
        )}
      </Box>

      <Flex
        bg={secondaryBg}
        w="100%"
        p="8px 12px"
        alignItems="center"
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="gray.100"
      >
        <Flex>
          <Flex 
            align="center" 
            me="12px" 
            p="3px 6px"
            borderRadius="full"
            transition="all 0.2s"
            _hover={{ bg: iconBox }}
          >
            <Icon
              as={AiOutlineHeart}
              w="14px"
              h="14px"
              me="3px"
              color="gray.500"
            />
            <Text color={mainText} fontSize="xs" fontWeight="500">
              {likes}
            </Text>
          </Flex>
          <Flex 
            align="center"
            p="3px 6px"
            borderRadius="full"
            transition="all 0.2s"
            _hover={{ bg: iconBox }}
          >
            <Icon
              as={AiOutlineComment}
              w="14px"
              h="14px"
              me="3px"
              color="gray.500"
            />
            <Text color={mainText} fontSize="xs" fontWeight="500">
              {comments}
            </Text>
          </Flex>
        </Flex>
        <Button
          colorScheme="blue"
          size="xs"
          borderRadius="full"
          px="3"
          fontSize="xs"
          fontWeight="500"
          onClick={handleClick}
          _hover={{
            transform: "translateY(-1px)",
            boxShadow: "sm"
          }}
        >
          Mai multe
        </Button>
      </Flex>
    </Flex>
  );
}

export default MiniCard;

