import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import financingOptionsStyles from "./FinancingOptions.module.css";
import { Box, Image, Heading, Text, VStack } from "@chakra-ui/react";
import Logo from "./assets/hero.png";
import HorizontalCarousel from './HorizontalCarousel';

function FinancingOption({
  title,
  column1Value,
  column1Title,
  column2Value,
  column2Title,
  column3Value,
  column3Title,
  buttonText,
  description,
}) {
  const navigate = useNavigate();

  return (
    <article className={financingOptionsStyles.financingOption}>
      <h3 className={financingOptionsStyles.optionTitle}>{title}</h3>
      <div className={financingOptionsStyles.statsContainer}>
        <p className={financingOptionsStyles.stats}>
          {column1Value}
          <br />
          <span className={financingOptionsStyles.lightText}>
            {column1Title}
          </span>
        </p>
        <p className={financingOptionsStyles.stats}>
          {column2Value}
          <br />
          <span className={financingOptionsStyles.lightText}>
            {column2Title}
          </span>
        </p>
        <p className={financingOptionsStyles.stats}>
          {column3Value}
          <br />
          <span className={financingOptionsStyles.lightText}>
            {column3Title}
          </span>
        </p>
      </div>
      <p className={financingOptionsStyles.description}>{description}</p>
      <button 
        onClick={() => navigate("/signin")} 
        className={financingOptionsStyles.findOutMore}
      >
        {buttonText}
      </button>
    </article>
  );
}

const carouselData = [
  {
    title: "Sprijin pentru întreprinderi nou înființate inovatoare",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "100,000€ - 1,000,000€",
    description:
      "până la 1,6 milioane de euro, cu 0% cofinanțare",
  },
  {
    title: "Fonduri europene",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "50,000€ - 500,000€",
    description:
      "Obține granturi și finanțări nerambursabile pentru proiectele tale de dezvoltare.",
  },
  {
    title: "Surse alternative",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "10,000€ - 100,000€",
    description:
      "Explorează opțiuni inovative de finanțare, precum capital de risc, crowdfunding și business angels.",
  },
  {
    title: "Surse alternative",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "10,000€ - 100,000€",
    description:
      "Explorează opțiuni inovative de finanțare, precum capital de risc, crowdfunding și business angels.",
  },
  {
    title: "Surse alternative",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "10,000€ - 100,000€",
    description:
      "Explorează opțiuni inovative de finanțare, precum capital de risc, crowdfunding și business angels.",
  },
  {
    title: "Surse alternative",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "10,000€ - 100,000€",
    description:
      "Explorează opțiuni inovative de finanțare, precum capital de risc, crowdfunding și business angels.",
  },
  {
    title: "Surse alternative",
    image: "https://via.placeholder.com/250x150",
    moneyRange: "10,000€ - 100,000€",
    description:
      "Explorează opțiuni inovative de finanțare, precum capital de risc, crowdfunding și business angels.",
  },
];

function CarouselCard({ title, image, moneyRange, description }) {
  return (
    <Box className={financingOptionsStyles.carouselCard}>
      <Image src={image} alt={title} />
      <VStack align="start" spacing={2} mt={4}>
        <Heading size="md">{title}</Heading>
        <Text fontWeight="bold">{moneyRange}</Text>
        <Text>{description}</Text>
      </VStack>
    </Box>
  );
}

const FinancingOptions = React.forwardRef((props, ref) => {
  const options = [
    {
      title: "Finanțare bancară",
      column1Value: "23+",
      column1Title: "parteneri bancari",
      column2Value: "150+",
      column2Title: "companii finantate",
      column3Value: "8+",
      column3Title: "tipuri de finantari",
      description:
        "Acceseaza credite, imprumuturi bancare pentru investitii si capital de lucru, leasing pentru afacerea ta.",
      buttonText: "Descopera",
    },
    {
      title: "Fonduri europene",
      column1Value: "500+",
      column1Title: "programe de finantare",
      column2Value: "1,5+ mil. €",
      column2Title: "finantare obtinuta",
      column3Value: "83%",
      column3Title: "rata de finantare",
      description:
        "Obtine granturi si finantari nerambursabile pentru proiectele tale de deazvoltare.",
      buttonText: "Acceseaza",
    },
    {
      title: "Surse alternative",
      column1Value: "35+",
      column1Title: "parteneri financiari/investitori",
      column2Value: "100.000 €",
      column2Title: "valoare medie finantare",
      column3Value: "10+",
      column3Title: "campanii active",
      description:
        "Exploreaza optiuni inovative de finantare, precum capital de risc, crowdfunding si business angels.",
      buttonText: "Exploreaza",
    },
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };  

  return (
    <section
      ref={ref}
      className={financingOptionsStyles.financingOptionsSection}
    >
      <h2 className={financingOptionsStyles.sectionTitle}>
        Care sunt opțiunile de finanțare disponibile?
      </h2>
      <div className={financingOptionsStyles.optionsContainer}>
        {options.map((option, index) => (
          <FinancingOption
            key={index}
            title={option.title}
            column1Value={option.column1Value}
            column1Title={option.column1Title}
            column2Value={option.column2Value}
            column2Title={option.column2Title}
            column3Value={option.column3Value}
            column3Title={option.column3Title}
            description={option.description}
            buttonText={option.buttonText}
          />
        ))}
      </div>
      <div className={financingOptionsStyles.carousel}>
        {/* <Slider {...carouselSettings}>
          {carouselData.map((data, index) => (
            <CarouselCard
              key={index}
              title={data.title}
              image={data.image}
              moneyRange={data.moneyRange}
              description={data.description}
            />
          ))}
        </Slider> */}
        <HorizontalCarousel />  
      </div>
    </section>
  );
});

export default FinancingOptions;
