import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, Text, SimpleGrid, Container, VStack, Spinner } from '@chakra-ui/react';
import Header from '../Feed/Header';
import FinanceCard from './FinanceCard';
import axiosInstance from '../../services/axios';
import styles from './SavedFundings.module.css';

const SavedFundings = () => {
  const [savedFundings, setSavedFundings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const filters = useSelector((state) => state.auth.filters);
  const [searchResults, setSearchResults] = useState([]);
  const currentCompanyId = useSelector((state) => state.auth.current_company_id);

  useEffect(() => {
    const fetchSavedFundings = async () => {
      try {
        const response = await axiosInstance.get('/fundings/loadLiked/0', { 
          params: {
            company_id: currentCompanyId
          },
          withCredentials: true 
        });
        setSavedFundings(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching saved fundings:', error);
        setIsLoading(false);
      }
    };

    fetchSavedFundings();
  }, [currentCompanyId]);

  const handleSearch = async (newFilters) => {
    try {
      console.log(currentCompanyId);
      setSearchResults([]); // Clear current results
      const response = await axiosInstance.get("/fundings/load/0", {
        params: {
          company_id: currentCompanyId,
          status: newFilters.status !== 'all' ? newFilters.status : null,
          region: newFilters.region !== 'all' ? newFilters.region : null,
          deadline_from: newFilters.startDate,
          deadline_to: newFilters.endDate,
          sort_by: newFilters.sortBy !== 'relevance' ? newFilters.sortBy : null,
          sort_order: newFilters.sortOrder,
          text: newFilters.searchQuery
        },
        withCredentials: true,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  return (
    <div>
      <Header user={user}
        setSearchResults={null}
        filters={filters}
        passedSearchQuery={null}
        onSearch={handleSearch} />
      <Box className={styles.savedFundingsPage}>
        <Container maxW="1600px" py={8}>
          <VStack spacing={8} align="stretch">
            <Box textAlign="center">
              <Heading as="h1" size="2xl" mb={4}>
                Finanțările tale salvate
              </Heading>
              <Text fontSize="lg" color="gray.600">
                Aici găsești toate oportunitățile de finanțare pe care le-ai salvat pentru referință ulterioară.
              </Text>
            </Box>

            {isLoading ? (
              <Box textAlign="center">
                <Spinner size="xl" color="purple.500" />
              </Box>
            ) : savedFundings.length > 0 ? (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} maxW="100%" mx="auto">
                {savedFundings.map((funding) => (
                  <FinanceCard key={funding._id} funding={funding} grid={true} />
                ))}
              </SimpleGrid>
            ) : (
              <Box textAlign="center" p={8} bg="gray.100" borderRadius="md">
                <Text fontSize="xl">Nu ai salvat încă nicio finanțare.</Text>
                <Text mt={2}>
                  Explorează feed-ul principal și salvează finanțările care te interesează pentru a le găsi aici mai târziu.
                </Text>
              </Box>
            )}
          </VStack>
        </Container>
      </Box>
    </div>
  );
};

export default SavedFundings;
