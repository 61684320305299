import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Heading,
    Text,
    VStack,
    UnorderedList,
    Divider,
    useColorModeValue,
    HStack,
    IconButton,
    Box,
    List,
    ListItem,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

function TermsAndConditions() {
    const navigate = useNavigate();
    const bgColor = useColorModeValue("gray.50", "gray.900");
    const textColor = useColorModeValue("gray.600", "gray.300");
    const headingColor = useColorModeValue("gray.800", "white");
    const borderColor = useColorModeValue("gray.200", "gray.700");

    return (
        <Box bg={bgColor} minH="100vh">
            <Box
                position="fixed"
                top="0"
                left="0"
                right="0"
                bg={bgColor}
                py={4}
                px={4}
                borderBottom="1px"
                borderColor={borderColor}
                zIndex={1000}
                boxShadow="sm"
            >
                <Container maxW="4xl">
                    <HStack justify="space-between" align="center">
                        <IconButton
                            icon={<ArrowBackIcon />}
                            onClick={() => navigate(-1)}
                            aria-label="Go back"
                            variant="ghost"
                            size="lg"
                        />
                        <Heading as="h1" size="lg" color={headingColor}>
                            Termeni și condiții
                        </Heading>
                        <Box w="40px" /> {/* Spacer for alignment */}
                    </HStack>
                </Container>
            </Box>
            <Container maxW="4xl">
                <Box pt="100px"> {/* Add padding to account for fixed header */}
                    <VStack spacing={8} align="stretch">

                        <Text fontSize="sm" textAlign="center" mb={8}>
                            Actualizat la data de: <strong>01.11.2024</strong>
                        </Text>

                        <List spacing={4} mb={8}>
                            {[
                                "Descrierea activității",
                                "Termeni",
                                "Condiții generale",
                                "Accesul la website",
                                "Contul meu",
                                "Prețurile, facturarea și plățile",
                                "Garanția",
                                "Confidențialitate",
                                "Răspundere",
                                "Litigii",
                                "Contact",
                            ].map((item, index) => (
                                <ListItem key={index} fontWeight="medium">
                                    {index + 1}. {item}
                                </ListItem>
                            ))}
                        </List>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                Prezentul document (împreună cu toate documentele menţionate în acesta)
                                stabileşte termenii şi condiţiile care guvernează utilizarea acestui
                                website - <Text as="a" href="https://finaccess.ro/" color="teal.500" isExternal>https://finaccess.ro/</Text> (numit în continuare "Website").
                            </ListItem>
                        </UnorderedList>

                        <UnorderedList spacing={3}>
                            <ListItem>
                                Website-ul <Text as="a" href="https://finaccess.ro/" color="teal.500" isExternal>https://finaccess.ro/</Text> aparţine şi este controlată de către <strong>S.C. FINACCESS S.R.L.</strong>, CUI RO42171928, J22 /242 /2020, numită în continuare "Societatea".
                            </ListItem>
                        </UnorderedList>

                        <UnorderedList spacing={3}>
                            <ListItem>
                                Utilizarea Website-ului presupune acordul dumneavoastră cu S.C. FINACCESS
                                S.R.L. de a respecta şi a acţiona în conformitate cu Termenii şi
                                condiţiile de utilizare a Website-ului.
                            </ListItem>
                            <ListItem>
                                Scopul Website-ului este de prezentare și promovare a serviciilor S.C. FINACCESS S.R.L. prin care societatea pune la dispoziție platforma acesteia. Totodată societatea noastră are ca scop contractarea prin intermediul Website-ului, cu potențialii clienți interesați de aceste servicii.
                            </ListItem>
                            <ListItem>
                                Vă rugăm să citiţi prezenții Termeni și Condiții, Politica de cookies-uri şi Politica de confidenţialitate înainte de a utiliza Website-ul. Prin accesarea și utilizarea Website-ului, recunoașteți în mod expres caracterul obligatoriu al acestor documente și vă asumați angajamentul de a le respecta.
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            1. Descrierea activității
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                S.C. FINACCESS S.R.L. cu sediul în județul Iași, orașul Iași, str. Decebal
                                14a, bloc x10, etaj 4, ap. 17, identificat prin CUI 42171928 înregistrat
                                la Registrul Comerțului sub nr.: J22 /242 /2020 este o companie având ca
                                activitate principala servicii de consultanță în management și
                                accesarea de finanțări în conformitate cu codul CAEN 7022.
                            </ListItem>
                        </UnorderedList>

                        <UnorderedList spacing={3}>
                            <ListItem>
                                Datele noastre de contact sunt:
                                <br />
                                Adresa sediului: str. Decebal 14a, bloc x10, etaj 4, ap. 17, orașul Iași,
                                județul Iași
                                <br />
                                Adresă de e-mail: <Text as="a" href="mailto:contact@ctrl.fund" color="teal.500">contact@ctrl.fund</Text>
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            2. Termeni
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                a) Client - persoana juridică (prin reprezentantul persoană fizică) care are sau obține acces la CONȚINUT, prin orice mijloc de comunicare pus la dispoziție de către S.C. FINACCESS S.R.L. (electronic, telefonic, etc) sau în baza unui acord de utilizare existent între S.C. FINACCESS S.R.L. și acesta și care necesită crearea și utilizarea unui Cont sau este posibilă prin funcția de comandă rapidă.
                            </ListItem>
                            <ListItem>
                                b) Cont- secțiunea din Website formată dintr-o adresă de e-mail și o parolă care permite Utilizatorului gestionarea relației cu Clientul. Utilizatorul este responsabil și se va asigura că toate informațiile introduse la crearea Contului sunt corecte, complete și actualizate.
                            </ListItem>
                            <ListItem>
                                c) Campanie- acțiunea de a expune in scop comercial, un număr finit de Bunuri și/sau Servicii având un stoc limitat și predefinit, pentru o perioadă limitată de timp stabilită de către Vânzător.
                            </ListItem>
                            <ListItem>
                                d) Contract - reprezintă contractul la distanță încheiat între Vânzător și Cumpărător, fără prezența fizică simultană a Vânzătorului și a Cumpărătorului.
                            </ListItem>
                            <ListItem>
                                e) Comunicări comerciale / newsletter - reprezintă orice tip de mesaj trimis (e-mail, telefonic, mobile push, web push, etc.) care conține informatii generale și tematice, informații cu privire la produse similiare sau complementare cu cele contractete de pe website, informații cu privire la oferte sau prețuri promoționale, sau alte informații relevante.
                            </ListItem>
                            <ListItem>
                                f) Utilizatorul - Persoana fizică sau juridică care își creează cont, iar în baza unui contract sau acceptării termenilor și condițiilor se folosește de funcțiile puse la dispoziție prin intermediul Website-ului. Utilizatorul poate fi: aplicant, ofertant, insituție bancară, consultant finantari nerambursabile ș. a.
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            3. Condiții generale
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                3.1. Platforma pusă la dispoziție este una de intermediere între actorii relevanți (ex. ofertanți, brokeri, furnizori schimburi valutare și client) în procesul de contractare a unei finanțări. Scopul platformei este de a strânge în același loc virtual acești actori pentru a se putea realiza comunicarea, ofertarea sau alte procese legate de contractare.
                            </ListItem>
                            <ListItem>
                                3.2. Relația dintre S.C. FINACCESS S.R.L. și diverși utilizatori menționați în pct. 2 lit. g), va fi formalizată prin semnarea unui contract separat cu compania noastră.
                            </ListItem>
                            <ListItem>
                                3.3. Toate informațiile de pe website-ul https://finaccess.ro/ sunt proprietatea intelectuală a S.C. FINACCESS S.R.L. conform Legii pentru protecția drepturilor de autor (Legea nr. 8/ 1996). Întreg continutul publicat pe site (text, descrieri produse, imagini, logo, culori, slogan, simboluri, elemente de grafică web, clipuri video, etc.) poate fi folosit doar în scopul informării utilizatorilor platformei.
                            </ListItem>
                            <ListItem>
                                3.4. Fotografiile afișate pe Website, editate sau prelucrate de FINACCESS S.R.L. nu pot fi preluate sau utilizate fără acordul acesteia;
                            </ListItem>
                            <ListItem>
                                3.5. Orice acțiune: proiect, eveniment, campanie, concurs, serviciu etc. desfășurată de companie prin intermediul Website-ului va fi anunțată, promovată și detaliată la momentul desfășurării acesteia, fie printr-un Regulament, fie prin prevederi succinte și clare inserate în cadrul anunțului de desfășurare a acțiunii respective sau ulterior, la momentul oportun.
                            </ListItem>
                            <ListItem>
                                3.6. S.C. FINACCESS S.R.L. își asumă dreptul de a schimba, modifica sau actualiza în orice moment conținutul Website-ului https://finaccess.ro/ precum și Termenii și condițiile de folosire, fără o notificare în prealabil.
                            </ListItem>
                            <ListItem>
                                3.7. Este strict interzisă și se pedepsește conform legii copierea, distribuirea, publicarea, transmiterea altor persoane sau incorporarea conținutului în alte materiale în afara website-ului https://finaccess.ro/, fără acordul Societății.
                            </ListItem>
                            <ListItem>
                                3.8. Pentru a ne sesiza orice problemă, vă rugăm să ne contactați la adresa de e-mail: <Text as="a" href="mailto:contact@ctrl.fund" color="teal.500">contact@ctrl.fund</Text>
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            4. Accesul la website
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                4.1. Accesul pe Website-ul pus la dispoziție se face exclusiv prin accesarea domeniului public: https://finaccess.ro/.
                            </ListItem>
                            <ListItem>
                                4.2. Prin accesarea și utilizarea acesteia, Utilizatorul/Clientul își dă acordul privind acceptarea termenilor și condițiilor prezentate pe Website, a politicii de confidențialitate și a politicii de cookies.
                            </ListItem>
                            <ListItem>
                                4.3. Utilizatorul/Clientul beneficiază de acces limitat, în interes personal (vizualizare, informare,) pe SITE-ul https://finaccess.ro/ și nu are dreptul de a descărca, de a modifica parțial sau integral Website-ul, de a reproduce parțial sau integral Website-ul, de a copia, vinde/revinde sau de a exploata în orice alta manieră Website-ul, în scopuri comerciale sau care contravin intereselor S. C. FINACCESS S.R.L.
                            </ListItem>
                            <ListItem>
                                4.4. Toate informațiile folosite pentru descrierea Serviciilor oferite pe Website (imagini statice / dinamice / prezentări multimedia / etc.) nu reprezintă o obligație contractuală din partea companiei noastre, acestea fiind utilizate exclusiv cu titlu de prezentare. Orice obligație contractuală va fi reglementată în mod separat cu părțile interesate.
                            </ListItem>
                            <ListItem>
                                4.5. Fiecare utilizator care dorește să acceseze serviciile noastre are posibilitatea să completeze câmpurile puse la dispoziție în secția "Sign up".
                            </ListItem>
                            <ListItem>
                                4.6. Ne rezervăm dreptul de a respinge cererile pe care nu le considerăm oneste sau care, din perspectiva noastră, au șanse reduse de aprobare din partea ofertanților disponibili pe Website. Scopul este să asigurăm un proces de aplicare și evaluare extrem de transparent și eficient, în beneficiul tuturor părților implicate.
                            </ListItem>
                        </UnorderedList>


                        <Heading as="h2" size="md" mb={4}>
                            5. Contul meu
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                5.1. Orice Utilizator care vizitează website-ul https://finaccess.ro/, și îndeplinește toate condițiile legale privind contractarea de servicii specifice oferite de noi, de asemenea termenii și condițiile website-ului, își poate crea un cont.
                            </ListItem>
                            <ListItem>
                                5.2. La crearea Contului, fiecare Utilizator își dă acordul cu privire la acceptarea acestor termeni și condiții și ia la cunoștință politica de prelucrare a datelor personale și politica de cookies, fiind responsabil în legătură cu acuratețea informațiilor oferite și de actualizarea lor.
                            </ListItem>
                            <ListItem>
                                5.3. Pentru crearea unui Cont va trebui să parcurgeți următorii pași:
                                <UnorderedList spacing={3}>
                                    <ListItem>
                                        Să introduceți următoarele date personale: e-mail, o parolă , cu care să vă logați ulterior.
                                    </ListItem>
                                    <ListItem>
                                        Să confirmați prin accesarea butonului “Acceptă și Înscrie-te”
                                    </ListItem>
                                    <ListItem>
                                        De asemenea, puteți să vă abonați la newsletterul nostru pentru a primi informări despre ofertele speciale și noutati despre ofertele și produsele noastre și a partenerilor noștri.
                                    </ListItem>
                                    <ListItem>
                                        În cont aveți posibilitatea de a:
                                        <UnorderedList spacing={3}>
                                            <ListItem>
                                                edita informațiile contului;
                                            </ListItem>
                                            <ListItem>
                                                modifica parola contului
                                            </ListItem>
                                        </UnorderedList>
                                    </ListItem>
                                </UnorderedList>
                            </ListItem>
                            <ListItem>
                                5.4. Odată înregistrați respectiv conectați pe platformă veți putea avea acces la toate serviciile puse la dispoziție, și pentru care s-a contractat, atunci când este cazul.
                            </ListItem>
                            <ListItem>
                                5.5. Ulterior înregistrării contului, datele de contact ale Clientului sau diverse alte date completate în câmpurile specifice existente pe website, vor fi puse la dispoziția diverșilor utilizatori (ex. bănci, instituții financiare non-bancare etc) pentru a îl contacta, pentru a realiza diverse analize specifice sau oferte.
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            6. Prețurile, facturarea și plățile
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                6.1. Platforma este oferită în mod gratuit Clienților noștri.
                            </ListItem>
                            <ListItem>
                                6.2. Pentru oricare altă categorie de utilizator a platformei, odată cu crearea contului, FINACCESS S.R.L. va transmite documentația necesară pentru validarea conturilor (ex. contractul), moment în care vor fi stabilite în mod concret prețurile, momentul facturării, a plății și modalitatea de realizare a acestora.
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            7. Garanția
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                7.1. Pentru toate serviciile puse la dispoziție de către compania noastră pe https://finaccess.ro/, societatea noastră garantează buna funcționare a platformei a acestora pe tot parcursul contractării. Societatea noastră v-a lua toate măsurile tehnice și organizatorice necesare pentru a asigura un nivel ridicat de securitate și confidențialitate a datelor.
                            </ListItem>
                            <ListItem>
                                7.2. Societatea noastră:
                                <UnorderedList spacing={3}>
                                    <ListItem>
                                        nu garantează în nici un fel executarea contractului dintre client și instituția și/sau societatea contractată ;
                                    </ListItem>
                                    <ListItem>
                                        nu răspunde de modalitatea de îndeplinire a obligațiilor specifice asumate de aceste două părți, ci se obligă doar să pună în legătură părțile prin platforma pusă la dispoziție ;
                                    </ListItem>
                                    <ListItem>
                                        nu se obligă să garanteze solvabilitatea vreuneia dintre părți.
                                    </ListItem>
                                </UnorderedList>
                            </ListItem>
                        </UnorderedList>
                        <Heading as="h2" size="md" mb={4}>
                            8. Confidențialitate
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                8.1. FINACCESS S.R.L. va păstra confidențialitatea tuturor datelor pe care le furnizați. Totodată, FINACCESS S.R.L. va asigura securitatea datelor oferite și va lua măsuri tehnice și organizatorice pentru o gestionare eficientă a acestora, strict în scopurile declarate în prezentul contract.
                            </ListItem>
                            <ListItem>
                                8.2. Datele sunt prelucrate în următoarele scopuri:
                                <UnorderedList spacing={3}>
                                    <ListItem>
                                        Înregistrarea și confirmarea conturilor;
                                    </ListItem>
                                    <ListItem>
                                        Buna folosință și utilizare a platformei și desfășurare a activității sub formatul prezentat;
                                    </ListItem>
                                    <ListItem>
                                        Crearea cadrului de desfășurare a activității contractate.
                                    </ListItem>
                                </UnorderedList>
                            </ListItem>
                            <ListItem>
                                8.3. Pentru a cunoaște mai mult detaliile legate de prelucrarea datelor, dar și drepturile pe care le aveți, vă rugăm să consultați Politica de prelucrare a datelor cu caracter personal și Politica de cookies.
                            </ListItem>
                        </UnorderedList>


                        <Heading as="h2" size="md" mb={4}>
                            9. Răspundere
                        </Heading>
                        <UnorderedList spacing={3}>
                            <ListItem>
                                9.1. Prin crearea și utilizarea Contului, Utilizatorul / Clientul își asumă răspunderea pentru respectarea prevederilor prezente, pentru menținerea confidențialității datelor de Cont (nume utilizator, parolă ș.a.) și pentru gestionarea accesării Contului, și, în măsura permisă de legislația în vigoare, este responsabil de activitatea derulată prin intermediul Contului său.
                            </ListItem>
                            <ListItem>
                                9.2. Prin crearea Contului și/sau utilizarea Conținutului, Clientul / Utilizatorul acceptă în mod expres și fără echivoc Termenii și Condițiile Site-ului în ultima versiune actualizată care este comunicată în cadrul Site-ului, existentă la data creării Contului și/sau utilizării conținutului și/sau la data plasării Comenzii.
                            </ListItem>
                            <ListItem>
                                9.3. FINACCESS S.R.L. nu răspunde pentru neexecutarea, executarea defectuoasă sau cu întârziere a contractelor de către terţi faţă de utilizator/client.
                            </ListItem>
                            <ListItem>
                                9.4. FINACCESS S.R.L. își rezervă dreptul de a actualiza și modifica periodic Termenii și Condițiile Website-ului pentru a reflecta orice modificări ale modului si condițiilor de funcționare a Site-ului sau orice modificări ale cerințelor legale. Documentul este opozabil Clienților / Utilizatorilor de la momentul afișării în Site. În cazul oricărei astfel de modificări, vom afișa pe Site versiunea modificată a Documentului, motiv pentru care vă rugăm să verificați periodic conținutul acestui Document.
                            </ListItem>
                            <ListItem>
                                9.5. Clienții / Utilizatorii își asumă întreaga responsabilitate în cazul divulgării datelor personale către terți, compania noastră nu poate fi numită responsabilă, în situația în care un Client / Utilizator este prejudiciat sub orice formă de către un terț care ar pretinde că este reprezentant al FINACCESS S.R.L. sau că reprezintă interesele acestuia.
                            </ListItem>
                            <ListItem>
                                9.6. Vor fi considerate tentativa de fraudă sau fraudă urmatoarele actiuni asupra website-ului, continutului acestuia sau asupra https://finaccess.ro/:
                                • accesarea datelor de orice tip ale unui Utilizator/Client prin folosirea unui cont sau prin orice altă metodă;
                                • alterarea sau modificarea conținutului Website-ului sau a oricărei corespondențe expediate prin orice mijloc de comunicare de către https://finaccess.ro/ către Utilizator/Client;
                                • afectarea performanțelor serverului pe care rulează Website-ul https://finaccess.ro/
                                În oricare din situațiile de mai sus, FINACCESS S.R.L. își rezervă dreptul de a se adresa autorităților competente. Fraudele și tentativele de fraudă sunt pedepsite conform legislației penale în vigoare, iar părțile prejudiciate pot cere în instanță repararea prejudiciilor morale sau pecuniare, după caz.
                            </ListItem>
                            <ListItem>
                                De asemenea, în cazul tranzacțiilor suspecte sau declarate suspecte, la solicitarea oricărei autorități publice, instituții autorizate, unități bancare partenere, FINACCESS S.R.L. are obligația, de a comunica inclusiv date cu caracter personal, dacă legea prevede în mod expres aceasta.
                            </ListItem>
                        </UnorderedList>

                        <Heading as="h2" size="md" mb={4}>
                            10. Litigii
                        </Heading>
                        <Text mb={6}>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    10.1. Prezentul contract este supus legii române. Eventualele litigii apărute între compania noastră și Utilizatori / Clienți se vor rezolva pe cale amiabilă sau, în cazul în care aceasta nu va fi posibilă, litigiile vor fi soluționate de instanțele judecătorești prevăzute de legislația civilă.
                                </ListItem>
                            </UnorderedList>
                        </Text>

                        <Heading as="h2" size="md" mb={4}>
                            11. Contact
                        </Heading>
                        <Text mb={6}>
                            <UnorderedList spacing={3}>
                                <ListItem>
                                    11.1. Pentru orice detalii sau întrebari despre https://finaccess.ro/, produse sau comenzi vă rugăm să ne contactați la adresa de e-mail: contact@ctrl.fund.
                                </ListItem>
                            </UnorderedList>
                        </Text>
                    </VStack>
                </Box>
            </Container>
        </Box>
    );
}

export default TermsAndConditions; 